import React from "react"
import { Button, Modal, Popover, Stack, Whisper } from "rsuite"
import { Icon } from "@rsuite/icons"
import IconFilter from "../../../components/common/IconFilter"
import InfoOutlineIcon from "@rsuite/icons/InfoOutline"

const ExportModal = ({
  typeModalOpen,
  handleModalClose,
  setExportTypes,
  exportTypes,
  handleExport,
}) => {
  return (
    <Modal keyboard={false} open={typeModalOpen} onClose={handleModalClose}>
      <Modal.Body>
        <Stack
          direction="column"
          spacing={3}
          alignItems="flex-start"
          style={{ marginBottom: "10px" }}
        >
          <h6>Select Types</h6>
          <Stack spacing={10}>
            <small>Select PNL types to be included in the export</small>
            <Whisper
              placement="top"
              trigger="hover"
              controlId="control-id-hover"
              speaker={
                <Popover>
                  <small>
                    PRODUCTION, PAPER, and MODEL are selected by default
                  </small>
                </Popover>
              }
            >
              <Icon
                style={{ cursor: "pointer" }}
                as={InfoOutlineIcon}
                fill="grey"
              />
            </Whisper>
          </Stack>
        </Stack>
        <IconFilter
          onChange={setExportTypes}
          only={["type"]}
          showLabel
          vertical
          value={exportTypes}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleExport} appearance="primary">
          Export
        </Button>
        <Button onClick={handleModalClose} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ExportModal
