import React from "react"
import { useMutation } from "@tanstack/react-query"
import {
  Button,
  Col,
  Form,
  Grid,
  Heading,
  Row,
  Schema,
  SelectPicker,
  Stack,
  Text,
} from "rsuite"
import CustomDatePicker from "./CustomDatePicker"
import { formatQuery } from "../controls/lib/utils"
import CustomCheckbox from "./CustomCheckbox"
import EndpointInputGroup from "./EndpointInputGroup"
import ConfirmDialog from "../../../../components/common/ConfirmDialog"
import { useDialog } from "../../../../hooks/useDialog"
import useFormSaveDisable from "../hooks/useFormSaveDisable"

const elementSettingsModel = Schema.Model({
  summaryType: Schema.Types.StringType(),
  pnlEndpoint: Schema.Types.StringType(),
  positionsEndpoint: Schema.Types.StringType(),
  slippageEndpoint: Schema.Types.StringType(),
})

const HistoricalPNLEndpoint = ({ endpoint, setFormValue, query }) => {
  const sinceMatch = endpoint.match(/&since=([0-9-]+)/)
  const untilMatch = endpoint.match(/&until=([0-9-]+)/)
  const isOfficialMatch = endpoint.match(/&is_official=(true)/)
  return (
    <Grid fluid>
      <Row>
        <Col>
          <Heading
            style={{
              marginBottom: "20px",
            }}
            level={6}
          >
            Historical P&L
          </Heading>
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col>
          <CustomDatePicker
            label="Since"
            match={sinceMatch}
            setFormValue={setFormValue}
            keyName="pnlEndpoint"
            queryName="since"
          />
        </Col>
        <Col>
          <CustomDatePicker
            label="Until"
            match={untilMatch}
            setFormValue={setFormValue}
            keyName="pnlEndpoint"
            queryName="until"
          />
        </Col>
        <Col>
          <CustomCheckbox
            queryName="is_official"
            match={isOfficialMatch}
            setFormValue={setFormValue}
            keyName="pnlEndpoint"
            query="&is_official=true"
            label="Official Only"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <EndpointInputGroup
            label="Historical P&L"
            endpoint={endpoint}
            setFormValue={setFormValue}
            keyName="pnlEndpoint"
            query={query}
          />
        </Col>
      </Row>
    </Grid>
  )
}

const PositionEndpoint = ({ endpoint, setFormValue, query }) => {
  return (
    <Grid fluid>
      <Row>
        <Col>
          <Heading
            style={{
              marginBottom: "20px",
            }}
            level={6}
          >
            Positions
          </Heading>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <EndpointInputGroup
            label="Positions"
            endpoint={endpoint}
            setFormValue={setFormValue}
            keyName="positionsEndpoint"
            query={query}
          />
        </Col>
      </Row>
    </Grid>
  )
}

const SlippageEndpoint = ({ endpoint, setFormValue, query }) => {
  const sinceMatch = endpoint.match(/&since=([0-9-]+)/)
  const untilMatch = endpoint.match(/&until=([0-9-]+)/)
  const isOfficialMatch = endpoint.match(/&is_official=(true)/)
  const formatRelativeMatch = endpoint.match(/&format=relative/)

  return (
    <Grid fluid>
      <Row>
        <Col>
          <Heading
            style={{
              marginBottom: "20px",
            }}
            level={6}
          >
            Slippage
          </Heading>
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col>
          <CustomDatePicker
            label="Since"
            match={sinceMatch}
            setFormValue={setFormValue}
            keyName="slippageEndpoint"
            queryName="since"
          />
        </Col>
        <Col>
          <CustomDatePicker
            label="Until"
            match={untilMatch}
            setFormValue={setFormValue}
            keyName="slippageEndpoint"
            queryName="until"
          />
        </Col>
        <Col>
          <CustomCheckbox
            queryName="format"
            match={formatRelativeMatch}
            setFormValue={setFormValue}
            keyName="slippageEndpoint"
            query="&format=relative"
            label="Format: relative"
          />
        </Col>
        <Col>
          <CustomCheckbox
            queryName="is_official"
            match={isOfficialMatch}
            setFormValue={setFormValue}
            keyName="slippageEndpoint"
            query="&is_official=true"
            label="Official Only"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <EndpointInputGroup
            label="Slippage"
            endpoint={endpoint}
            setFormValue={setFormValue}
            keyName="slippageEndpoint"
            query={query}
            rows={3}
          />
        </Col>
      </Row>
    </Grid>
  )
}
const ModelDataSettingsForm = ({ selectedPortfolio }) => {
  const { onDialogClose, onDialogOpen, isOpen } = useDialog()
  const { onFormChange, isSaveDisabled, formValue, onFormValueChange } =
    useFormSaveDisable({
      id: selectedPortfolio?.id,
      summaryType: selectedPortfolio?.summaryType || "DAILY",
      pnlEndpoint: selectedPortfolio?.pnlEndpoint || "",
      positionsEndpoint: selectedPortfolio?.positionsEndpoint || "",
      slippageEndpoint: selectedPortfolio?.slippageEndpoint || "",
    })
  const formRef = React.useRef()

  const mutation = useMutation({
    mutationKey: ["updatePortfolio"],
  })

  const hasAnAccount =
    !!selectedPortfolio?.account && selectedPortfolio?.account.length > 0
  if (!hasAnAccount) return <div>No Account Set</div>

  const isIB = !!selectedPortfolio?.account?.find(
    (acct) => acct.broker === "interactivebrokers"
  )
  const isBinance = !!selectedPortfolio.account?.find(
    (acct) => acct.broker === "binance"
  )

  const endpointQuery = (prefix, isSlippage = false) =>
    formatQuery({
      pathPrefix: prefix,
      accts: selectedPortfolio.account,
      isBinance,
      isSlippage,
    })

  const onConfirm = () => {
    mutation.mutate(formValue, {
      onSuccess: () => onDialogClose(),
    })
  }

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      return
    }

    onDialogOpen()
  }
  return (
    <Form
      ref={formRef}
      model={elementSettingsModel}
      formValue={formValue}
      onChange={onFormChange}
      fluid
    >
      <Grid fluid>
        <Row
          style={{
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <Col xs={24}>
            <Stack justifyContent="space-between" alignItems="center">
              <Heading level={5}>Element Settings</Heading>
              <Form.Group>
                <Button
                  appearance="primary"
                  onClick={handleSubmit}
                  disabled={isSaveDisabled}
                >
                  Save
                </Button>
              </Form.Group>
            </Stack>
          </Col>
        </Row>

        <Row style={{ marginBottom: "15px" }}>
          <Col xs={24}>
            <Form.Group controlId="summaryType">
              <Form.Control
                label="Data Frequency"
                name="summaryType"
                accepter={SelectPicker}
                data={["DAILY", "WEEKLY"].map((opt) => ({
                  key: opt,
                  value: opt,
                  label: opt.split("_").join(" "),
                }))}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs={24}>
            <HistoricalPNLEndpoint
              endpoint={formValue.pnlEndpoint}
              setFormValue={onFormValueChange}
              query={endpointQuery(`/v2/top_pnl`)}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs={24}>
            {" "}
            <PositionEndpoint
              endpoint={formValue.positionsEndpoint}
              setFormValue={onFormValueChange}
              query={endpointQuery(`/v2/positions`)}
            />
          </Col>
        </Row>
        {isIB && (
          <Row style={{ marginBottom: "15px" }}>
            <Col xs={24}>
              <SlippageEndpoint
                endpoint={formValue.slippageEndpoint}
                setFormValue={onFormValueChange}
                query={endpointQuery(`/v3/top_pnl`, true)}
              />
            </Col>
          </Row>
        )}
      </Grid>
      <ConfirmDialog
        onClose={onDialogClose}
        onConfirm={onConfirm}
        isOpen={isOpen}
        isLoading={mutation.isLoading}
      >
        <Text>Are you sure you want save these changes?</Text>
      </ConfirmDialog>
    </Form>
  )
}

export default ModelDataSettingsForm
