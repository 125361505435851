import { useSearchParams } from "react-router-dom"
import { useControls } from "../../../../../../lib/controls/ControlContext"
import { useEffect } from "react"

const useTradeSeverConfig = () => {
  const { showTradeServerSummary } = useControls()
  const [searchParams] = useSearchParams()

  const mode_q = searchParams.get("mode")

  useEffect(() => {
    showTradeServerSummary.setValue(!!mode_q ? mode_q : "Summary")
  }, [mode_q, showTradeServerSummary])

  return showTradeServerSummary.value
}

export default useTradeSeverConfig
