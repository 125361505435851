import { useMutation } from "@tanstack/react-query"
import React from "react"
import {
  Button,
  Col,
  Form,
  Grid,
  Heading,
  InputNumber,
  Row,
  Schema,
  SelectPicker,
  Stack,
  Text,
} from "rsuite"
import ConfirmDialog from "../../../../components/common/ConfirmDialog"
import { useDialog } from "../../../../hooks/useDialog"
import useFormSaveDisable from "../hooks/useFormSaveDisable"

const leverageModel = Schema.Model({
  leverageToDisplay: Schema.Types.StringType().isRequired(
    "Select a Leverage Type"
  ),
  leverage: Schema.Types.NumberType().min(1, "Should be at least 1"),
  minDollarPositionValueToShow: Schema.Types.NumberType().min(
    1,
    "Should be at least 1"
  ),
})

const LEVERAGE_CHOICES = ["LEVERAGE", "REALIZED_LEVERAGE", "LEVERAGE_CASH"]

const LeverageSettingsForm = ({ portfolio }) => {
  const { onDialogClose, onDialogOpen, isOpen } = useDialog()
  const { onFormChange, isSaveDisabled, formValue } = useFormSaveDisable({
    id: portfolio.id,
    leverageToDisplay: portfolio.leverageToDisplay || LEVERAGE_CHOICES[0],
    leverage: `${portfolio.leverage}` || "1",
    minDollarPositionValueToShow:
      `${portfolio.minDollarPositionValueToShow}` || "25",
  })

  const formRef = React.useRef()
  const [formError, setFormError] = React.useState({})

  const mutation = useMutation({
    mutationKey: ["updatePortfolio"],
  })

  const onConfirm = () => {
    mutation.mutate(
      {
        ...formValue,
        leverage: +formValue.leverage,
        minDollarPositionValueToShow: +formValue.minDollarPositionValueToShow,
      },
      {
        onSuccess: () => onDialogClose(),
      }
    )
  }

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      return
    }

    onDialogOpen()
  }

  return (
    <Form
      ref={formRef}
      model={leverageModel}
      formValue={formValue}
      onCheck={setFormError}
      onChange={onFormChange}
      fluid
    >
      <Grid fluid>
        <Row
          style={{
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <Col xs={24}>
            <Stack justifyContent="space-between" alignItems="center">
              <Heading level={5}> Leverage Settings</Heading>
              <Form.Group>
                <Button
                  appearance="primary"
                  onClick={handleSubmit}
                  loading={mutation.isLoading}
                  disabled={isSaveDisabled}
                >
                  Save
                </Button>
              </Form.Group>
            </Stack>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col xs={24}>
            <Form.Group controlId="leverageToDisplay">
              <Form.Control
                name="leverageToDisplay"
                label="Leverage To Display"
                accepter={SelectPicker}
                data={LEVERAGE_CHOICES.map((opt) => ({
                  label: opt,
                  value: opt,
                }))}
                errorMessage={formError.leverageToDisplay}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col xs={12}>
            <Form.Group controlId="leverage">
              <Form.ControlLabel>Leverage</Form.ControlLabel>
              <Form.Control
                name="leverage"
                accepter={InputNumber}
                errorMessage={formError.leverage}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group controlId="minDollarPositionValueToShow">
              <Form.ControlLabel>
                Min Dollar Position Value To Show
              </Form.ControlLabel>
              <Form.Control
                name="minDollarPositionValueToShow"
                accepter={InputNumber}
                errorMessage={formError.minDollarPositionValueToShow}
              />
            </Form.Group>
          </Col>
        </Row>
      </Grid>
      <ConfirmDialog
        onClose={onDialogClose}
        onConfirm={onConfirm}
        isOpen={isOpen}
        isLoading={mutation.isLoading}
      >
        <Text>Are you sure you want save these changes?</Text>
      </ConfirmDialog>
    </Form>
  )
}

export default LeverageSettingsForm
