import { useTheme } from "@aws-amplify/ui-react"
import React from "react"
import {
  FaFlask,
  FaDollarSign,
  FaChartLine,
  FaFile,
  FaBook,
  FaPlay,
  FaPause,
  FaRegNewspaper,
  FaRegLightbulb,
  FaCompass,
  FaBalanceScale,
  FaDeezer,
} from "react-icons/fa"
import { BiMapAlt } from "react-icons/bi"
import GearIcon from "@rsuite/icons/Gear"
import { MdSpeed } from "react-icons/md"
import { Icon } from "@rsuite/icons"
import { Tooltip, Whisper } from "rsuite"

export const portTypes = [
  "PRODUCTION",
  "EXPERIMENTAL",
  "MODEL",
  "LEGACY",
  "BENCHMARK",
  "PAPER",
  "DEVELOPMENT",
]

export const portStatues = ["DRAFT", "LIVE", "PAUSED"]

const AppIcons = {
  PRODUCTION: FaDollarSign,
  BENCHMARK: MdSpeed,
  EXPERIMENTAL: FaFlask,
  DEVELOPMENT: FaRegLightbulb,
  MODEL: FaChartLine,
  LEGACY: FaBook,
  PAPER: FaRegNewspaper,
  DRAFT: FaFile,

  LIVE: FaPause,
  PAUSED: FaPlay,

  historical: FaDollarSign,
  dashboard: BiMapAlt,
  slippage: FaCompass,
  tradeserver: FaDeezer,
  auto_reconciliation: FaBalanceScale,
  postrack: FaBalanceScale,
  settings: GearIcon,
}

const PortStatus = ({ status, fill, pId, showTooltip = false, ...props }) => {
  const { tokens } = useTheme()

  if (showTooltip) {
    return (
      <Whisper
        controlId={`control-id-${pId}-status`}
        trigger="hover"
        speaker={<Tooltip>{status.toUpperCase()}</Tooltip>}
        placement="top"
      >
        <div>
          <Icon
            as={AppIcons[status]}
            fill={fill || tokens.colors.icons[status]?.value}
            {...props}
          />
        </div>
      </Whisper>
    )
  } else {
    return (
      <Icon
        as={AppIcons[status]}
        fill={fill || tokens.colors.icons[status]?.value}
        {...props}
      />
    )
  }
}

export default PortStatus
