import { useCallback } from "react"
import { useControls } from "../../../lib/controls/ControlContext"
import moment from "moment"

function arrayToCsv(data) {
  return data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double quotes
          .map((v) => `"${v}"`) // quote it
          .join(",") // comma-separated
    )
    .join("\r\n") // rows starting on new lines
}

function downloadBlob(content, filename, contentType) {
  // Create a blob
  var blob = new Blob([content], { type: contentType })
  var url = URL.createObjectURL(blob)

  // Create a link to download it
  var pom = document.createElement("a")
  pom.href = url
  pom.setAttribute("download", filename)
  pom.click()
}

const useDashboardConfig = () => {
  const {
    dashboardConfig: [dConfig, setDConfig],
  } = useControls()

  const dConfigFilter = ({ portfolioManager, slug }) => {
    return dConfig.editMode
      ? true
      : dConfig.muted.indexOf(portfolioManager + "/" + slug) < 0
  }

  const handleClearButtonClick = useCallback(() => {
    setDConfig({
      ...dConfig,
      muted: [],
    })
  }, [dConfig, setDConfig])

  const handleCellClick = useCallback(
    (manager_slug, slug) => {
      if (dConfig.muted.indexOf(manager_slug + "/" + slug) >= 0) {
        setDConfig({
          ...dConfig,
          muted: dConfig.muted.filter((m) => m !== manager_slug + "/" + slug),
        })
      } else {
        setDConfig({
          ...dConfig,
          muted: [...dConfig.muted, manager_slug + "/" + slug],
        })
      }
    },
    [dConfig, setDConfig]
  )

  const handleExportIssue = (data, exportTypes = []) => {
    const today = moment()
    const issuesArray = [
      ["Manager", "Account", "", "Last PNL date", "Days not updated"],
    ]

    const productionManagerData = data.managers.filter((manager) =>
      exportTypes.includes(manager.type)
    )

    for (const port of productionManagerData) {
      const lastPnl = moment(port.metaData?.lastPNLDate)
      var duration = lastPnl.diff(today, "days")

      if (Math.abs(duration) >= 3) {
        issuesArray.push([
          port.manager_displayName,
          port.account,
          port.displayName,
          port.metaData.lastPNLDate,
          duration,
        ])
      }
    }

    const csv = arrayToCsv(issuesArray)
    downloadBlob(
      csv,
      `dashboard_issues${today.year()}${today.month()}${today.day()}.csv`,
      "text/csv;charset=utf-8;"
    )
  }

  return {
    dConfig,
    isInEditMode: dConfig.editMode,
    mutedPortfolios: dConfig.muted,
    dConfigFilter,
    handleClearButtonClick,
    handleCellClick,
    handleExportIssue,
  }
}

export default useDashboardConfig
