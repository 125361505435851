import { useMutation } from "@tanstack/react-query"
import React from "react"
import { Form, Button, Grid, Stack, Row, Col, Heading, Text } from "rsuite"
import MilestoneInput, { milestoneModel } from "./MilestoneInput"
import ConfirmDialog from "../../../../components/common/ConfirmDialog"
import { useDialog } from "../../../../hooks/useDialog"
import useFormSaveDisable from "../hooks/useFormSaveDisable"

const MilestoneForm = ({ portfolioId, milestones }) => {
  const formRef = React.useRef()

  const { onDialogClose, onDialogOpen, isOpen } = useDialog()
  const { onFormChange, isSaveDisabled, formValue } = useFormSaveDisable({
    milestones: milestones?.map((milestone) => ({
      title: milestone.title || "",
      date: new Date(milestone.date),
      color: milestone.color,
    })),
  })

  const [formError, setFormError] = React.useState({})

  const mutation = useMutation({
    mutationKey: ["updatePortfolio"],
  })

  const onConfirm = () => {
    mutation.mutate(
      {
        id: portfolioId,
        milestones: formValue.milestones,
      },
      {
        onSuccess: () => onDialogClose(),
      }
    )
  }

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      return
    }

    onDialogOpen()
  }

  return (
    <Form
      ref={formRef}
      model={milestoneModel}
      formValue={formValue}
      onChange={onFormChange}
      onCheck={setFormError}
      checkTrigger="blur"
    >
      <Grid fluid>
        <Row
          style={{
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <Col xs={24}>
            <Stack alignItems="center" justifyContent="space-between">
              <Heading level={5}>Edit Milestones</Heading>
              <Button
                appearance="primary"
                onClick={handleSubmit}
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Control
              name="milestones"
              accepter={MilestoneInput}
              fieldError={formError.milestones}
              style={{ marginBottom: "5px" }}
            />
          </Col>
        </Row>
      </Grid>

      <ConfirmDialog
        onClose={onDialogClose}
        onConfirm={onConfirm}
        isOpen={isOpen}
        isLoading={mutation.isLoading}
      >
        <Text>Are you sure you want save these changes?</Text>
      </ConfirmDialog>
    </Form>
  )
}

export default MilestoneForm
