import { useState } from "react"
import { isEqual } from "lodash"

const useFormSaveDisable = (initalValue) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [formValue, setFormValue] = useState({
    ...initalValue,
  })

  const onFormChange = (e) => {
    if (!isEqual(e, initalValue)) {
      setIsSaveDisabled(false)
    } else {
      setIsSaveDisabled(true)
    }
    setFormValue(e)
  }

  return {
    onFormChange,
    isSaveDisabled,
    formValue,
    onFormValueChange: setFormValue,
  }
}

export default useFormSaveDisable
