export const metagoraDashboardData = /* GraphQL */ `
  query MetagoraDashboardData(
    $input: MetagoraDashboardInput
    $filter: [String]
  ) {
    metagoraDashboardData(input: $input, filter: $filter) {
      managers {
        name
        displayName
        sortOrder
        slug
        portfolios {
          name
          displayName
          slug
          account {
            broker
            accountNum
            accountName
            __typename
          }
          portfolioMeta {
            cashLevel
            impliedEquity
            leverageSetting
            leverage
            leverageToDisplay
            minDollarPositionValueToShow
            dailyReturnThreshold
            __typename
          }
          portfolioManager
          lastSync
          metaData {
            lastPNLDate
            todaysPNL
            todaysPNLValue
            yesterdaysPNLValue
            monthToDate
            quarterToDate
            yearToDate
            cumulativeReturnCash
            exposure
            todays_drawdown
            cashLevel
            leverageSetting
            currentLeverage
            marginMaintenance
            marginMaintenancePortfolioValue
            sharpeRatio
            highWater
            total_cumulative_return_pct
            avg_daily_return_pct
            avg_calendar_daily_return_pct
            total_cumulative_slippage_pct
            avg_daily_slippage_pct
            avg_calendar_daily_slippage_pct
            total_number_of_pnl_days
            total_number_of_slippage_days
            total_number_of_positions
            total_number_of_commodities
            total_number_of_unofficial_days
            __typename
          }
          cashLevel
          leverageSetting
          type
          status
          health {
            position_differences
            traded_differences
            unofficial_numbers
            daily_return_warning
            max_position_by_portfolio
            max_position_by_position
            __typename
          }
          __typename
        }
        totals {
          managerTotalProduction
          managerTotalExposure
          __typename
        }
        __typename
      }
      totals {
        overallManagerTotalProduction
        overallManagerTotalExposure
        __typename
      }
      __typename
    }
  }
`
