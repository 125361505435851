import React from "react"
import { Text, useTheme, View } from "@aws-amplify/ui-react"

const auth_components = {
  Header() {
    const { tokens } = useTheme()

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text
          fontSize="4em"
          padding={tokens.space.large}
          color={tokens.colors.neutral[40]}
        >
          Liver Capital
        </Text>
      </View>
    )
  },
}

export default auth_components
