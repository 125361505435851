import _ from "lodash"

const user_block = (slack_id) => {
  return {
    type: "context",
    elements: [
      {
        type: "mrkdwn",
        text: `Reported by <@${slack_id}>`,
      },
    ],
  }
}

const divider_block = {
  type: "divider",
}

const unofficial_flag = [
  {
    type: "text",
    text: "\t",
  },
  {
    type: "emoji",
    name: "large_blue_square",
  },
]
const warning_position_flag = [
  {
    type: "emoji",
    name: "exclamation",
  },
  {
    type: "text",
    text: " Attention Needed",
    style: {
      bold: true,
    },
  },
]

const out_of_position_flag = [
  {
    type: "text",
    text: " ",
  },
  {
    type: "emoji",
    name: "warning",
  },
]

const leverage_blocks = (pm_data) => {
  if (!Object.keys(pm_data).length) return []
  const pm_blcks = Object.keys(pm_data).map((pm) => ({
    type: "rich_text",
    elements: [
      {
        type: "rich_text_section",
        elements: [
          {
            type: "text",
            text: `${pm}:`,
            style: {
              bold: true,
            },
          },
        ],
      },
      {
        type: "rich_text_list",
        style: "ordered",
        indent: 0,
        border: 1,
        elements: _.orderBy(pm_data[pm], ["leverage_delta"], ["desc"]).map(
          (port) => ({
            type: "rich_text_section",
            elements: [
              {
                type: "text",
                text: `[${port.leverage.toFixed(2)}]`,
              },
              {
                type: "text",
                text: " ",
              },
              {
                type: "text",
                text: port.currentLev.toFixed(2),
              },
              {
                type: "text",
                text: "\t",
              },
              {
                type: "link",
                url: `https://portfolios.livercapital.com/${port.manager_slug}/${port.slug}/historical?r=L3M`,
                text: port.displayName,
                style: {
                  bold: true,
                },
              },
              ...(port.unofficial ? unofficial_flag : []),
              ...(port.position_differences > 0 ? out_of_position_flag : []),
              {
                type: "text",
                text: "\n",
              },
              {
                type: "text",
                text: ` ${port.note || " "}`,
              },
              {
                type: "text",
                text: "\n\n",
              },
              ...(port.isAttentionNeeded ? warning_position_flag : []),
            ],
          })
        ),
      },
    ],
  }))
  return _.flatten(pm_blcks.map((b) => [b, divider_block]))
}

const report_header = (title) => {
  return {
    type: "header",
    text: {
      type: "plain_text",
      text: title,
      emoji: true,
    },
  }
}

const slack_blocks = ({ slack_id, pm_data, title }) => ({
  blocks: [
    report_header(title),
    divider_block,
    ...leverage_blocks(pm_data),
    user_block(slack_id),
  ],
})

const report_data = (report) => {
  return report.map((r) => ({
    name: r.name,
    displayName: r.displayName,
    slug: r.slug,
    type: r.type,
    pm: r.portfolioManager,
    manager_displayName: r.manager_displayName,
    manager_slug: r.manager_slug,
    account: r.account,
    leverage: r.leverageSetting,
    leverage_delta: r.leverage_delta,
    currentLev: r.currentLeverage,
    unofficial: r.health.unofficial_numbers,
    unofficial_count: r.total_number_of_unofficial_days,
    position_differences: r.health.position_differences,
    note: r.notes,
    isAttentionNeeded: r.isAttentionNeeded,
  }))
}

const slack_report = ({ slack_id, report, threshold }) => {
  const slack_data = report_data(report)
  const grouped_slack_data = _.groupBy(slack_data, "pm")
  if (!slack_data.length) return null
  return slack_blocks({
    title: `Trader Dashboard - Realized Leverage Values (${threshold}):`,
    slack_id,
    pm_data: grouped_slack_data,
    threshold,
  })
}

export default slack_report
