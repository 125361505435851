import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { metagoraPortfolioPositionData } from "./graphql"
import { GraphQL_API } from "../../../../../routes/secure_loaders"
import { useMemo } from "react"

const REFRESH_INTERVAL = 1000 * 60 * 5

const getPositionData = async (managerSlug, portSlug) => {
  try {
    const { data } = await GraphQL_API(metagoraPortfolioPositionData, {
      input: {
        portSlug,
        managerSlug,
      },
    })
    return data.metagoraPortfolioPositionData
  } catch (error) {
    const errorData = error?.data?.metagoraPortfolioPositionData
    if (errorData) {
      return errorData
    } else {
      throw new Error("No Portfolio Positions")
    }
  }
}

const positionsQuery = ({ portfolioSlug, managerSlug }) => ({
  queryKey: [managerSlug, portfolioSlug, "positions"],
  queryFn: () => getPositionData(managerSlug, portfolioSlug),
  enabled: Boolean(portfolioSlug && managerSlug),
  refetchInterval: REFRESH_INTERVAL,
})

export const usePositionsQuery = (portfolioSlug, managerSlug) =>
  useQuery(positionsQuery({ portfolioSlug, managerSlug }))

const map_position_data = (pos) => ({
  id: pos.instrument_id,
  ticker: pos.symbol_root,
  expiry: pos.expiry,
  value: +(pos.quantity * pos.price * pos.multiplier).toFixed(),
  position_value: pos.position_value,
  absolute_pnl: pos.absolute_pnl,
  relative_pnl: pos.relative_pnl,
  startPriceRaw: pos.price,
  priceDiff: pos.price_diff,
  priceChange: pos.price_change,
  startQuantity: pos.quantity,
  endQuantity: pos.quantity,
  size: Math.abs(pos.quantity),
  initial_margin: pos.initial_margin,
  maintenance_margin: pos.maintenance_margin,
  margin_per_contract: pos.margin_per_contract,
  maintenance_margin_relative: pos.maintenance_margin_relative,
})

const map_commodity_to_position_data = (position_data) => (cmd) => {
  const {
    name,
    instrumentID,
    modelPosition,
    accountPosition,
    positionDifference,
    cumModelContractsTraded,
    cumAccountContractsTraded,
    cumContractsTradedDiff,
  } = cmd
  const [symbol_root, cmd_exp] = name.split("_")
  const pos = position_data.find(
    (p) => p.expiry === cmd_exp && p.symbol_root === symbol_root
  ) || {
    no_position: true,
  }
  return {
    ...map_position_data(pos),
    id: pos.instrument_id || instrumentID,
    ticker: pos.symbol_root || symbol_root,
    expiry: pos.expiry || cmd_exp,
    endPriceRaw: pos.price,
    modelPosition,
    accountPosition,
    positionDifference,
    cumModelContractsTraded,
    cumAccountContractsTraded,
    cumContractsTradedDiff,
    changed: Math.abs(cumContractsTradedDiff) >= 1,
    different: Math.abs(positionDifference) >= 1,
    cum_Contracts_Traded_Diff: Math.abs(cumContractsTradedDiff),
  }
}

const selectPositionData = (position_data, autoRecon) =>
  (autoRecon?.length
    ? autoRecon?.map(map_commodity_to_position_data(position_data))
    : position_data?.map(map_position_data)
  )?.sort((a, b) => a.ticker?.localeCompare(b.ticker) || a.expiry - b.expiry)

function arrayToObjectWithDefault(arr, defaultValue = 0) {
  return arr.reduce((obj, key) => {
    obj[key] = defaultValue
    return obj
  }, {})
}

const tradeServerSummary = (instrument_pnls, fields) => {
  const grouped = _.groupBy(instrument_pnls, "ticker")
  return Object.values(grouped).map((instrument) =>
    instrument.reduce(
      (tot, item) => {
        fields.forEach((field) => {
          tot[field] += item[field] || 0
        })
        return {
          ...tot,
          id: `${tot.id}_${item.id}`,
          leg_count: tot.leg_count + 1,
          ticker: item.ticker,
          endQuantity: item.endQuantity,
        }
      },
      {
        id: instrument[0].ticker,
        leg_count: 0,
        ...arrayToObjectWithDefault(fields),
      }
    )
  )
}

const make_chart_data = (ts_data, chart_field) => {
  const by_ticker = _.groupBy(ts_data, "ticker")
  const instrument_leg_reducer = _.reduce(
    by_ticker,
    (tot, item) => {
      const field_sum = _.sumBy(item, chart_field)
      const field_size = _.sumBy(item, (i) => Math.abs(i[chart_field]))
      const qty_sum = _.sumBy(item, "startQuantity")
      const leg_count = item.length

      if (field_sum) {
        const legs = _.orderBy(item, [chart_field], ["desc"]).map((i, inx) => ({
          ["L_" + inx]: i[chart_field],
          ["L_E_" + inx]: i.expiry,
          ["L_Q_" + inx]: i.startQuantity,
          name: i.ticker,
          expiry: i.expiry,
          diff: i.cum_Contracts_Traded_Diff,
        }))

        const legs_reduced = _.reduce(
          legs,
          (result, value) => {
            return {
              ...result,
              ...value,
              [chart_field]: field_sum,
              leg_count,
              field_size,
              qty_sum,
            }
          },
          {}
        )
        return [...tot, legs_reduced]
      } else {
        return tot
      }
    },
    []
  )
  return instrument_leg_reducer
}

const sumFields = (items, fields) =>
  fields.reduce(
    (acc, field) => {
      acc[`total_${field}`] = _.sumBy(items, field)
      return acc
    },
    {
      position_diff_abs: _.sumBy(
        items,
        (o) => Math.abs(o.positionDifference) || 0
      ),
      cum_abs_traded_diff: _.sumBy(
        items,
        (o) => Math.abs(o.cumContractsTradedDiff) || 0
      ),
    }
  )

export const useTradeServer = (
  position_data,
  autoRecon,
  chart_field,
  totals_fields
) => {
  const ts_data = useMemo(
    () => selectPositionData(position_data, autoRecon),
    [position_data, autoRecon]
  )
  const summary = useMemo(
    () => tradeServerSummary(ts_data, totals_fields),
    [ts_data, totals_fields]
  )

  const chart_data = useMemo(
    () => make_chart_data(ts_data, chart_field),
    [ts_data, chart_field]
  )

  const summary_chart_data = useMemo(
    () => make_chart_data(summary, chart_field),
    [summary, chart_field]
  )
  return {
    ts_data,
    summary,
    chart_data,
    summary_chart_data,
    totals: sumFields(ts_data, totals_fields),
  }
}
