import React, { useContext } from "react"
import _ from "lodash"
import useRebasedSlippageAxis from "./useRebasedSlippageAxis"
import slippage_config from "./slippage_config"
import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { generateClient } from "aws-amplify/api"
import { metagoraPortfolioSlippageData } from "./graphql"

const REFETCH_INTERVAL = 1000 * 60 * 3

const client = generateClient()

const GraphQL_API = (query, variables = {}) => {
  return client.graphql({ query, variables })
}
const getSlippageData = async (managerSlug, portSlug) => {
  try {
    const response = await GraphQL_API(metagoraPortfolioSlippageData, {
      input: {
        portSlug,
        managerSlug,
      },
    })
    return response.data.metagoraPortfolioSlippageData
  } catch (error) {
    if (error.data.metagoraPortfolioSlippageData) {
      return error.data.metagoraPortfolioSlippageData
    } else {
      throw new Error("No Slippage Positions")
    }
  }
}

const slippageQuery = (portfolioSlug, managerSlug) => ({
  queryKey: ["slippage", managerSlug, portfolioSlug],
  queryFn: async () => await getSlippageData(managerSlug, portfolioSlug),
  enabled: !!portfolioSlug && !!managerSlug,
  refetchInterval: REFETCH_INTERVAL,
})

let SlippageContext
const { Provider, Consumer } = (SlippageContext = React.createContext())

const SlippageProvider = ({ children }) => {
  const { managerSlug, portfolioSlug } = useParams()
  const query = useQuery(slippageQuery(portfolioSlug, managerSlug))
  const rebased_data = useRebasedSlippageAxis(query.data?.slippage?.data)
  // console.log(query.data)
  return (
    <Provider
      value={{
        data: _.reverse([...rebased_data]),
        displayName: query.data?.displayName,
        config: slippage_config,

        isLoading: query.isLoading,
        ui: {
          portfolio_id: query.data?.id,
          lastSync: query.data?.lastSync,
          endpoint: query.data?.slippageEndpoint,
        },
      }}
    >
      {children}
    </Provider>
  )
}

export { SlippageContext, Consumer as SlippageConsumer, SlippageProvider }

export const useSlippage = () => {
  const context = useContext(SlippageContext)
  return context
}
