import React from "react"
import ReactDOM from "react-dom/client"
import "rsuite/dist/rsuite.min.css"
import "./index.css"
import App from "./App"
// import reportWebVitals from "./reportWebVitals"

import "@aws-amplify/ui-react/styles.css"
import { Amplify } from "aws-amplify"
import config from "./amplifyconfiguration.json"
import { AuthProvider } from "./components/AmplifyAuth/AuthContext"
import { Authenticator } from "@aws-amplify/ui-react"
import auth_components from "./components/AmplifyAuth/auth_components"

import ReactGA from "react-ga4"

const TRACKING_ID = process.env.REACT_APP_GA_ID
const options = {
  testMode: process.env.NODE_ENV === "development",
}
ReactGA.initialize(TRACKING_ID, options)

Amplify.configure(config)

const Root = () => {
  return (
    <React.StrictMode>
      <Authenticator components={auth_components}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Authenticator>
    </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<Root />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
