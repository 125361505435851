import { useTheme } from "@aws-amplify/ui-react"
import { useMutation } from "@tanstack/react-query"
import React from "react"
import { RiBlazeFill, RiPercentLine } from "react-icons/ri"
import {
  Button,
  Col,
  Form,
  Grid,
  Heading,
  InputGroup,
  InputNumber,
  Row,
  Schema,
  Stack,
  Text,
} from "rsuite"
import ConfirmDialog from "../../../../components/common/ConfirmDialog"
import { useDialog } from "../../../../hooks/useDialog"
import useFormSaveDisable from "../hooks/useFormSaveDisable"

const MAX_MARGIN_BY_POSITION_THRESHOLD = 10
const MAX_MARGIN_BY_PORTFOLIO_THRESHOLD = 50
const DAILY_RETURN_DEFAULT = 20

const healthModel = Schema.Model({
  dailyReturnThreshold: Schema.Types.NumberType(),
  maxMarginPerPosition: Schema.Types.NumberType(),
  maxMarginPerPortfolio: Schema.Types.NumberType(),
})

const PortfolioHealthSettingsForm = ({ portfolio }) => {
  const { onDialogClose, onDialogOpen, isOpen } = useDialog()
  const { onFormChange, isSaveDisabled, formValue } = useFormSaveDisable({
    id: portfolio.id,
    dailyReturnThreshold: portfolio.dailyReturnThreshold
      ? `${portfolio.dailyReturnThreshold}`
      : `${DAILY_RETURN_DEFAULT}`,
    maxMarginPerPosition: portfolio.maxMarginPerPosition
      ? `${portfolio.maxMarginPerPosition}`
      : `${MAX_MARGIN_BY_POSITION_THRESHOLD}`,
    maxMarginPerPortfolio: portfolio.maxMarginPerPortfolio
      ? `${portfolio.maxMarginPerPortfolio}`
      : `${MAX_MARGIN_BY_PORTFOLIO_THRESHOLD}`,
  })
  const formRef = React.useRef()
  const mutation = useMutation({
    mutationKey: ["updatePortfolio"],
  })

  const { tokens } = useTheme()

  const onConfirm = () => {
    mutation.mutate(
      {
        ...formValue,
        dailyReturnThreshold: +formValue.dailyReturnThreshold,
        maxMarginPerPosition: +formValue.maxMarginPerPosition,
        maxMarginPerPortfolio: +formValue.maxMarginPerPortfolio,
      },
      {
        onSuccess: () => onDialogClose(),
      }
    )
  }

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      return
    }
    onDialogOpen()
  }
  return (
    <Form
      fluid
      ref={formRef}
      model={healthModel}
      formValue={formValue}
      onChange={onFormChange}
    >
      <Grid fluid>
        <Row
          style={{
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <Col xs={24}>
            <Stack justifyContent="space-between" alignItems="center">
              <Heading level={5}> Health Settings</Heading>
              <Button
                appearance="primary"
                onClick={handleSubmit}
                loading={mutation.isLoading}
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </Stack>
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs={24}>
            <Form.Group controlId="dailyReturnThreshold">
              <Form.ControlLabel>{`Daily Return Threshold (${DAILY_RETURN_DEFAULT})`}</Form.ControlLabel>
              <InputGroup>
                <InputGroup.Addon>
                  <RiPercentLine color={tokens.colors.metagora.red} />
                </InputGroup.Addon>
                <Form.Control
                  name="dailyReturnThreshold"
                  accepter={InputNumber}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs={24}>
            <Form.Group controlId="maxMarginPerPosition">
              <Form.ControlLabel>{`Max Margin Per Position (${MAX_MARGIN_BY_POSITION_THRESHOLD})`}</Form.ControlLabel>
              <InputGroup>
                <InputGroup.Addon>
                  <RiBlazeFill color={tokens.colors.metagora.red} />
                </InputGroup.Addon>
                <Form.Control
                  name="maxMarginPerPosition"
                  accepter={InputNumber}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs={24}>
            <Form.Group controlId="maxMarginPerPortfolio">
              <Form.ControlLabel>{`Max Margin Per Portfolio (${MAX_MARGIN_BY_PORTFOLIO_THRESHOLD})`}</Form.ControlLabel>
              <InputGroup>
                <InputGroup.Addon>
                  <RiBlazeFill color={tokens.colors.metagora.red} />
                </InputGroup.Addon>
                <Form.Control
                  name="maxMarginPerPortfolio"
                  accepter={InputNumber}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </Grid>
      <ConfirmDialog
        onClose={onDialogClose}
        onConfirm={onConfirm}
        isOpen={isOpen}
        isLoading={mutation.isLoading}
      >
        <Text>Are you sure you want save these changes?</Text>
      </ConfirmDialog>
    </Form>
  )
}

export default PortfolioHealthSettingsForm
