import moment from "moment"

export const dashedNumber = (value) => {
  if (!value || isNaN(value) || value === "NaN") return "-"
  return value.toFixed(2)
}

export const abbreviate_number = function (num, fixed) {
  if (num === null || num === undefined) {
    return null
  } // terminate early
  if (num === 0) {
    return "0"
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed // number of decimal places to show
  var b = num.toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ["", "K", "M", "B", "T"][k] // append power
  return e
}

export const priceFormat = (v, precision) => {
  if (v === null || v === undefined) {
    return null
  }
  const price = `$${v.toLocaleString(undefined, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  })}`
  if (precision > 0) {
    const places = price.split(".")
    return (
      <span>
        {places[0]}.<span className="decimal">{places[1]}</span>
      </span>
    )
  } else {
    return price
  }
}

export const formats = {
  pct: (value, num = 2) => {
    if (value === null || value === undefined) {
      return "0.00%"
    }
    return `${value.toLocaleString(undefined, {
      minimumFractionDigits: num,
      maximumFractionDigits: num,
    })}%`
    // return `${value.toFixed(2)}%`
  },
  float: (v, num = 4) => `${v ? v.toFixed(num) : "0.0000"}`,
  float_2: (v, num = 2) => `${v ? v.toFixed(num) : "0.00"}`,
  number: (value) => {
    if (value === null || value === undefined) {
      return 0
    }
    return `${value.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}`
  },
  price: (value, num = 3) => {
    if (value === null || value === undefined) {
      return null
    }
    if (value >= 100) {
      return priceFormat(value, 0)
    } else if (value < 100 && value > 10) {
      return priceFormat(value, 2)
    } else {
      return priceFormat(value, num)
    }
  },
  currency: (value, currency = "USD", maxDigits = 0) =>
    value !== null &&
    value?.toLocaleString("en-US", {
      style: "currency",
      currency,
      maximumFractionDigits: maxDigits,
      minimumFractionDigits: maxDigits,
    }),

  us_abbr: (v) => v !== null && abbreviate_number(v, 2),
  string: (v) => v,
  // UTC_date: (date) => date,
  UTC_date: (date) =>
    moment(date).isValid() ? moment(date).format("MM-DD h:mm a") : null,
  date:
    (format = "MM-DD h:mm a", initialFormat = "YYYY-MM-DD") =>
    (date) =>
      moment(date).isValid()
        ? moment(date, initialFormat).format(format)
        : null,
}

export const COLORS = {
  positive: "rgba(4, 255, 4, .9)",
  negative: "rgba(255, 0, 0, 0.4)",
  neutral: "#9E9E9E",
  warning: "rgba(220, 233, 40, 0.5)",
}

export const formatString = (str) => {
  if (!str) return str
  // Convert camelCase to spaces
  let formattedStr = str.replace(/([a-z])([A-Z])/g, "$1 $2")

  // Replace underscores with spaces
  formattedStr = formattedStr.replace(/_/g, " ")

  // Capitalize the first letter of the sentence
  // formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1)
  formattedStr = formattedStr.toLowerCase()

  return formattedStr
}
