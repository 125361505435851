import _ from "lodash"

/**
 * Function to calculate rebased data.
 * @param {Object} data - The data object.
 * @param {string} field - The field name.
 * @return {number} - The rebased data.
 */
const calculateRebasedData = (data, field) => {
  if (!data) {
    return 0
  }

  return data[field] > 0 ? -data[field] : Math.abs(data[field])
}

/**
 * Function to rebase and reduce data.
 * @param {Array} acc - The accumulator array.
 * @param {Object} curr - The current data object.
 * @param {number} index - The current index.
 * @param {Array} arr - The array of all data objects.
 * @return {Array} - The updated accumulator array.
 */
export const rebaseAndReduceData = (acc, curr, index, arr) => {
  const firstData = _.head(arr)

  const baseCumulativeReturn = calculateRebasedData(
    firstData,
    "cumulative_return"
  )
  const baseCumulativeReturnCash = calculateRebasedData(
    firstData,
    "cumulative_return_cash"
  )

  const { comp_cumulative_return: lastCompCumulativeReturn = 0 } =
    acc[index - 1] || {}
  const {
    comp_benchmark_cumulative_return: lastBenchmarkCumulativeReturn = 0,
  } = acc[index - 1] || {}

  const dailyReturn = curr["daily_return_pct"] / 100
  const benchmarkDailyReturn = curr["benchmark_daily_return_pct"] / 100

  const compCumulativeReturn =
    (1 + lastCompCumulativeReturn) * (1 + dailyReturn) - 1
  const compBenchmarkCumulativeReturn =
    (1 + lastBenchmarkCumulativeReturn) * (1 + benchmarkDailyReturn) - 1

  const newItem = {
    ...curr,
    comp_cumulative_return: compCumulativeReturn,
    comp_benchmark_cumulative_return: compBenchmarkCumulativeReturn,
    cumulative_return_pct: compCumulativeReturn * 100,
    cumulative_return:
      curr["cumulative_return"] + baseCumulativeReturn + firstData.daily_return,
    cumulative_return_cash:
      curr["cumulative_return_cash"] +
      baseCumulativeReturnCash +
      firstData.return_cash,
    rebased_benchmark_return: compBenchmarkCumulativeReturn * 100,
  }

  return [...acc, newItem]
}

/**
 * Function to rebase P&L data.
 * @param {Array} data - The array of data objects.
 * @return {Array} - The array of rebased data objects.
 */
const rebasePNL = (data) => {
  if (!data || !Array.isArray(data)) {
    throw new Error("Invalid data. Expected an array.")
  }

  return data.reduce(rebaseAndReduceData, [])
}

export default rebasePNL
