import React, { lazy, Suspense } from "react"
import { FlexboxGrid, Col, Panel, Loader } from "rsuite"
import { useControls } from "../../../../lib/controls/ControlContext"
import InstrumentsChart from "./charts/InstrumentsChart"
import { TraderDashboardProvider } from "./lib/trader_dashboard_context"

const TradeServerNav = lazy(() => import("./components/TradeServerNav"))
const TraderDashboardTable = lazy(() =>
  import("./components/TraderDashboardTable")
)

const TradeServerPage = () => {
  const { showChart } = useControls()
  return (
    <TraderDashboardProvider>
      <Suspense fallback={<Loader />}>
        <TradeServerNav />
      </Suspense>
      <FlexboxGrid>
        {showChart && (
          <FlexboxGrid.Item as={Col} colspan={24} sm={24} xs={24}>
            <Panel>
              <Suspense fallback={<Loader />}>
                <InstrumentsChart />
              </Suspense>
            </Panel>
          </FlexboxGrid.Item>
        )}
        <FlexboxGrid.Item as={Col} colspan={24} sm={24} xs={24}>
          <Panel>
            <Suspense fallback={<Loader />}>
              <TraderDashboardTable />
            </Suspense>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </TraderDashboardProvider>
  )
}

export default TradeServerPage
