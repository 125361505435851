import { useMutation } from "@tanstack/react-query"
import React from "react"
import { Form, Button, Grid, Stack, Row, Col, Heading, Text } from "rsuite"
import { accountModel } from "../controls/accounts/AccountInputControl"
import AccountInput from "./AccountInput"
import ConfirmDialog from "../../../../components/common/ConfirmDialog"
import { useDialog } from "../../../../hooks/useDialog"
import useFormSaveDisable from "../hooks/useFormSaveDisable"

const NewAccountForm = ({ portfolioId, accounts }) => {
  const formRef = React.useRef()

  const { onDialogClose, onDialogOpen, isOpen } = useDialog()
  const { onFormChange, isSaveDisabled, formValue } = useFormSaveDisable({
    accounts: accounts?.map((acct) => ({
      accountName: acct.accountName || "",
      accountNum: acct.accountNum,
      broker: acct.broker,
    })),
  })

  const [formError, setFormError] = React.useState({})

  const mutation = useMutation({
    mutationKey: ["updatePortfolio"],
  })

  const onConfirm = () => {
    mutation.mutate(
      {
        id: portfolioId,
        account: formValue.accounts,
      },
      {
        onSuccess: () => onDialogClose(),
      }
    )
  }

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      return
    }

    onDialogOpen()
  }

  return (
    <Form
      ref={formRef}
      model={accountModel}
      formValue={formValue}
      onChange={onFormChange}
      onCheck={setFormError}
      checkTrigger="blur"
    >
      <Grid fluid>
        <Row
          style={{
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <Col xs={24}>
            <Stack alignItems="center" justifyContent="space-between">
              <Heading level={5}>Edit Accounts</Heading>
              <Button
                appearance="primary"
                onClick={handleSubmit}
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Control
              name="accounts"
              accepter={AccountInput}
              fieldError={formError.accounts}
              style={{ marginBottom: "5px" }}
            />
          </Col>
        </Row>
      </Grid>

      <ConfirmDialog
        onClose={onDialogClose}
        onConfirm={onConfirm}
        isOpen={isOpen}
        isLoading={mutation.isLoading}
      >
        <Text>Are you sure you want save these changes?</Text>
      </ConfirmDialog>
    </Form>
  )
}

export default NewAccountForm
