import { generateClient } from "aws-amplify/api"
import * as gqlQuery from "../graphql/queries"
import {
  listAllManagers,
  getManagerAndPortfolios,
  managerBySlug,
  listPortfolioManagers,
} from "./graphql"
import _ from "lodash"
import { getPortfolioHealth } from "../sections/Portfolios/components/PortfolioHealth"
import getAccessFilter from "../components/AmplifyAuth/lib/getAccessFilter"

const client = generateClient()

export const GraphQL_API = (query, variables = {}) =>
  client.graphql({ query, variables })

// ************ Manager ******************

export const listManagers = async (auth) => {
  try {
    const { filter } = getAccessFilter("name", auth.userMeta)

    const { data } = await GraphQL_API(listPortfolioManagers, { filter })
    return _.orderBy(data.listPortfolioManagers.items, [
      "sortOrder",
      "displayName",
    ])
  } catch (e) {
    console.log(e)
    throw e
  }
}

async function fetchManagerPromises(slug) {
  let portfolioItems = []
  async function getManagerPorts(nextToken) {
    try {
      const { data } = await GraphQL_API(getManagerAndPortfolios, {
        slug,
        nextToken,
      })
      portfolioItems = [
        ...portfolioItems,
        ...data.managerBySlug.items[0].portfolios.items,
      ]

      if (data.managerBySlug.items[0].portfolios.nextToken) {
        await getManagerPorts(data.managerBySlug.items[0].portfolios.nextToken)
      }
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  await getManagerPorts()
  return portfolioItems.map(getPortfolioHealth)
}

const getManagerAndAllPortfolios = async (man) => ({
  displayName: man.displayName,
  slug: man.slug,
  id: man.id,
  color: man.color,
  sortOrder: man.sortOrder,
  name: man.name,
  displayMarketView: man.displayMarketView,
  portfolios: await fetchManagerPromises(man.slug),
})

export const getPortfolioManager = async (managerSlug) => {
  // console.log(managerSlug)
  try {
    const { data } = await GraphQL_API(managerBySlug, {
      slug: managerSlug,
    })
    const manager = data.managerBySlug.items.length
      ? data.managerBySlug.items[0]
      : null

    const manager_and_portfolio = await getManagerAndAllPortfolios(manager)

    return manager_and_portfolio
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getManagersAndPortfolios = async (auth) => {
  //Dashboard
  //ManagerAdminLayout
  try {
    const { filter } = getAccessFilter("name", auth.userMeta)

    const { data } = await GraphQL_API(listAllManagers, { filter })

    const allManagers = _.orderBy(data.listPortfolioManagers.items, [
      "sortOrder",
      "displayName",
    ])
    const manProm = allManagers.map(getManagerAndAllPortfolios)

    const allManResult = await Promise.all(manProm)
    return allManResult
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getPortfolio = async (manager, portfolioSlug) => {
  try {
    const { data } = await GraphQL_API(gqlQuery.metagoraPortfolioData, {
      input: {
        portSlug: portfolioSlug,
        managerSlug: manager,
      },
    })
    return data.metagoraPortfolioData
  } catch (error) {
    console.log(error)
    if (!error.data.metagoraPortfolioData) {
      throw new Error("No Portfolio")
    } else {
      throw error
    }
  }
}
