import React, { useState } from "react"
import {
  Button,
  Col,
  Form,
  Grid,
  Heading,
  InputNumber,
  Row,
  Schema,
  Stack,
  Text,
  Toggle,
} from "rsuite"
import { useNavigate } from "react-router-dom"
import InfoOutlineIcon from "@rsuite/icons/InfoOutline"
import { useMutation } from "@tanstack/react-query"
import ConfirmDialog from "../../../components/common/ConfirmDialog"
import { useDialog } from "../../../hooks/useDialog"

const model = Schema.Model({
  name: Schema.Types.StringType().isRequired("This field is required"),
  displayName: Schema.Types.StringType(),
  slug: Schema.Types.StringType()
    .isRequired("This field is required")
    .pattern(/^\S*$/, "Can not contain spaces"),
  sortOrder: Schema.Types.NumberType(),
  displayMarketView: Schema.Types.BooleanType(),
})

const ManagerForm = ({ manager, isAdmin }) => {
  const {
    onDialogClose: onRemoveDialogClose,
    onDialogOpen: onRemoveDialogOpen,
    isOpen: isRemoveOpen,
  } = useDialog()

  const { onDialogClose, onDialogOpen, isOpen } = useDialog()
  const navigate = useNavigate()
  const formRef = React.useRef()

  const mutation = useMutation({
    mutationKey: ["updateManager"],
  })
  const deleteMutation = useMutation({
    mutationKey: ["deleteManager"],
  })

  const [formValue, setFormValue] = useState({
    id: manager.id,
    name: manager.name,
    displayName: manager.displayName,
    slug: manager.slug,
    sortOrder: manager.sortOrder ? manager.sortOrder : 0,
    displayMarketView: manager.displayMarketView || false,
  })

  const onConfirm = () => {
    mutation.mutate(
      {
        ...formValue,
        sortOrder: +formValue.sortOrder,
      },
      {
        onSuccess: onDialogClose,
      }
    )
  }

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      return
    }

    onDialogOpen()
  }

  const onConfirmDelete = () => {
    deleteMutation.mutate(manager.id, {
      onSuccess: () => navigate(`/admin`),
    })
  }

  return (
    <Form
      ref={formRef}
      model={model}
      formValue={formValue}
      onChange={setFormValue}
      plaintext={!isAdmin}
      fluid
    >
      <Grid fluid style={{ paddingRight: "20px" }}>
        <Row
          style={{
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <Col xs={24} lg={24} md={24} xl={24}>
            <Stack justifyContent="space-between" alignItems="center">
              <Heading level={5}>Manager Settings</Heading>

              <Button
                appearance="primary"
                onClick={handleSubmit}
                disabled={!isAdmin}
              >
                Save
              </Button>
            </Stack>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col xs={24} lg={24} md={24} xl={24}>
            <Form.Group controlId="displayMarketView">
              <Form.ControlLabel>Display Market View</Form.ControlLabel>
              <Form.Control
                label="Market View"
                name="displayMarketView"
                accepter={Toggle}
                checked={formValue.displayMarketView}
                onChange={(isChecked) =>
                  setFormValue((fv) => ({
                    ...fv,
                    displayMarketView: isChecked,
                  }))
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs={24} lg={24} md={24} xl={24}>
            <Form.Group controlId="name">
              <Form.ControlLabel>Manager Name</Form.ControlLabel>
              <Form.Control name="name" readOnly />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs={24} lg={24} md={24} xl={24}>
            <Form.Group controlId="displayName">
              <Form.ControlLabel>Manager Display Name</Form.ControlLabel>
              <Form.Control name="displayName" />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs={24} lg={24} md={24} xl={24}>
            <Form.Group controlId="slug">
              <Form.ControlLabel>Manager Slug</Form.ControlLabel>
              <Form.Control name="slug" />
              <Form.HelpText>Must be same as name</Form.HelpText>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs={24} lg={24} md={24} xl={24}>
            <Form.Group controlId="sortOrder">
              <Form.ControlLabel>Manager Sort Order</Form.ControlLabel>
              <Form.Control
                label="Sort Order"
                name="sortOrder"
                accepter={InputNumber}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col
            xs={24}
            style={{
              border: "1px solid red",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <Heading level={4} style={{ marginBottom: "15px" }}>
              Danger!
            </Heading>
            <Stack
              alignItems="center"
              spacing={10}
              style={{ marginBottom: "20px" }}
            >
              <InfoOutlineIcon style={{ color: "red", fontSize: 24 }} />
              <Text>Be very careful when you are in this section!</Text>
            </Stack>
            <Button
              onClick={onRemoveDialogOpen}
              disabled={manager.ports?.length > 0 || !isAdmin}
              style={{ whiteSpace: "nowrap" }}
              color="red"
              appearance="primary"
            >
              Remove Manager
            </Button>
          </Col>
        </Row>
      </Grid>
      <ConfirmDialog
        onClose={onDialogClose}
        onConfirm={onConfirm}
        isOpen={isOpen}
        isLoading={mutation.isLoading}
      >
        <Text>Are you sure you want save these changes?</Text>
      </ConfirmDialog>
      <ConfirmDialog
        onClose={onRemoveDialogClose}
        onConfirm={onConfirmDelete}
        isOpen={isRemoveOpen}
        isLoading={mutation.isLoading}
        type="danger"
      >
        <Stack style={{}}>
          <Text>Do you really want to remove {manager.displayName}?</Text>
        </Stack>
      </ConfirmDialog>
    </Form>
  )
}

export default ManagerForm
