import { Icon } from "@rsuite/icons"
import React from "react"
import CsvDownloader from "react-csv-downloader"
import { FaDownload } from "react-icons/fa"

const CSVDownload = ({ data, filename, children }) => {
  return (
    <CsvDownloader
      datas={data}
      filename={filename}
      extension=".csv"
      separator=","
    >
      {children || <Icon as={FaDownload} />}
    </CsvDownloader>
  )
}

export default CSVDownload
