import React from "react"
import { Button, Col, Input, Modal, Row, Stack, Text, Toggle } from "rsuite"

const PNLNoteModal = ({
  noteModal,
  setNoteModal,
  handleAddNote,
  setReportPortfolios,
}) => {
  return (
    <Modal
      open={noteModal.open}
      onClose={() => setNoteModal({ ...noteModal, open: false })}
    >
      <Modal.Header>
        <Modal.Title>{noteModal.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={24}>
            <Text>Note</Text>
            <Input
              as="textarea"
              onChange={(e) => handleAddNote(e)}
              value={noteModal.value}
              rows={8}
            />
            <Stack justifyContent="flex-end" style={{ marginTop: "5px" }}>
              <Button onClick={() => handleAddNote("")} appearance="subtle">
                Clear Note
              </Button>
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div style={{ margin: "10px 0" }}>
              <Text size="sm" style={{ color: "#828387" }}>
                Toggle to set this PNL as important.
              </Text>
            </div>
            <Stack spacing={5}>
              <small>Attention Needed</small>
              <Toggle
                disabled={!noteModal.value}
                onChange={(e) =>
                  setReportPortfolios((prevState) => {
                    prevState[noteModal.index].isAttentionNeeded = e
                    return [...prevState]
                  })
                }
              />
            </Stack>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => setNoteModal({ ...noteModal, open: false })}
          appearance="secondary"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PNLNoteModal
