import React from "react"
import { Checkbox } from "rsuite"

const CustomCheckbox = ({
  queryName,
  match,
  setFormValue,
  keyName,
  query,
  label,
}) => {
  return (
    <Checkbox
      name={queryName}
      checked={match ? !!match : false}
      onChange={(v, checked) => {
        if (checked) {
          setFormValue((fv) => ({
            ...fv,
            [keyName]: fv[keyName] + query,
          }))
        } else {
          setFormValue((fv) => ({
            ...fv,
            [keyName]: fv[keyName].replace(match[0], ""),
          }))
        }
      }}
    >
      {label}
    </Checkbox>
  )
}

export default CustomCheckbox
