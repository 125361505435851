import { rebaseAndReduceData } from "./rebasePNL"

const usePNLData = ({ primaryKey, data, status, dateFilter }) => {
  const dailyCharMeta = (pnl, inx) => ({
    ...pnl,
    index: inx,
    bar_color: pnl?.official
      ? pnl[primaryKey] > 0
        ? "rgba(2, 173, 2, .8)"
        : "rgba(255, 0, 0, 0.7)"
      : "rgba(0, 0, 128, 1)", //"rgba(220, 95, 30, 1)",
  })

  const displayedChartData = (data?.chartData || [])
    .filter((d) => !JSON.parse(d.meta)?.previous)
    .filter((s) => dateFilter(s.period))
    .reduce(rebaseAndReduceData, [])
    .map(dailyCharMeta)
    .sort((a, b) => a.period.localeCompare(b.period))

  return {
    data,
    displayName: data?.displayName,
    status,
    displayedChartData,
  }
}

export default usePNLData
