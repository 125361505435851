import { useMutation } from "@tanstack/react-query"
import { useState } from "react"

const usePortfolioSync = (id) => {
  const [errorMessage, setErrorMessage] = useState(null)

  const mutation = useMutation({
    mutationKey: ["syncPortfolio"],
    onError: (error) => {
      console.log(error)
      setErrorMessage(error.message)
    },
  })

  const sync = (e) => {
    e.stopPropagation()
    setErrorMessage(null)
    mutation.mutate(id)
  }

  return {
    ...mutation,
    isPortfolioLoading: mutation.isLoading,
    errorMessage,
    sync,
  }
}

export default usePortfolioSync
