import { Navigate, createBrowserRouter } from "react-router-dom"
import managers_route from "../sections/Managers/route/managers_route"
import profile_route from "../components/AmplifyAuth/profile_route"
import { QueryClientProvider } from "@tanstack/react-query"
import queryClient from "./queryClient"
import Layout from "../components/Layout"
import ErrorPage from "../components/common/ErrorPage"
import resource_route from "../sections/Resources/resource_route"
import reporting_route from "../sections/Reporting/reporting_route"

const router_code = createBrowserRouter([
  {
    path: "/",
    element: (
      <QueryClientProvider client={queryClient}>
        <Layout />
      </QueryClientProvider>
    ),
    id: "root",
    errorElement: <ErrorPage />,
    children: [
      resource_route,
      reporting_route,
      managers_route,
      profile_route,
      {
        path: "",
        element: <Navigate to="quant" />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="quant" />,
  },
])

export default router_code
