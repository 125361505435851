import React from "react"
import { MdOutlineSpeed } from "react-icons/md"
import { Icon } from "@rsuite/icons"
import { Text, Panel, Stack } from "rsuite"

import DataSync from "./DataSync"
import ManagerStatus from "./ManagerStatus"
import PortfolioStatus from "./PortfolioStatus"

import styles from "./Managers.module.css"
import { useTheme } from "@aws-amplify/ui-react"

const PortfolioViewItem = ({
  portfolio,
  setSelectedPortfolio,
  selectedPortfolio,
  setIsPortfolioDrawerOpen,
}) => {
  const { tokens } = useTheme()
  const handleClick = (e) => {
    if (setIsPortfolioDrawerOpen) {
      setIsPortfolioDrawerOpen(false)
    }
    setSelectedPortfolio({ ...portfolio })
  }

  return (
    <Panel
      bodyFill
      className={`${styles.cardPortfolioItem} 
      ${selectedPortfolio?.id === portfolio.id && styles.portfolioItemSelected}
      `}
      onClick={handleClick}
    >
      <Stack justifyContent="space-between">
        <div>
          <Stack spacing={10} justifyContent="flex-start">
            <Text size="md">{portfolio.displayName}</Text>
          </Stack>
        </div>
        <DataSync port={portfolio} />
      </Stack>
      <Stack spacing={5} alignItems="center" style={{ minHeight: "32px" }}>
        {portfolio.posTrack || portfolio.autoRecon ? (
          <Icon
            style={{ fontSize: "20px" }}
            as={MdOutlineSpeed}
            color={tokens.colors.icons.auto_reconciliation}
          />
        ) : null}
        <div style={{ minWidth: "50px" }}>
          <ManagerStatus portfolio={portfolio} />
        </div>
        <div style={{ minWidth: "50px" }}>
          <PortfolioStatus portfolio={portfolio} />
        </div>
      </Stack>
    </Panel>
  )
}

export default PortfolioViewItem
