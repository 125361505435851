import React from "react"
import { Outlet, ScrollRestoration } from "react-router-dom"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { useIsFetching } from "@tanstack/react-query"
import { Container, Content, Footer } from "rsuite"
import MainNavBar from "./Nav/MainNavBar"

const LoadingIndicator = () => {
  const isFetching = useIsFetching()
  return [...Array(isFetching).keys()].map((key, inx) => (
    <div
      key={inx}
      className="loading-box"
      style={{ opacity: isFetching ? 1 : 0, width: `${inx * 5}%` }}
    />
  ))
}

const Layout = () => {
  return (
    <Container>
      <Content style={{ paddingBottom: 70 }}>
        <Outlet />
      </Content>
      <Footer>
        <MainNavBar />
        <LoadingIndicator />
        <ScrollRestoration />
        <ReactQueryDevtools initialIsOpen={false} position="top-left" />
      </Footer>
    </Container>
  )
}

export default Layout
