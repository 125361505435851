import React, { lazy, Suspense } from "react"

import { PNLProvider } from "./lib/PNLContext"
import { Col, Grid, Loader, Row } from "rsuite"

const PNLNavBar = lazy(() => import("./components/PNLNavBar"))
const PNLChart = lazy(() => import("./charts/ComparePNLChart/PNLChart"))
const PNLTable = lazy(() => import("./PNLTable"))
const DrawdownChart = lazy(() =>
  import("./charts/ComparePNLChart/DrawdownChart")
)

const HistoricalPNLPage = () => {
  return (
    <PNLProvider>
      <Grid fluid>
        <Row>
          <Col xs={24}>
            <Suspense fallback={<Loader />}>
              <PNLNavBar />
            </Suspense>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Suspense fallback={<Loader />}>
              <PNLChart />
            </Suspense>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Suspense fallback={<Loader />}>
              <DrawdownChart />
            </Suspense>
          </Col>
        </Row>
        <Row>
          <Col xs={24} id="historical_pnl">
            <Suspense fallback={<Loader />}>
              <PNLTable />
            </Suspense>
          </Col>
        </Row>
      </Grid>
    </PNLProvider>
  )
}

export default HistoricalPNLPage
