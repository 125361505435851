import React from "react"
import { usePNLRange } from "./usePNLConfig"
import usePNLData from "./usePNLData"
import { useBenchmarkParams } from "../components/BenchmarkOverlayMenu"
import { useQuery } from "@tanstack/react-query"
import pnl_data_config from "./pnl_data_config"
import { useControls } from "../../../../../lib/controls/ControlContext"
import { useParams } from "react-router-dom"
import { generateClient } from "aws-amplify/api"
import { mergedPortfolioChartData } from "./graphql"

const REFETCH_INTERVAL = 1000 * 60 * 5

const client = generateClient()

const GraphQL_API = (query, variables = {}) =>
  client.graphql({ query, variables })

const getPNLData = async ({
  managerSlug,
  portfolioSlug,
  range,
  benchmarkSlug,
  benchmarkManagerSlug,
}) => {
  //HistoricalPNL getPnLQuery
  try {
    const { data } = await GraphQL_API(mergedPortfolioChartData, {
      portSlug: portfolioSlug,
      managerSlug,
      range,
      benchmarkSlug,
      benchmarkManagerSlug,
    })
    return data.mergedPortfolioChartData
  } catch (e) {
    console.log(e)
    throw e.errors[0]
  }
}

const getPnLQuery = (
  managerSlug,
  portfolioSlug,
  range,
  benchmarkSlug,
  benchmarkManagerSlug
) => ({
  queryKey: [
    managerSlug,
    portfolioSlug,
    "pnl",
    range ?? "YTD",
    benchmarkSlug ?? null,
  ],
  queryFn: () =>
    getPNLData({
      managerSlug,
      portfolioSlug,
      range,
      benchmarkSlug,
      benchmarkManagerSlug,
    }),
  refetchInterval: REFETCH_INTERVAL,
})

let PNLContext
const { Provider, Consumer } = (PNLContext = React.createContext())

const PNLProvider = ({ children }) => {
  const { managerSlug, portfolioSlug } = useParams()
  const {
    dateFilter,
    resetSelectedDateFilter,
    dateRangeFilter,
    compactPnlTable,
    primaryKey,
  } = useControls()

  const { handleDateChange, range } = usePNLRange()
  const { handleBenchmarkChange, b_slug, bm_slug } = useBenchmarkParams()

  const query = getPnLQuery(managerSlug, portfolioSlug, range, b_slug, bm_slug)

  const { data, status, isLoading } = useQuery({
    ...query,
    onSuccess: (data) => {
      if (!!data && data?.dateOptions.indexOf(range) < 0) {
        handleDateChange("YTD")
        resetSelectedDateFilter()
      }
    },
  })
  const { displayName, displayedChartData } = usePNLData({
    primaryKey: primaryKey.value,
    data,
    status,
    dateFilter: dateRangeFilter,
  })

  const config = pnl_data_config({
    leverageToDisplay: data?.portfolioMeta?.leverageToDisplay,
    leverage: data?.portfolioMeta?.leverage,
    compact: compactPnlTable.isDefault,
  })
  return (
    <Provider
      value={{
        ui: {
          nav_bar: {
            displayName,
            endpoint: data?.endpoint,
            download_data: data?.chartData?.filter((d) => !d.meta),
            portfolio_id: data?.id,
            lastSync: data?.lastSync,
            isLoading,
          },
          benchmark: {
            handleBenchmarkChange,
            b_slug,
            bm_slug,
          },
          displayName,
          range,
          endpoint: data?.endpoint,
          dateRange: dateFilter,
          dateOptions: data?.dateOptions,

          handleDateChange,

          isZoomed: dateFilter.endIndex !== "" && dateFilter.startIndex !== "",
          download_data: data?.chartData?.filter((d) => !d.meta),
          portfolio_id: data?.id,
        },
        table: {
          data: data?.chartData?.filter((d) => !d.meta),
          config,
          isLoading,
          status,
        },
        chart: {
          data: displayedChartData,
          showBenchmark: !!b_slug,
          leverage: data?.portfolioMeta?.leverage,
          leverageField:
            data?.portfolioMeta?.leverageToDisplay === "REALIZED_LEVERAGE"
              ? "realized_leverage"
              : data?.portfolioMeta?.leverageToDisplay === "LEVERAGE_CASH"
              ? "leverage_cash"
              : "leverage",
          benchmarkDisplayName: data?.benchmarkDisplayName,
        },
        milestones: data?.milestones,
      }}
    >
      {children}
    </Provider>
  )
}

export { PNLContext, Consumer as PNLConsumer, PNLProvider }
