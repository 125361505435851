export const columnAbbr = {
  lastPNLDate: {
    title: "Last PNL Date",
    abbrv: "LPNLD",
  },
  todaysPNL: {
    title: "Todays PNL",
    abbrv: "TPNL",
  },
  todaysPNLValue: {
    title: "Todays PNL Value",
    abbrv: "TPNLV",
    isCurrency: true,
  },
  yesterdaysPNLValue: {
    title: "Yesterdays PNL Value",
    abbrv: "YPNLV",
    isCurrency: true,
  },
  monthToDate: {
    title: "Month To Date",
    abbrv: "MTD",
  },
  quarterToDate: {
    title: "Quarter To Date",
    abbrv: "QTD",
  },
  yearToDate: {
    title: "Year To Date",
    abbrv: "YTD",
  },
  cumulativeReturnCash: {
    title: "Cumulative Return Cash",
    abbrv: "CRC",
  },
  exposure: {
    title: "Exposure",
    abbrv: "EXPO",
  },
  todays_drawdown: {
    title: "Todays Drawdown",
    abbrv: "TD",
  },
  cashLevel: {
    title: "Cash Level",
    abbrv: "CL",
    isCurrency: true,
  },
  leverageSetting: {
    title: "Leverage Setting",
    abbrv: "LS",
  },
  currentLeverage: {
    title: "Current Leverage",
    abbrv: "CL",
  },
  marginMaintenance: {
    title: "Margin Maintenance",
    abbrv: "MM",
  },
  marginMaintenancePortfolioValue: {
    title: "Margin Maintenance Portfolio Value",
    abbrv: "MMPV",
  },
  sharpeRatio: {
    title: "Sharpe Ratio",
    abbrv: "SR",
  },
  highWater: {
    title: "High Water",
    abbrv: "HW",
  },
  total_cumulative_return_pct: {
    title: "Total Cumulative Return Percent",
    abbrv: "TCRP",
  },
  avg_daily_return_pct: {
    title: "Average Daily Return Percent",
    abbrv: "ADRP",
  },
  avg_calendar_daily_return_pct: {
    title: "Average Calendar Daily Return Percent",
    abbrv: "ACDRP",
  },
  total_cumulative_slippage_pct: {
    title: "Total Cumulative Slippage Percent",
    abbrv: "TCSP",
  },
  avg_daily_slippage_pct: {
    title: "Average Daily Slippage Percent",
    abbrv: "ADSP",
  },
  avg_calendar_daily_slippage_pct: {
    title: "Average Calendar Daily Slippage Percent",
    abbrv: "ACDSP",
  },
  total_number_of_pnl_days: {
    title: "Total Number of PNL Days",
    abbrv: "TNPNLD",
  },
  total_number_of_slippage_days: {
    title: "Total Number of Slippage Days",
    abbrv: "TNSD",
  },
  total_number_of_positions: {
    title: "Total Number of Positions",
    abbrv: "TNP",
  },
  total_number_of_commodities: {
    title: "Total Number of Commodities",
    abbrv: "TNC",
  },
  total_number_of_unofficial_days: {
    title: "Total Number of Unofficial Days",
    abbrv: "TNUD",
  },
}
