export const mergedPortfolioChartData = /* GraphQL */ `
  query MergedPortfolioChartData(
    $portSlug: String!
    $managerSlug: String!
    $range: String
    $benchmarkSlug: String
    $benchmarkManagerSlug: String
  ) {
    mergedPortfolioChartData(
      portSlug: $portSlug
      managerSlug: $managerSlug
      range: $range
      benchmarkSlug: $benchmarkSlug
      benchmarkManagerSlug: $benchmarkManagerSlug
    ) {
      id
      name
      displayName
      benchmarkDisplayName
      benchmarkSlug
      summaryType
      dateOptions
      selectedRange
      slug
      status
      portfolioManager
      lastSync
      endpoint
      milestones {
        title
        date
        color
      }
      chartData {
        period
        official
        portfolio_value
        daily_return
        cumulative_return
        daily_return_pct
        benchmark_cumulative_return_pct
        benchmark_daily_return_pct
        cumulative_return_pct
        leverage
        leverage_cash
        realized_leverage
        return_cash
        cumulative_return_cash
        margin_initial
        margin_maintenance
        pct_drawdown
        trade_level
        deposit
        drawdown_days
        time_to_recovery
        rebased_benchmark_return
        rebased_return
        rebased
        rebased_lev
        abs_return
        meta
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      __typename
    }
  }
`
