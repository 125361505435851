import React from "react"
import { Panel } from "rsuite"

import styles from "./Common.module.css"

const CardContainer = ({
  className,
  children,
  bgColor,
  style,
  onClick,
  ...props
}) => {
  return (
    <Panel
      bodyFill
      className={`${styles.cardItem} ${className}`}
      style={{
        background: bgColor,
        ...style,
      }}
      props={props}
      onClick={onClick}
    >
      {children}
    </Panel>
  )
}

export default CardContainer
