import React from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Form, Schema, InputGroup } from "rsuite"
import PlusIcon from "@rsuite/icons/Plus"
import { useMutation } from "@tanstack/react-query"

const portfolioModel = Schema.Model({
  name: Schema.Types.StringType().isRequired("This field is required"),
  displayName: Schema.Types.StringType(),
  slug: Schema.Types.StringType(),
  portfolioManager: Schema.Types.StringType(),
  status: Schema.Types.StringType(),
})

const CreateNewPortfolio = ({ portfolioManager }) => {
  const navigate = useNavigate()
  const mutation = useMutation({
    mutationKey: ["createPortfolio"],
  })
  const formRef = React.useRef()
  const initialFormState = {
    name: "",
    displayName: "",
    slug: "",
    status: "DRAFT",
  }
  const [formValue, setFormValue] = useState(initialFormState)
  const [formError, setFormError] = React.useState({})

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      return
    }
    const newPort = {
      ...formValue,
      displayName: formValue.name,
      slug: formValue.name.split(" ").join("-"),
      status: "DRAFT",
      portfolioManager: portfolioManager,
    }
    mutation.mutate(newPort, {
      onSuccess: () => {
        setFormValue(initialFormState)
        navigate(`/quant/admin/${newPort.portfolioManager}`)
      },
    })
  }
  return (
    <Form
      ref={formRef}
      model={portfolioModel}
      formValue={formValue}
      onChange={setFormValue}
      onCheck={setFormError}
    >
      <Form.Group controlId="name">
        <InputGroup style={{ width: "100%" }}>
          <Form.Control
            name="name"
            placeholder={
              !!formError.name
                ? formError.name
                : `${portfolioManager} Portfolio Name`
            }
            errorMessage={null}
          />
          <InputGroup.Button
            loading={mutation.isLoading}
            onClick={handleSubmit}
          >
            <PlusIcon />
          </InputGroup.Button>
        </InputGroup>
      </Form.Group>
    </Form>
  )
}

export default CreateNewPortfolio
