import { useState } from "react"
import { useLocalStorage } from "../../../../../lib/useLocalStorage"
import { useSearchParams } from "react-router-dom"
import { useEffect } from "react"

export const usePNLRange = () => {
  const [selectedRange, setSelectedRange] = useLocalStorage(
    "selectedRange",
    "YTD"
  )

  const [params, setSearchParams] = useSearchParams()
  let range = params.get("r")

  useEffect(() => {
    params.set("r", selectedRange)
    setSearchParams(params)
  }, [params, selectedRange, setSearchParams])

  const handleDateChange = (dateOpt) => {
    setSelectedRange(dateOpt)
    params.set("r", dateOpt)
    setSearchParams(params)
  }

  return {
    handleDateChange,
    range,
  }
}

export const usePNLDateRange = () => {
  const [params, setSearchParams] = useSearchParams()
  let date_range = params.get("d")

  const handleDateRangeChange = (dateOpt) => {
    params.set("d", dateOpt)
    setSearchParams(params)
  }

  return {
    handleDateRangeChange,
    date_range,
  }
}

export const usePNLBenchmark = () => {
  const [params, setSearchParams] = useSearchParams()
  let benchmark_slug = params.get("b")

  const handleBenchmarkChange = (benchmarkSlug) => {
    setSearchParams({
      b: benchmarkSlug,
    })
  }

  return {
    benchmark_slug,
    handleBenchmarkChange,
  }
}

export const usePNLConfig = () => {
  const [primaryKey, setPrimaryKey] = useLocalStorage(
    "primaryChartKey",
    "cumulative_return_pct"
  )
  const [showLabels, setShowLabels] = useLocalStorage("showLabels", "SHOW")
  const [showTooltip, setShowTooltip] = useState("show")

  return {
    primaryKey,
    setPrimaryKey,
    showTooltip,
    setShowTooltip,
    showLabels,
    setShowLabels,
  }
}
