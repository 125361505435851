import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

export const useSelectedPortfolio = (portfolios, query) => {
  const [selectedPortfolio, setSelectedPortfolio] = useState({})

  const setSearchParams = useSearchParams()[1]

  const changeSelectedPortfolio = (portfolio) => {
    setSearchParams({ q: portfolio.slug })
    setSelectedPortfolio({ ...portfolio })
  }

  useEffect(() => {
    setSelectedPortfolio(portfolios?.find((p) => p.slug === query))
  }, [portfolios, query])

  return { selectedPortfolio, changeSelectedPortfolio }
}
