import moment from "moment"

const DateFormat = ({
  date,
  format = "MM-DD h:mm a",
  initialFormat = "YYYY-MM-DD",
}) =>
  moment(date, initialFormat).isValid()
    ? moment(date, initialFormat).format(format)
    : null

export default DateFormat
