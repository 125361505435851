import React from "react"
import { Nav } from "rsuite"
import SettingIcon from "@rsuite/icons/Setting"
import { useControls } from "../../../lib/controls/ControlContext"

const ControlsNavMenu = () => {
  const { showLabels, showTooltip, primaryKey } = useControls()
  return (
    <Nav.Menu
      icon={<SettingIcon />}
      placement="leftStart"
      openDirection="start"
    >
      <Nav.Item active={showLabels.isDefault} onClick={showLabels.toggle}>
        {!showLabels.isDefault ? "Hide" : `Show`} Labels
      </Nav.Item>
      <Nav.Item active={!showTooltip.isDefault} onClick={showTooltip.toggle}>
        {showTooltip.isDefault ? "Hide" : `Show`} Tooltip
      </Nav.Item>

      <Nav.Menu
        title="Cumulative Return"
        placement="bottomEnd"
        openDirection="end"
      >
        <Nav.Item
          onClick={() => primaryKey.setValue("cumulative_return_pct")}
          active={primaryKey.isDefault}
        >
          Cumulative Return
        </Nav.Item>
        <Nav.Item
          onClick={() => primaryKey.setValue("cumulative_return_cash")}
          active={!primaryKey.isDefault}
        >
          Cumulative Return Cash
        </Nav.Item>
      </Nav.Menu>
    </Nav.Menu>
  )
}

export default ControlsNavMenu
