import { useMutation } from "@tanstack/react-query"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Form, Schema, InputGroup } from "rsuite"
import PlusIcon from "@rsuite/icons/Plus"

const portfolioManagerModel = Schema.Model({
  name: Schema.Types.StringType().isRequired("This field is required"),
  displayName: Schema.Types.StringType(),
  slug: Schema.Types.StringType(),
})

const CreateNewPM = () => {
  const mutation = useMutation({
    mutationKey: ["createManager"],
  })
  const navigate = useNavigate()

  const formState = { name: "" }
  const formRef = React.useRef()
  const [formValue, setFormValue] = useState(formState)
  const [formError, setFormError] = React.useState({})

  const handleSubmit = () => {
    const newPM = {
      name: formValue.name,
      displayName: formValue.name?.split("-").join(" "),
      slug: formValue.name?.split(" ").join("-"),
    }
    mutation.mutate(newPM, {
      onSuccess: () => navigate(`/quant/admin/${newPM.slug}`),
    })
    setFormValue(formState)
  }

  return (
    <Form
      ref={formRef}
      model={portfolioManagerModel}
      formValue={formValue}
      onChange={setFormValue}
      onCheck={setFormError}
    >
      <Form.Group>
        <InputGroup style={{ width: "100%" }}>
          <Form.Control
            name="name"
            placeholder={
              !!formError.name ? formError.name : `Portfolio Manager Name`
            }
          />
          <InputGroup.Button
            loading={mutation.isLoading}
            onClick={handleSubmit}
          >
            <PlusIcon />
          </InputGroup.Button>
        </InputGroup>
      </Form.Group>
    </Form>
  )
}

export default CreateNewPM
