import React from "react"
import { Nav } from "rsuite"
import NavItemButton from "../components/NavItemButton"
import { useControls } from "../../../lib/controls/ControlContext"

const PortfolioNav = ({ port }) => {
  const { showTradeServerSummary } = useControls()
  return (
    <Nav pullRight>
      <NavItemButton
        to={"historical"}
        icon={"historical"}
        text={"Historical PNL"}
      />

      {!!port?.positionsEndpoint && !!port?.account?.length > 0 && (
        <NavItemButton
          to={`tradeserver?mode=${showTradeServerSummary.value}`}
          icon={"tradeserver"}
          text={"Trade Server"}
        />
      )}
      {!!port?.slippageEndpoint && (
        <NavItemButton to={"slippage"} icon={"slippage"} text={"Slippage"} />
      )}
    </Nav>
  )
}

export default PortfolioNav
