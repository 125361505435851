import React, { useEffect, useState } from "react"
import {
  Button,
  Checkbox,
  Col,
  Container,
  Content,
  Drawer,
  Form,
  Header,
  Heading,
  Input,
  InputGroup,
  Modal,
  Row,
  Stack,
  Text,
  Toggle,
  SelectPicker,
  Loader,
} from "rsuite"
import SearchIcon from "@rsuite/icons/Search"
import PlusIcon from "@rsuite/icons/Plus"
import { useDashboard } from "../Dashboard/lib/useDashboard"
import OverallTotal from "./components/OverallTotal"
import ReportManagers from "./components/ReportManagers"
import ReportUnofficial from "./components/ReportUnofficial"
import { useSlackReporting } from "./hooks/useSlackReporting"
import SlackBlocks from "./components/SlackBlocks"
import useAuth from "../../components/AmplifyAuth/useAuth"
import CardContainer from "../../components/common/CardContainer"
import PNLNoteModal from "./components/PNLNoteModal"

const ReportingDashboard = () => {
  const { onConvertToSlack, slack_blocks, onSendToSlack } = useSlackReporting()
  const { data, isLoading } = useDashboard(undefined, false)

  const { userMeta } = useAuth()
  const [leverageValue, setLeverageValue] = useState(0)
  const [reportPortfolios, setReportPortfolios] = useState([])
  const [unofficialPNL, setUnofficialPNL] = useState([])
  const [customTableColumn, setCustomTableColumn] = useState([])
  const [showSlackReportModal, setShowSlackReportModal] = useState(false)
  const [noteModal, setNoteModal] = useState({
    title: "",
    message: "",
    type: "",
    open: false,
    index: null,
    value: "",
  })
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = useState(false)
  const [isReportCreateModalOpen, setIsReportCreateModalOpen] = useState(false)

  const [reportIncludes, setReportIncludes] = useState({
    pnl: true,
    unofficial: true,
  })

  useEffect(() => {
    setReportPortfolios(data?.managers)
    setUnofficialPNL(
      data?.managers?.filter((manager) => manager.health.unofficial_numbers)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data?.managers)])

  const handleReportDataUpdate = () => {
    setReportPortfolios(() => {
      return data?.managers
        .filter((p) => ["PRODUCTION", "PAPER"].indexOf(p.type) >= 0)
        .map((p) => ({
          ...p,
          notes: "",
          reporting_threshold: leverageValue,
          leverage_delta: +Math.abs(
            p.currentLeverage - p.leverageSetting
          ).toFixed(2),
          leverage_pct: (p.currentLeverage / p.leverageSetting) * 100,
          leverage_threshold: +(p.leverageSetting * leverageValue).toFixed(4),
        }))
        .filter((p) => Math.abs(p.leverage_delta) > p.leverage_threshold)
    })
  }

  const handleRemoveFromReport = (index, type) => {
    switch (type) {
      case "official":
        setReportPortfolios((prevState) => {
          prevState.splice(index, 1)

          return [...prevState]
        })
        break
      case "unofficial":
        setUnofficialPNL((prevState) => {
          prevState.splice(index, 1)

          return [...prevState]
        })
        break

      default:
        break
    }
  }

  const handleRefreshList = (type) => {
    switch (type) {
      case "official":
        setReportPortfolios(data?.managers)
        break
      case "unofficial":
        setUnofficialPNL(
          data?.managers?.filter((manager) => manager.health.unofficial_numbers)
        )
        break

      default:
        break
    }
  }

  const handleAddNote = (e) => {
    setNoteModal((prevState) => ({
      ...prevState,
      value: e,
    }))

    switch (noteModal.type) {
      case "official":
        setReportPortfolios((prevState) => {
          prevState[noteModal.index].notes = e
          return [...prevState]
        })
        break
      case "unofficial":
        setUnofficialPNL((prevState) => {
          prevState[noteModal.index].notes = e
          return [...prevState]
        })
        break

      default:
        break
    }
  }

  const generateSlackReport = () => {
    let reportData = []

    if (reportIncludes.pnl) {
      reportData = [
        ...reportData,
        ...reportPortfolios.filter(
          (rP) => rP.checked && !rP.health.unofficial_numbers
        ),
      ]
    }

    if (reportIncludes.unofficial) {
      reportData = [...reportData, ...unofficialPNL]
    }

    onConvertToSlack({
      portfolios: reportData,
      reportingThreshold: leverageValue,
    })

    setShowSlackReportModal(true)
  }

  const handleSendToSlack = () => {
    onSendToSlack()
    setShowSlackReportModal(false)
  }

  const handleCheck = (e, index) => {
    setReportPortfolios((prevState) => {
      prevState[index].checked = e
      return [...prevState]
    })
  }

  const handleCheckAll = (e) => {
    setReportPortfolios((prevState) => {
      return prevState.map((pS) => ({ ...pS, checked: e }))
    })
  }

  return (
    <Container style={{ padding: "0.8rem 1.8rem 0 1.8rem" }}>
      <Header>
        <Row style={{ paddingTop: 0 }}>
          <Col xs={24}>
            <Stack
              alignItems="center"
              justifyContent="space-between"
              style={{ padding: "0.5rem" }}
            >
              <Stack.Item>
                <Heading level={4}>Reporting Dashboard</Heading>
                <Text size="sm" style={{ color: "#828387" }}>
                  Shows data the needs attetion in the system
                </Text>
              </Stack.Item>
              <Stack alignItems="flex-end" direction="column">
                <Text size="md">{userMeta?.email}</Text>
                <Text size="sm" style={{ color: "#828387" }}>
                  {userMeta?.groups.join(", ")}
                </Text>
              </Stack>
            </Stack>
          </Col>
        </Row>
      </Header>
      {isLoading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          style={{ padding: "30px" }}
        >
          <Loader content="Loading Report..." vertical size="md" />
        </Stack>
      ) : (
        <Content>
          <Row style={{ margin: "20px 0" }}>
            <Col xs={24}>
              <Stack justifyContent="space-between">
                <OverallTotal
                  aumValue={data?.totals?.overallManagerTotalProduction}
                  exposureValue={data?.totals?.overallManagerTotalExposure}
                />
                <Button
                  onClick={() => setIsReportCreateModalOpen(true)}
                  appearance="subtle"
                  block
                  startIcon={<PlusIcon />}
                >
                  Create Report
                </Button>
              </Stack>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={8}>
              <ReportUnofficial
                unofficialPNL={unofficialPNL}
                handleRemoveFromReport={handleRemoveFromReport}
                handleRefreshList={handleRefreshList}
                setNoteModal={setNoteModal}
              />
            </Col>
            <Col xs={16}>
              <Stack
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: "1rem", padding: "0rem 1rem 0 0" }}
              >
                <div style={{ margin: "10px 0" }}>
                  <Heading level={6} style={{ color: "orange" }}>
                    Reports
                  </Heading>
                  <Text size="sm" style={{ color: "#828387" }}>
                    List of reports
                  </Text>
                </div>
              </Stack>
              <Row>
                <Col xs={6}>
                  <CardContainer>First Report</CardContainer>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <ReportManagers
                customTableColumn={customTableColumn}
                generateSlackReport={generateSlackReport}
                portfolios={reportPortfolios}
                handleRemoveFromReport={handleRemoveFromReport}
                handleRefreshList={handleRefreshList}
                setNoteModal={setNoteModal}
                setIsSettingsDrawerOpen={setIsSettingsDrawerOpen}
                handleCheck={handleCheck}
                handleCheckAll={handleCheckAll}
              />
            </Col>
          </Row>
        </Content>
      )}

      <Drawer
        size="xs"
        placement="left"
        open={isSettingsDrawerOpen}
        onClose={() => setIsSettingsDrawerOpen(false)}
        style={{ padding: "10px" }}
      >
        <Content style={{ padding: "20px" }}>
          <Row>
            <Col xs={24}>
              <div style={{ margin: "10px 0" }}>
                <Heading level={6} style={{ color: "orange" }}>
                  Report Settings
                </Heading>
                <Text size="sm" style={{ color: "#828387" }}>
                  Dashboard and slack report settings
                </Text>
              </div>
              <InputGroup inside style={{ marginBottom: "0.5rem" }}>
                <Input placeholder="Leverage" onChange={setLeverageValue} />
                <InputGroup.Button>
                  <SearchIcon />
                </InputGroup.Button>
              </InputGroup>

              <Stack spacing={8} style={{ marginBottom: "1rem" }}>
                <Stack.Item flex={1}>
                  <Button block onClick={handleReportDataUpdate}>
                    Select {leverageValue}%
                  </Button>
                </Stack.Item>
                <Stack.Item flex={1}>
                  <Button block>Restart</Button>
                </Stack.Item>
              </Stack>
              <div style={{ margin: "10px 0" }}>
                <Text size="sm" style={{ color: "#828387" }}>
                  Toggle which data should be included in the report
                </Text>
              </div>
              <Stack
                style={{ margin: "10px 0" }}
                justifyContent="space-between"
              >
                PNL
                <Toggle
                  defaultChecked
                  checked={reportIncludes.pnl}
                  onChange={(e) =>
                    setReportIncludes((prevState) => ({
                      ...prevState,
                      pnl: e,
                    }))
                  }
                />
              </Stack>
              <Stack
                style={{ margin: "10px 0" }}
                justifyContent="space-between"
              >
                Unofficial PNL
                <Toggle
                  defaultChecked
                  checked={reportIncludes.unofficial}
                  onChange={(e) =>
                    setReportIncludes((prevState) => ({
                      ...prevState,
                      unofficial: e,
                    }))
                  }
                />
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <div style={{ margin: "10px 0" }}>
                <Heading level={6} style={{ color: "orange" }}>
                  Table Settings
                </Heading>
                <Text size="sm" style={{ color: "#828387" }}>
                  Select columns you want to be visible
                </Text>
              </div>
              {
                <div style={{ maxHeight: "350px", overflow: "auto" }}>
                  {Object.keys(reportPortfolios?.[0]?.metaData || {}).map(
                    (md, index) => (
                      <Stack spacing={10} alignItems="center" key={index}>
                        <Checkbox
                          checked={customTableColumn.includes(md)}
                          color="orange"
                          onCheckboxClick={(e) =>
                            setCustomTableColumn((prevState) => {
                              if (e.target.checked) {
                                return [...prevState, md]
                              }

                              const psIndex = prevState.indexOf(md)

                              prevState.splice(psIndex, 1)
                              return [...prevState]
                            })
                          }
                        />{" "}
                        <Text size="sm">
                          {md.toUpperCase().split("_").join(" ")}
                        </Text>
                      </Stack>
                    )
                  )}
                </div>
              }
            </Col>
          </Row>
        </Content>
      </Drawer>
      <Modal
        open={showSlackReportModal}
        onClose={() => setShowSlackReportModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Slack Report Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SlackBlocks blocks={slack_blocks?.blocks} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSendToSlack} appearance="primary">
            Send To Slack
          </Button>
          <Button
            onClick={() => setShowSlackReportModal(false)}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showSlackReportModal}
        onClose={() => setShowSlackReportModal(false)}
      >
        <Modal.Header>
          <Modal.Title>Slack Report Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SlackBlocks blocks={slack_blocks?.blocks} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSendToSlack} appearance="primary">
            Send To Slack
          </Button>
          <Button
            onClick={() => setShowSlackReportModal(false)}
            appearance="secondary"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={isReportCreateModalOpen}
        size="sm"
        onClose={() => setIsReportCreateModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Create Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="title">
              <Form.ControlLabel>Systems</Form.ControlLabel>
              <SelectPicker
                data={[
                  { label: "PosTrackReview", value: "PosTrackReview" },
                  { label: "SysCheck", value: "SysCheck" },
                  { label: "ITloop1", value: "ITloop1" },
                  { label: "ITloop2", value: "ITloop2" },
                  { label: "ITloop3", value: "ITloop3" },
                  { label: "ITloop4", value: "ITloop4" },
                ]}
                searchable={false}
                label="Systems"
                style={{ width: 224 }}
                placeholder="Select"
              />
            </Form.Group>
            <Form.Group controlId="title">
              <Form.ControlLabel>Title</Form.ControlLabel>
              <Input placeholder="Title" name="title" block />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.ControlLabel>Description</Form.ControlLabel>
              <Input placeholder="Title" name="title" block as="textarea" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setIsReportCreateModalOpen(false)}
            appearance="primary"
          >
            Create
          </Button>
          <Button
            onClick={() => setIsReportCreateModalOpen(false)}
            appearance="secondary"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <PNLNoteModal
        noteModal={noteModal}
        setNoteModal={setNoteModal}
        handleAddNote={handleAddNote}
        setReportPortfolios={setReportPortfolios}
      />
    </Container>
  )
}

export default ReportingDashboard
