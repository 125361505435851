import React from "react"
import { Stack } from "rsuite"
import PortStatus from "../../Portfolios/components/PortStatus"

const PortfolioStatus = ({ portfolio }) => {
  return (
    <Stack spacing={3}>
      <Stack alignItems="center">
        {portfolio.slippageEndpoint && (
          <PortStatus status="slippage" showTooltip />
        )}
      </Stack>
      <Stack.Item style={{ display: "flex", alignItems: "center" }}>
        {portfolio.pnlEndpoint && (
          <PortStatus status="historical" showTooltip />
        )}
      </Stack.Item>
      <Stack.Item style={{ display: "flex", alignItems: "center" }}>
        {portfolio.positionsEndpoint && (
          <PortStatus status="dashboard" showTooltip />
        )}
      </Stack.Item>
    </Stack>
  )
}

export default PortfolioStatus
