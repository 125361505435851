import { useState } from "react"

const useUIControls = () => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [showProduction, setShowProduction] = useState(false)

  return {
    isSettingsOpen,
    setIsSettingsOpen,
    showInfo,
    setShowInfo,
    showProduction,
    setShowProduction,
  }
}
export default useUIControls
