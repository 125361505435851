import _ from "lodash"

// Helper function to update the form value
export const updateFormValue = (setFormValue, key, oldValue, newValue) => {
  setFormValue((fv) => ({
    ...fv,
    [key]: fv[key].replace(oldValue, newValue),
  }))
}

export const formatQuery = ({
  pathPrefix,
  accts = [],
  isBinance,
  isSlippage = false,
  query = {},
}) => {
  const { since, until, format, is_official } = query

  const ibAccounts = accts.map((acct) => {
    const accountNum = acct.accountNum
    if (isBinance) {
      return [
        { broker: "binance-spot", accountNum },
        { broker: "binance-futures", accountNum },
      ]
    } else if (isSlippage) {
      return [
        { broker: "interactivebrokers", accountNum },
        { broker: "-model_interactivebrokers", accountNum },
        { broker: "/interactivebrokers", accountNum },
      ]
    }
    return acct
  })

  const users = _.flatten(ibAccounts)
    .map(({ broker, accountNum }) => `${broker},${accountNum}`)
    .join("&user=")

  return [
    `${pathPrefix}?user=${users}`,
    since && `&since=${since}`,
    until && `&until=${until}`,
    format && `&format=${format}`,
    is_official && `&is_official=${is_official}`,
  ]
    .filter(Boolean)
    .join("")
}
