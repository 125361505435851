import React from "react"
import { Button, Heading, Modal, Stack } from "rsuite"
import InfoOutlineIcon from "@rsuite/icons/InfoOutline"
import { useTheme } from "@aws-amplify/ui-react"

const ConfirmDialog = ({
  isOpen,
  headerText,
  size = "xs",
  children,
  onClose,
  onConfirm,
  type = "info",
  isLoading,
}) => {
  const { tokens } = useTheme()

  const types = {
    info: tokens.colors.blue[60],
    danger: tokens.colors.red[60],
    warning: tokens.colors.orange[60],
    sucess: tokens.colors.green[60],
  }

  return (
    <>
      <Modal
        backdrop="static"
        role="alertdialog"
        open={isOpen}
        onClose={onClose}
        size={size}
      >
        <Modal.Body>
          <Heading level={5}>{headerText}</Heading>
          <Stack spacing={10} justifyContent="center">
            {type !== "none" && (
              <InfoOutlineIcon style={{ color: types[type], fontSize: 24 }} />
            )}
            {children}
          </Stack>
        </Modal.Body>
        <Modal.Footer style={{ padding: "0 20px" }}>
          <Button
            onClick={onConfirm}
            appearance="primary"
            loading={isLoading}
            color={type === "danger" ? "red" : "blue"}
          >
            Confirm
          </Button>
          <Button onClick={onClose} appearance="default">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConfirmDialog
