import React, { useContext, useEffect } from "react"
import useLocalControls from "./useLocalControls"
import useUIControls from "./useUIControls"
import useDateControls from "./useDateControls"
import useDashboardControls from "./useDashboardControls"

const ControlContext = React.createContext()
const { Provider, Consumer } = ControlContext

const ControlProvider = ({ children }) => {
  const localControls = useLocalControls()
  const uiControls = useUIControls()
  const dateControls = useDateControls()
  const dashboardControls = useDashboardControls()
  return (
    <Provider
      value={{
        ...localControls,
        ...dashboardControls,
        ...uiControls,
        ...dateControls,
      }}
    >
      {children}
    </Provider>
  )
}

export { ControlContext, Consumer as ControlConsumer, ControlProvider }

export const useControls = () => useContext(ControlContext)

export const useCloseSidebar = () => {
  const { setSideBarCollapse } = useControls()

  useEffect(() => {
    setSideBarCollapse(false)
  }, [setSideBarCollapse])
}
