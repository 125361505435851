import { Navigate, Outlet } from "react-router-dom"
import HistoricalPNLPage from "./elements/HistoricalPNL/HistoricalPNLPage"
import Slippage from "./elements/Slippage/Slippage"
import PortfolioContentLayout from "./layout/PortfolioContentLayout"
import TradeServerPage from "./elements/TradeServer/TradeServerPage"
import TradeServerPnlPage from "./elements/TradeServer/TradeServerPnL/TradeServerPnLPage"

const portfolios_route = {
  path: ":portfolioSlug",
  id: "portfolio",
  element: <PortfolioContentLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="positions" />,
    },
    {
      path: "historical",
      element: <HistoricalPNLPage />,
    },
    {
      path: "slippage",
      element: <Slippage />,
    },
    {
      path: "tradeserver",
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <TradeServerPage />,
        },
        {
          path: "tradeserverpnl",
          element: <TradeServerPnlPage />,
        },
      ],
    },
  ],
}

export default portfolios_route
