import React, { useState } from "react"
import {
  Row,
  Col,
  Heading,
  IconButton,
  Stack,
  Text,
  InputGroup,
  Input,
  Container,
  Header,
  Content,
  TagPicker,
  Popover,
  Whisper,
  Loader,
} from "rsuite"
import FunnelIcon from "@rsuite/icons/Funnel"
import AddOutlineIcon from "@rsuite/icons/AddOutline"
import CloseOutlineIcon from "@rsuite/icons/CloseOutline"
import SettingIcon from "@rsuite/icons/Setting"
import SearchIcon from "@rsuite/icons/Search"
import ManagersList from "./components/ManagerList"
import PortfolioList from "./components/PortfolioList"
import { useAllManagersAdmin } from "./lib/useManager"
import { useFilterManagerLists } from "../../hooks/useFilterManagerLists"
import { useFilterPortfolios } from "../../hooks/useFilterPortfolios"
import CreateNewPM from "../Portfolios/Settings/controls/CreateNewPM"
import ManagerEditModal from "./components/ManagerEditModal"
import useAuth from "../../components/AmplifyAuth/useAuth"
import styles from "./components/Managers.module.css"
import StatusFilters from "./components/StatusFilters"
import CardContainer from "../../components/common/CardContainer"

const ManagersAdmin = () => {
  const { data, isLoading } = useAllManagersAdmin()

  const { userMeta } = useAuth()

  const { ports, managers } = data || {}

  const [search, setSearch] = useState("")
  const [searchPortfolio, setSearchPortfolio] = useState("")
  const [isAdminSearchOpen, setIsAdminSearchOpen] = useState(false)
  const [isPortSearchOpen, setIsPortSearchOpen] = useState(false)
  const [isAddManagerOpen, setIsAddManagerOpen] = useState(false)
  const [isManagerSettingsOpen, setIsManagerSettingsOpen] = useState(false)
  const [modalSelectedManager, setModalSelectedManager] = useState(null)
  const [isModalSortingEnabled, setIsModalSortingEnabled] = useState(false)

  const { filteredManagers } = useFilterManagerLists(search, managers)

  const [filterByIconArray, setFilterByIconArray] = useState([])
  const [filterByManagerArray, setFilterByManagerArray] = useState([])

  const { filteredPortfolios } = useFilterPortfolios(
    ports?.managers,
    searchPortfolio,
    filterByIconArray,
    filterByManagerArray
  )

  const handleShowInput = (type, listType = null) => {
    switch (type) {
      case "search":
        setIsAddManagerOpen(false)
        setIsAdminSearchOpen(true)
        break

      case "add":
        setIsAddManagerOpen(true)
        setIsAdminSearchOpen(false)
        break

      default:
        break
    }
  }

  const onCloseAllInputs = () => {
    setIsAddManagerOpen(false)
    setIsAdminSearchOpen(false)
  }

  return (
    <Container className={styles.managerContainer}>
      <Header>
        <Row style={{ paddingTop: 0 }}>
          <Col xs={24}>
            <Stack
              alignItems="center"
              justifyContent="space-between"
              style={{ padding: "0.5rem" }}
            >
              <Stack.Item>
                <Heading level={4}>Dashboard</Heading>
                <Text size="sm" style={{ color: "#828387" }}>
                  Manage managers and portfolios
                </Text>
              </Stack.Item>
              <Stack alignItems="flex-end" direction="column">
                <Text size="md">{userMeta?.email}</Text>
                <Text size="sm" style={{ color: "#828387" }}>
                  {userMeta?.groups.join(", ")}
                </Text>
              </Stack>
            </Stack>
          </Col>
        </Row>
      </Header>
      {isLoading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          style={{ padding: "30px" }}
        >
          <Loader content="Loading Portfolios..." vertical size="md" />
        </Stack>
      ) : (
        <Content>
          <Row style={{ margin: "10px 0" }} gutter={20}>
            <Col xs={24} md={24} lg={8} xl={8}>
              <Stack
                alignItems="center"
                justifyContent="space-between"
                spacing={5}
                style={{ margin: "20px 0" }}
              >
                <div>
                  <Heading level={6} style={{ color: "orange" }}>
                    Managers
                  </Heading>
                  <Text size="sm" style={{ color: "#828387" }}>
                    List of all managers
                  </Text>
                </div>
                <Stack spacing={5}>
                  {!isAddManagerOpen && !isAdminSearchOpen && (
                    <>
                      <IconButton
                        circle
                        icon={<SettingIcon />}
                        appearance="default"
                        onClick={() =>
                          setIsManagerSettingsOpen((prevState) => !prevState)
                        }
                      />
                      <IconButton
                        circle
                        icon={<AddOutlineIcon />}
                        appearance="default"
                        onClick={() => handleShowInput("add")}
                      />
                      <IconButton
                        circle
                        icon={<SearchIcon />}
                        appearance="default"
                        onClick={() => handleShowInput("search")}
                      />
                    </>
                  )}
                  {isAddManagerOpen && (
                    <>
                      <Stack.Item grow={1}>
                        <CreateNewPM />
                      </Stack.Item>
                      <Stack.Item>
                        <IconButton
                          circle
                          icon={<CloseOutlineIcon />}
                          appearance="default"
                          onClick={() => onCloseAllInputs("add")}
                        />
                      </Stack.Item>
                    </>
                  )}
                  {isAdminSearchOpen && (
                    <>
                      <InputGroup inside>
                        <Input
                          onChange={(e) => setSearch(e)}
                          value={search}
                          placeholder="Search"
                        />
                        <InputGroup.Button>
                          <SearchIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <IconButton
                        circle
                        icon={<CloseOutlineIcon />}
                        appearance="default"
                        onClick={() => onCloseAllInputs("add")}
                      />
                    </>
                  )}
                </Stack>
              </Stack>

              <ManagersList
                isSortingEnabled={false}
                key={filteredManagers?.length}
                managers={filteredManagers}
              />
            </Col>
            <Col xs={24} md={24} lg={16} xl={16}>
              <Stack
                justifyContent="space-between"
                style={{ margin: "20px 0" }}
              >
                <Stack spacing={20}>
                  <div>
                    <Heading level={6} style={{ color: "orange" }}>
                      Portfolios
                    </Heading>
                    <Text size="sm" style={{ color: "#828387" }}>
                      List of all portfolios
                    </Text>
                  </div>
                </Stack>

                <Stack spacing={5}>
                  {isPortSearchOpen ? (
                    <>
                      <InputGroup inside>
                        <Input
                          onChange={setSearchPortfolio}
                          value={searchPortfolio}
                          placeholder="Search"
                        />
                        <InputGroup.Button>
                          <SearchIcon />
                        </InputGroup.Button>
                      </InputGroup>
                      <IconButton
                        circle
                        icon={<CloseOutlineIcon />}
                        appearance="default"
                        onClick={() =>
                          setIsPortSearchOpen((prevState) => !prevState)
                        }
                      />
                    </>
                  ) : (
                    !isPortSearchOpen && (
                      <>
                        <div className={styles.statusFilterFunnel}>
                          <Whisper
                            trigger="click"
                            placement="bottomEnd"
                            speaker={
                              <Popover>
                                <StatusFilters
                                  filterByIconArray={filterByIconArray}
                                  setFilterByIconArray={setFilterByIconArray}
                                />
                              </Popover>
                            }
                          >
                            <IconButton
                              circle
                              icon={<FunnelIcon />}
                              appearance="default"
                            />
                          </Whisper>
                        </div>
                        <IconButton
                          circle
                          icon={<SearchIcon />}
                          appearance="default"
                          onClick={() =>
                            setIsPortSearchOpen((prevState) => !prevState)
                          }
                        />
                      </>
                    )
                  )}
                </Stack>
              </Stack>

              <CardContainer
                className={styles.statusFilter}
                style={{ marginBottom: "10px" }}
              >
                <Stack justifyContent="space-between">
                  <StatusFilters
                    filterByIconArray={filterByIconArray}
                    setFilterByIconArray={setFilterByIconArray}
                  />
                  <Stack.Item style={{ minWidth: "200px" }}>
                    <TagPicker
                      onChange={setFilterByManagerArray}
                      value={filterByManagerArray}
                      data={
                        managers
                          ?.sort((a, b) => a.sortOrder - b.sortOrder)
                          .map((manager) => ({
                            label: manager.displayName,
                            value: manager.slug,
                          })) || []
                      }
                      block
                    />
                  </Stack.Item>
                </Stack>
              </CardContainer>

              <PortfolioList
                portfolios={filteredPortfolios}
                key={filteredPortfolios?.length}
              />
            </Col>
          </Row>
        </Content>
      )}

      <ManagerEditModal
        isManagerSettingsOpen={isManagerSettingsOpen}
        setIsManagerSettingsOpen={setIsManagerSettingsOpen}
        setIsModalSortingEnabled={setIsModalSortingEnabled}
        isModalSortingEnabled={isModalSortingEnabled}
        filteredManagers={filteredManagers}
        setModalSelectedManager={setModalSelectedManager}
        modalSelectedManager={modalSelectedManager}
      />
    </Container>
  )
}

export default ManagersAdmin
