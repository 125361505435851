import { useEffect, useState } from "react"
import { useDebounce } from "./useDebounce"

export const useFilterPortfolios = (
  portfolios,
  search,
  status = [],
  managers = []
) => {
  const [filteredPortfolios, setFilteredPortfolios] = useState(portfolios || [])
  const debounceSearch = useDebounce(search)

  useEffect(() => {
    setFilteredPortfolios((prevState) => {
      const types = status.filter((stat) => stat.type === "type")
      const stats = status.filter((stat) => stat.type === "status")
      const endpoints = status.filter((stat) => stat.type === "endpoint")

      if (
        debounceSearch === "" &&
        status.length === 0 &&
        managers.length === 0
      ) {
        return portfolios
      }
      prevState = portfolios
        .filter((pS) =>
          pS.name.toLowerCase().includes(debounceSearch.toLowerCase())
        )
        .filter((pS) => {
          if (managers.length === 0 || managers.includes(pS.portfolioManager))
            return true
          return false
        })
        .filter((pS) => {
          if (types.length === 0) return true
          const type = types.find((s) => s.name === pS.type)
          if (type) {
            return true
          }
          return false
        })
        .filter((pS) => {
          if (stats.length === 0) return true
          const type = stats.find((s) => s.name === pS.status)
          if (type) {
            return true
          }
          return false
        })
        .filter((pS) => {
          if (endpoints.length === 0) return true
          const s = endpoints.find((s) => s.name === "slippage")
          const d = endpoints.find((s) => s.name === "dashboard")
          const h = endpoints.find((s) => s.name === "historical")
          const p = endpoints.find((s) => s.name === "auto_reconciliation")
          if (
            (s && pS.slippageEndpoint) ||
            (d && pS.positionsEndpoint) ||
            (h && pS.pnlEndpoint) ||
            (p && (pS.posTrack || pS.autoRecon))
          ) {
            return true
          }
          return false
        })

      return [...prevState]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch, portfolios?.length, status.length, managers?.length])

  return { filteredPortfolios }
}
