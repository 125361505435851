/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getInstrumentPnL = /* GraphQL */ `
  query GetInstrumentPnL($input: InstrumentPnLInput) {
    getInstrumentPnL(input: $input) {
      items {
        period
        pnlUsd
        instrumentPnlUsd
        userID
        tradeInstrumentId
        quantity
        expiry
        ticker
        currency
        exchange
        isActive
        multiplier
        instrumentType
        tradingExchange
        __typename
      }
      __typename
    }
  }
`;
export const getLatestPerInstrument = /* GraphQL */ `
  query GetLatestPerInstrument($input: LatestPerInstrumentInput) {
    getLatestPerInstrument(input: $input) {
      items {
        period
        userId
        instrumentTradeId
        instrumentPriceId
        startPriceUsd
        endPriceUsd
        startPriceRaw
        endPriceRaw
        absolutePnl
        startQuantity
        endQuantity
        priceChange
        priceDiff
        percentagePnl
        __typename
      }
      instruments {
        id
        expiry
        ticker
        currency
        exchange
        isActive
        multiplier
        tradableId
        priceSource
        conversionTo
        priceProvider
        conversionFrom
        instrumentType
        tradingExchange
        __typename
      }
      __typename
    }
  }
`;
export const metagoraPortfolioData = /* GraphQL */ `
  query MetagoraPortfolioData($input: MetagoraPortfolioInput) {
    metagoraPortfolioData(input: $input) {
      id
      name
      displayName
      slug
      portfolioManager
      description
      account {
        broker
        accountNum
        accountName
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      status
      type
      autoReconTimestamp
      autoRecon {
        instrumentID
        name
        modelPosition
        modelChanges
        cumModelContractsTraded
        cumModelContractsTradedRounded
        accountPosition
        positionChanges
        cumAccountContractsTraded
        differenceChanges
        positionDifference
        cumContractsTradedDiff
        __typename
      }
      lastSync
      pnlEndpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
          trade_level
          deposit
          __typename
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
          __typename
        }
        __typename
      }
      slippageEndpoint
      slippage {
        data {
          period
          daily_slippage_pct
          cumulative_slippage_pct
          daily_commission_pct
          cumulative_commission_pct
          ann_slippage_3_month_avg_pct
          ann_commission_3_month_avg_pct
          __typename
        }
        summary {
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
          __typename
        }
        formats
        __typename
      }
      positionsEndpoint
      positions {
        data {
          instrument_id
          account
          exchange
          symbol_root
          expiry
          currency
          multiplier
          instrument_type
          quantity
          price
          period
          initial_margin
          maintenance_margin
          positions_pnl_pct
          margin_per_contract
          position_value
          maintenance_margin_pv
          absolute_pnl
          relative_pnl
          price_change
          price_diff
          maintenance_margin_relative
          __typename
        }
        formats
        __typename
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      summaryType
      pnlType
      leverage
      leverageToDisplay
      minDollarPositionValueToShow
      cashLevel
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      posTrack {
        positions {
          name
          accountPosition
          modelPosition
          status
          instrumentId
          __typename
        }
        alertStatus
        isAlertVisible
        accountLastValid
        accountStatus
        modelLastValid
        modelStatus
        leverage
        __typename
      }
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
        __typename
      }
      milestones {
        title
        date
        color
        __typename
      }
      excludeFromOverallTotals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const metagoraPortfolioPnLData = /* GraphQL */ `
  query MetagoraPortfolioPnLData($input: PortfolioPnlInput) {
    metagoraPortfolioPnLData(input: $input) {
      data {
        cash
        commisions
        commisionsCumulative
        commissions
        commissionsCumulative
        date
        deposit
        futuresValue
        impliedEquity
        leverage
        leverageCash
        marginInitial
        marginMaintenance
        marginMaintenanceRelative
        official
        pctDrawdown
        pctPnl
        pctPnlCumulative
        portfolioValue
        rawPnl
        rawPnlCumulative
        realizedLeverage
        returnCash
        returnCashCumulative
        stocksValue
        unrealizedFuturesPnl
        __typename
      }
      summary {
        cumReturnPct
        avgCalendarDayReturnPct
        avgDayReturnPct
        __typename
      }
      __typename
    }
  }
`;
export const metagoraPortfolioPositionData = /* GraphQL */ `
  query MetagoraPortfolioPositionData($input: MetagoraPortfolioInput) {
    metagoraPortfolioPositionData(input: $input) {
      id
      name
      displayName
      slug
      portfolioManager
      description
      account {
        broker
        accountNum
        accountName
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      status
      type
      autoReconTimestamp
      autoRecon {
        instrumentID
        name
        modelPosition
        modelChanges
        cumModelContractsTraded
        cumModelContractsTradedRounded
        accountPosition
        positionChanges
        cumAccountContractsTraded
        differenceChanges
        positionDifference
        cumContractsTradedDiff
        __typename
      }
      lastSync
      pnlEndpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
          trade_level
          deposit
          __typename
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
          __typename
        }
        __typename
      }
      slippageEndpoint
      slippage {
        data {
          period
          daily_slippage_pct
          cumulative_slippage_pct
          daily_commission_pct
          cumulative_commission_pct
          ann_slippage_3_month_avg_pct
          ann_commission_3_month_avg_pct
          __typename
        }
        summary {
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
          __typename
        }
        formats
        __typename
      }
      positionsEndpoint
      positions {
        data {
          instrument_id
          account
          exchange
          symbol_root
          expiry
          currency
          multiplier
          instrument_type
          quantity
          price
          period
          initial_margin
          maintenance_margin
          positions_pnl_pct
          margin_per_contract
          position_value
          maintenance_margin_pv
          absolute_pnl
          relative_pnl
          price_change
          price_diff
          maintenance_margin_relative
          __typename
        }
        formats
        __typename
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      summaryType
      pnlType
      leverage
      leverageToDisplay
      minDollarPositionValueToShow
      cashLevel
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      posTrack {
        positions {
          name
          accountPosition
          modelPosition
          status
          instrumentId
          __typename
        }
        alertStatus
        isAlertVisible
        accountLastValid
        accountStatus
        modelLastValid
        modelStatus
        leverage
        __typename
      }
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
        __typename
      }
      milestones {
        title
        date
        color
        __typename
      }
      excludeFromOverallTotals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const metagoraPortfolioSlippageData = /* GraphQL */ `
  query MetagoraPortfolioSlippageData($input: MetagoraPortfolioInput) {
    metagoraPortfolioSlippageData(input: $input) {
      id
      name
      displayName
      slug
      portfolioManager
      description
      account {
        broker
        accountNum
        accountName
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      status
      type
      autoReconTimestamp
      autoRecon {
        instrumentID
        name
        modelPosition
        modelChanges
        cumModelContractsTraded
        cumModelContractsTradedRounded
        accountPosition
        positionChanges
        cumAccountContractsTraded
        differenceChanges
        positionDifference
        cumContractsTradedDiff
        __typename
      }
      lastSync
      pnlEndpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
          trade_level
          deposit
          __typename
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
          __typename
        }
        __typename
      }
      slippageEndpoint
      slippage {
        data {
          period
          daily_slippage_pct
          cumulative_slippage_pct
          daily_commission_pct
          cumulative_commission_pct
          ann_slippage_3_month_avg_pct
          ann_commission_3_month_avg_pct
          __typename
        }
        summary {
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
          __typename
        }
        formats
        __typename
      }
      positionsEndpoint
      positions {
        data {
          instrument_id
          account
          exchange
          symbol_root
          expiry
          currency
          multiplier
          instrument_type
          quantity
          price
          period
          initial_margin
          maintenance_margin
          positions_pnl_pct
          margin_per_contract
          position_value
          maintenance_margin_pv
          absolute_pnl
          relative_pnl
          price_change
          price_diff
          maintenance_margin_relative
          __typename
        }
        formats
        __typename
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      summaryType
      pnlType
      leverage
      leverageToDisplay
      minDollarPositionValueToShow
      cashLevel
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      posTrack {
        positions {
          name
          accountPosition
          modelPosition
          status
          instrumentId
          __typename
        }
        alertStatus
        isAlertVisible
        accountLastValid
        accountStatus
        modelLastValid
        modelStatus
        leverage
        __typename
      }
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
        __typename
      }
      milestones {
        title
        date
        color
        __typename
      }
      excludeFromOverallTotals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const metagoraDashboardData = /* GraphQL */ `
  query MetagoraDashboardData(
    $input: MetagoraDashboardInput
    $filter: [String]
  ) {
    metagoraDashboardData(input: $input, filter: $filter) {
      managers {
        name
        displayName
        sortOrder
        slug
        portfolios {
          name
          displayName
          slug
          account {
            broker
            accountNum
            accountName
            __typename
          }
          portfolioMeta {
            cashLevel
            impliedEquity
            leverageSetting
            leverage
            leverageToDisplay
            minDollarPositionValueToShow
            dailyReturnThreshold
            __typename
          }
          portfolioManager
          lastSync
          metaData {
            lastPNLDate
            todaysPNL
            todaysPNLValue
            yesterdaysPNLValue
            monthToDate
            quarterToDate
            yearToDate
            cumulativeReturnCash
            exposure
            todays_drawdown
            cashLevel
            leverageSetting
            currentLeverage
            marginMaintenance
            marginMaintenancePortfolioValue
            sharpeRatio
            highWater
            total_cumulative_return_pct
            avg_daily_return_pct
            avg_calendar_daily_return_pct
            total_cumulative_slippage_pct
            avg_daily_slippage_pct
            avg_calendar_daily_slippage_pct
            total_number_of_pnl_days
            total_number_of_slippage_days
            total_number_of_positions
            total_number_of_commodities
            total_number_of_unofficial_days
            __typename
          }
          cashLevel
          leverageSetting
          type
          status
          health {
            position_differences
            traded_differences
            unofficial_numbers
            daily_return_warning
            max_position_by_portfolio
            max_position_by_position
            __typename
          }
          __typename
        }
        totals {
          managerTotalProduction
          managerTotalExposure
          __typename
        }
        __typename
      }
      totals {
        overallManagerTotalProduction
        overallManagerTotalExposure
        __typename
      }
      __typename
    }
  }
`;
export const mergedPortfolioChartData = /* GraphQL */ `
  query MergedPortfolioChartData(
    $portSlug: String!
    $managerSlug: String!
    $range: String
    $benchmarkSlug: String
    $benchmarkManagerSlug: String
  ) {
    mergedPortfolioChartData(
      portSlug: $portSlug
      managerSlug: $managerSlug
      range: $range
      benchmarkSlug: $benchmarkSlug
      benchmarkManagerSlug: $benchmarkManagerSlug
    ) {
      id
      name
      displayName
      benchmarkDisplayName
      benchmarkSlug
      summaryType
      dateOptions
      selectedRange
      slug
      status
      portfolioManager
      lastSync
      chartData {
        period
        official
        portfolio_value
        daily_return
        cumulative_return
        daily_return_pct
        benchmark_cumulative_return_pct
        benchmark_daily_return_pct
        cumulative_return_pct
        leverage
        leverage_cash
        realized_leverage
        return_cash
        cumulative_return_cash
        margin_initial
        margin_maintenance
        pct_drawdown
        trade_level
        deposit
        drawdown_days
        time_to_recovery
        rebased_benchmark_return
        rebased_return
        rebased
        rebased_lev
        abs_return
        meta
        __typename
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      endpoint
      milestones {
        title
        date
        color
        __typename
      }
      __typename
    }
  }
`;
export const getPortfolio = /* GraphQL */ `
  query GetPortfolio($id: ID!) {
    getPortfolio(id: $id) {
      id
      name
      displayName
      slug
      portfolioManager
      description
      account {
        broker
        accountNum
        accountName
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      status
      type
      autoReconTimestamp
      autoRecon {
        instrumentID
        name
        modelPosition
        modelChanges
        cumModelContractsTraded
        cumModelContractsTradedRounded
        accountPosition
        positionChanges
        cumAccountContractsTraded
        differenceChanges
        positionDifference
        cumContractsTradedDiff
        __typename
      }
      lastSync
      pnlEndpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
          trade_level
          deposit
          __typename
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
          __typename
        }
        __typename
      }
      slippageEndpoint
      slippage {
        data {
          period
          daily_slippage_pct
          cumulative_slippage_pct
          daily_commission_pct
          cumulative_commission_pct
          ann_slippage_3_month_avg_pct
          ann_commission_3_month_avg_pct
          __typename
        }
        summary {
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
          __typename
        }
        formats
        __typename
      }
      positionsEndpoint
      positions {
        data {
          instrument_id
          account
          exchange
          symbol_root
          expiry
          currency
          multiplier
          instrument_type
          quantity
          price
          period
          initial_margin
          maintenance_margin
          positions_pnl_pct
          margin_per_contract
          position_value
          maintenance_margin_pv
          absolute_pnl
          relative_pnl
          price_change
          price_diff
          maintenance_margin_relative
          __typename
        }
        formats
        __typename
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      summaryType
      pnlType
      leverage
      leverageToDisplay
      minDollarPositionValueToShow
      cashLevel
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      posTrack {
        positions {
          name
          accountPosition
          modelPosition
          status
          instrumentId
          __typename
        }
        alertStatus
        isAlertVisible
        accountLastValid
        accountStatus
        modelLastValid
        modelStatus
        leverage
        __typename
      }
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
        __typename
      }
      milestones {
        title
        date
        color
        __typename
      }
      excludeFromOverallTotals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPortfolios = /* GraphQL */ `
  query ListPortfolios(
    $filter: ModelPortfolioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortfolios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        displayName
        slug
        portfolioManager
        description
        account {
          broker
          accountNum
          accountName
          __typename
        }
        portfolioMeta {
          cashLevel
          impliedEquity
          leverageSetting
          leverage
          leverageToDisplay
          minDollarPositionValueToShow
          dailyReturnThreshold
          __typename
        }
        status
        type
        autoReconTimestamp
        autoRecon {
          instrumentID
          name
          modelPosition
          modelChanges
          cumModelContractsTraded
          cumModelContractsTradedRounded
          accountPosition
          positionChanges
          cumAccountContractsTraded
          differenceChanges
          positionDifference
          cumContractsTradedDiff
          __typename
        }
        lastSync
        pnlEndpoint
        historicalPnl {
          data {
            period
            official
            portfolio_value
            daily_return
            cumulative_return
            daily_return_pct
            cumulative_return_pct
            leverage
            leverage_cash
            realized_leverage
            return_cash
            cumulative_return_cash
            margin_initial
            margin_maintenance
            pct_drawdown
            trade_level
            deposit
            __typename
          }
          formats
          summary {
            total_cumulative_return_pct
            avg_daily_return_pct
            avg_calendar_daily_return_pct
            __typename
          }
          __typename
        }
        slippageEndpoint
        slippage {
          data {
            period
            daily_slippage_pct
            cumulative_slippage_pct
            daily_commission_pct
            cumulative_commission_pct
            ann_slippage_3_month_avg_pct
            ann_commission_3_month_avg_pct
            __typename
          }
          summary {
            total_cumulative_slippage_pct
            avg_daily_slippage_pct
            avg_calendar_daily_slippage_pct
            __typename
          }
          formats
          __typename
        }
        positionsEndpoint
        positions {
          data {
            instrument_id
            account
            exchange
            symbol_root
            expiry
            currency
            multiplier
            instrument_type
            quantity
            price
            period
            initial_margin
            maintenance_margin
            positions_pnl_pct
            margin_per_contract
            position_value
            maintenance_margin_pv
            absolute_pnl
            relative_pnl
            price_change
            price_diff
            maintenance_margin_relative
            __typename
          }
          formats
          __typename
        }
        metaData {
          lastPNLDate
          todaysPNL
          todaysPNLValue
          yesterdaysPNLValue
          monthToDate
          quarterToDate
          yearToDate
          cumulativeReturnCash
          exposure
          todays_drawdown
          cashLevel
          leverageSetting
          currentLeverage
          marginMaintenance
          marginMaintenancePortfolioValue
          sharpeRatio
          highWater
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
          total_number_of_pnl_days
          total_number_of_slippage_days
          total_number_of_positions
          total_number_of_commodities
          total_number_of_unofficial_days
          __typename
        }
        summaryType
        pnlType
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        cashLevel
        leverageSetting
        dailyReturnThreshold
        maxMarginPerPosition
        maxMarginPerPortfolio
        posTrack {
          positions {
            name
            accountPosition
            modelPosition
            status
            instrumentId
            __typename
          }
          alertStatus
          isAlertVisible
          accountLastValid
          accountStatus
          modelLastValid
          modelStatus
          leverage
          __typename
        }
        health {
          position_differences
          traded_differences
          unofficial_numbers
          daily_return_warning
          max_position_by_portfolio
          max_position_by_position
          __typename
        }
        milestones {
          title
          date
          color
          __typename
        }
        excludeFromOverallTotals
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const portfolioBySlug = /* GraphQL */ `
  query PortfolioBySlug(
    $slug: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPortfolioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    portfolioBySlug(
      slug: $slug
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        displayName
        slug
        portfolioManager
        description
        account {
          broker
          accountNum
          accountName
          __typename
        }
        portfolioMeta {
          cashLevel
          impliedEquity
          leverageSetting
          leverage
          leverageToDisplay
          minDollarPositionValueToShow
          dailyReturnThreshold
          __typename
        }
        status
        type
        autoReconTimestamp
        autoRecon {
          instrumentID
          name
          modelPosition
          modelChanges
          cumModelContractsTraded
          cumModelContractsTradedRounded
          accountPosition
          positionChanges
          cumAccountContractsTraded
          differenceChanges
          positionDifference
          cumContractsTradedDiff
          __typename
        }
        lastSync
        pnlEndpoint
        historicalPnl {
          data {
            period
            official
            portfolio_value
            daily_return
            cumulative_return
            daily_return_pct
            cumulative_return_pct
            leverage
            leverage_cash
            realized_leverage
            return_cash
            cumulative_return_cash
            margin_initial
            margin_maintenance
            pct_drawdown
            trade_level
            deposit
            __typename
          }
          formats
          summary {
            total_cumulative_return_pct
            avg_daily_return_pct
            avg_calendar_daily_return_pct
            __typename
          }
          __typename
        }
        slippageEndpoint
        slippage {
          data {
            period
            daily_slippage_pct
            cumulative_slippage_pct
            daily_commission_pct
            cumulative_commission_pct
            ann_slippage_3_month_avg_pct
            ann_commission_3_month_avg_pct
            __typename
          }
          summary {
            total_cumulative_slippage_pct
            avg_daily_slippage_pct
            avg_calendar_daily_slippage_pct
            __typename
          }
          formats
          __typename
        }
        positionsEndpoint
        positions {
          data {
            instrument_id
            account
            exchange
            symbol_root
            expiry
            currency
            multiplier
            instrument_type
            quantity
            price
            period
            initial_margin
            maintenance_margin
            positions_pnl_pct
            margin_per_contract
            position_value
            maintenance_margin_pv
            absolute_pnl
            relative_pnl
            price_change
            price_diff
            maintenance_margin_relative
            __typename
          }
          formats
          __typename
        }
        metaData {
          lastPNLDate
          todaysPNL
          todaysPNLValue
          yesterdaysPNLValue
          monthToDate
          quarterToDate
          yearToDate
          cumulativeReturnCash
          exposure
          todays_drawdown
          cashLevel
          leverageSetting
          currentLeverage
          marginMaintenance
          marginMaintenancePortfolioValue
          sharpeRatio
          highWater
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
          total_number_of_pnl_days
          total_number_of_slippage_days
          total_number_of_positions
          total_number_of_commodities
          total_number_of_unofficial_days
          __typename
        }
        summaryType
        pnlType
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        cashLevel
        leverageSetting
        dailyReturnThreshold
        maxMarginPerPosition
        maxMarginPerPortfolio
        posTrack {
          positions {
            name
            accountPosition
            modelPosition
            status
            instrumentId
            __typename
          }
          alertStatus
          isAlertVisible
          accountLastValid
          accountStatus
          modelLastValid
          modelStatus
          leverage
          __typename
        }
        health {
          position_differences
          traded_differences
          unofficial_numbers
          daily_return_warning
          max_position_by_portfolio
          max_position_by_position
          __typename
        }
        milestones {
          title
          date
          color
          __typename
        }
        excludeFromOverallTotals
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPortfolioManager = /* GraphQL */ `
  query GetPortfolioManager($id: ID!) {
    getPortfolioManager(id: $id) {
      name
      displayName
      sortOrder
      color
      displayMarketView
      slug
      portfolios {
        items {
          id
          name
          displayName
          slug
          portfolioManager
          description
          account {
            broker
            accountNum
            accountName
            __typename
          }
          portfolioMeta {
            cashLevel
            impliedEquity
            leverageSetting
            leverage
            leverageToDisplay
            minDollarPositionValueToShow
            dailyReturnThreshold
            __typename
          }
          status
          type
          autoReconTimestamp
          autoRecon {
            instrumentID
            name
            modelPosition
            modelChanges
            cumModelContractsTraded
            cumModelContractsTradedRounded
            accountPosition
            positionChanges
            cumAccountContractsTraded
            differenceChanges
            positionDifference
            cumContractsTradedDiff
            __typename
          }
          lastSync
          pnlEndpoint
          historicalPnl {
            data {
              period
              official
              portfolio_value
              daily_return
              cumulative_return
              daily_return_pct
              cumulative_return_pct
              leverage
              leverage_cash
              realized_leverage
              return_cash
              cumulative_return_cash
              margin_initial
              margin_maintenance
              pct_drawdown
              trade_level
              deposit
              __typename
            }
            formats
            summary {
              total_cumulative_return_pct
              avg_daily_return_pct
              avg_calendar_daily_return_pct
              __typename
            }
            __typename
          }
          slippageEndpoint
          slippage {
            data {
              period
              daily_slippage_pct
              cumulative_slippage_pct
              daily_commission_pct
              cumulative_commission_pct
              ann_slippage_3_month_avg_pct
              ann_commission_3_month_avg_pct
              __typename
            }
            summary {
              total_cumulative_slippage_pct
              avg_daily_slippage_pct
              avg_calendar_daily_slippage_pct
              __typename
            }
            formats
            __typename
          }
          positionsEndpoint
          positions {
            data {
              instrument_id
              account
              exchange
              symbol_root
              expiry
              currency
              multiplier
              instrument_type
              quantity
              price
              period
              initial_margin
              maintenance_margin
              positions_pnl_pct
              margin_per_contract
              position_value
              maintenance_margin_pv
              absolute_pnl
              relative_pnl
              price_change
              price_diff
              maintenance_margin_relative
              __typename
            }
            formats
            __typename
          }
          metaData {
            lastPNLDate
            todaysPNL
            todaysPNLValue
            yesterdaysPNLValue
            monthToDate
            quarterToDate
            yearToDate
            cumulativeReturnCash
            exposure
            todays_drawdown
            cashLevel
            leverageSetting
            currentLeverage
            marginMaintenance
            marginMaintenancePortfolioValue
            sharpeRatio
            highWater
            total_cumulative_return_pct
            avg_daily_return_pct
            avg_calendar_daily_return_pct
            total_cumulative_slippage_pct
            avg_daily_slippage_pct
            avg_calendar_daily_slippage_pct
            total_number_of_pnl_days
            total_number_of_slippage_days
            total_number_of_positions
            total_number_of_commodities
            total_number_of_unofficial_days
            __typename
          }
          summaryType
          pnlType
          leverage
          leverageToDisplay
          minDollarPositionValueToShow
          cashLevel
          leverageSetting
          dailyReturnThreshold
          maxMarginPerPosition
          maxMarginPerPortfolio
          posTrack {
            positions {
              name
              accountPosition
              modelPosition
              status
              instrumentId
              __typename
            }
            alertStatus
            isAlertVisible
            accountLastValid
            accountStatus
            modelLastValid
            modelStatus
            leverage
            __typename
          }
          health {
            position_differences
            traded_differences
            unofficial_numbers
            daily_return_warning
            max_position_by_portfolio
            max_position_by_position
            __typename
          }
          milestones {
            title
            date
            color
            __typename
          }
          excludeFromOverallTotals
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPortfolioManagers = /* GraphQL */ `
  query ListPortfolioManagers(
    $filter: ModelPortfolioManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortfolioManagers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        displayName
        sortOrder
        color
        displayMarketView
        slug
        portfolios {
          items {
            id
            name
            displayName
            slug
            portfolioManager
            description
            account {
              broker
              accountNum
              accountName
              __typename
            }
            portfolioMeta {
              cashLevel
              impliedEquity
              leverageSetting
              leverage
              leverageToDisplay
              minDollarPositionValueToShow
              dailyReturnThreshold
              __typename
            }
            status
            type
            autoReconTimestamp
            autoRecon {
              instrumentID
              name
              modelPosition
              modelChanges
              cumModelContractsTraded
              cumModelContractsTradedRounded
              accountPosition
              positionChanges
              cumAccountContractsTraded
              differenceChanges
              positionDifference
              cumContractsTradedDiff
              __typename
            }
            lastSync
            pnlEndpoint
            historicalPnl {
              formats
              __typename
            }
            slippageEndpoint
            slippage {
              formats
              __typename
            }
            positionsEndpoint
            positions {
              formats
              __typename
            }
            metaData {
              lastPNLDate
              todaysPNL
              todaysPNLValue
              yesterdaysPNLValue
              monthToDate
              quarterToDate
              yearToDate
              cumulativeReturnCash
              exposure
              todays_drawdown
              cashLevel
              leverageSetting
              currentLeverage
              marginMaintenance
              marginMaintenancePortfolioValue
              sharpeRatio
              highWater
              total_cumulative_return_pct
              avg_daily_return_pct
              avg_calendar_daily_return_pct
              total_cumulative_slippage_pct
              avg_daily_slippage_pct
              avg_calendar_daily_slippage_pct
              total_number_of_pnl_days
              total_number_of_slippage_days
              total_number_of_positions
              total_number_of_commodities
              total_number_of_unofficial_days
              __typename
            }
            summaryType
            pnlType
            leverage
            leverageToDisplay
            minDollarPositionValueToShow
            cashLevel
            leverageSetting
            dailyReturnThreshold
            maxMarginPerPosition
            maxMarginPerPortfolio
            posTrack {
              alertStatus
              isAlertVisible
              accountLastValid
              accountStatus
              modelLastValid
              modelStatus
              leverage
              __typename
            }
            health {
              position_differences
              traded_differences
              unofficial_numbers
              daily_return_warning
              max_position_by_portfolio
              max_position_by_position
              __typename
            }
            milestones {
              title
              date
              color
              __typename
            }
            excludeFromOverallTotals
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const managerBySlug = /* GraphQL */ `
  query ManagerBySlug(
    $slug: String!
    $name: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPortfolioManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    managerBySlug(
      slug: $slug
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        displayName
        sortOrder
        color
        displayMarketView
        slug
        portfolios {
          items {
            id
            name
            displayName
            slug
            portfolioManager
            description
            account {
              broker
              accountNum
              accountName
              __typename
            }
            portfolioMeta {
              cashLevel
              impliedEquity
              leverageSetting
              leverage
              leverageToDisplay
              minDollarPositionValueToShow
              dailyReturnThreshold
              __typename
            }
            status
            type
            autoReconTimestamp
            autoRecon {
              instrumentID
              name
              modelPosition
              modelChanges
              cumModelContractsTraded
              cumModelContractsTradedRounded
              accountPosition
              positionChanges
              cumAccountContractsTraded
              differenceChanges
              positionDifference
              cumContractsTradedDiff
              __typename
            }
            lastSync
            pnlEndpoint
            historicalPnl {
              formats
              __typename
            }
            slippageEndpoint
            slippage {
              formats
              __typename
            }
            positionsEndpoint
            positions {
              formats
              __typename
            }
            metaData {
              lastPNLDate
              todaysPNL
              todaysPNLValue
              yesterdaysPNLValue
              monthToDate
              quarterToDate
              yearToDate
              cumulativeReturnCash
              exposure
              todays_drawdown
              cashLevel
              leverageSetting
              currentLeverage
              marginMaintenance
              marginMaintenancePortfolioValue
              sharpeRatio
              highWater
              total_cumulative_return_pct
              avg_daily_return_pct
              avg_calendar_daily_return_pct
              total_cumulative_slippage_pct
              avg_daily_slippage_pct
              avg_calendar_daily_slippage_pct
              total_number_of_pnl_days
              total_number_of_slippage_days
              total_number_of_positions
              total_number_of_commodities
              total_number_of_unofficial_days
              __typename
            }
            summaryType
            pnlType
            leverage
            leverageToDisplay
            minDollarPositionValueToShow
            cashLevel
            leverageSetting
            dailyReturnThreshold
            maxMarginPerPosition
            maxMarginPerPortfolio
            posTrack {
              alertStatus
              isAlertVisible
              accountLastValid
              accountStatus
              modelLastValid
              modelStatus
              leverage
              __typename
            }
            health {
              position_differences
              traded_differences
              unofficial_numbers
              daily_return_warning
              max_position_by_portfolio
              max_position_by_position
              __typename
            }
            milestones {
              title
              date
              color
              __typename
            }
            excludeFromOverallTotals
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
