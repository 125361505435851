/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncPortfolio = /* GraphQL */ `
  mutation SyncPortfolio($portfolioId: ID!) {
    syncPortfolio(portfolioId: $portfolioId) {
      id
      name
      displayName
      slug
      portfolioManager
      description
      account {
        broker
        accountNum
        accountName
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      status
      type
      autoReconTimestamp
      autoRecon {
        instrumentID
        name
        modelPosition
        modelChanges
        cumModelContractsTraded
        cumModelContractsTradedRounded
        accountPosition
        positionChanges
        cumAccountContractsTraded
        differenceChanges
        positionDifference
        cumContractsTradedDiff
        __typename
      }
      lastSync
      pnlEndpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
          trade_level
          deposit
          __typename
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
          __typename
        }
        __typename
      }
      slippageEndpoint
      slippage {
        data {
          period
          daily_slippage_pct
          cumulative_slippage_pct
          daily_commission_pct
          cumulative_commission_pct
          ann_slippage_3_month_avg_pct
          ann_commission_3_month_avg_pct
          __typename
        }
        summary {
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
          __typename
        }
        formats
        __typename
      }
      positionsEndpoint
      positions {
        data {
          instrument_id
          account
          exchange
          symbol_root
          expiry
          currency
          multiplier
          instrument_type
          quantity
          price
          period
          initial_margin
          maintenance_margin
          positions_pnl_pct
          margin_per_contract
          position_value
          maintenance_margin_pv
          absolute_pnl
          relative_pnl
          price_change
          price_diff
          maintenance_margin_relative
          __typename
        }
        formats
        __typename
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      summaryType
      pnlType
      leverage
      leverageToDisplay
      minDollarPositionValueToShow
      cashLevel
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      posTrack {
        positions {
          name
          accountPosition
          modelPosition
          status
          instrumentId
          __typename
        }
        alertStatus
        isAlertVisible
        accountLastValid
        accountStatus
        modelLastValid
        modelStatus
        leverage
        __typename
      }
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
        __typename
      }
      milestones {
        title
        date
        color
        __typename
      }
      excludeFromOverallTotals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePortfolioInfo = /* GraphQL */ `
  mutation UpdatePortfolioInfo($input: UpdatePortfolioInfo) {
    updatePortfolioInfo(input: $input) {
      id
      name
      displayName
      slug
      portfolioManager
      description
      account {
        broker
        accountNum
        accountName
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      status
      type
      autoReconTimestamp
      autoRecon {
        instrumentID
        name
        modelPosition
        modelChanges
        cumModelContractsTraded
        cumModelContractsTradedRounded
        accountPosition
        positionChanges
        cumAccountContractsTraded
        differenceChanges
        positionDifference
        cumContractsTradedDiff
        __typename
      }
      lastSync
      pnlEndpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
          trade_level
          deposit
          __typename
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
          __typename
        }
        __typename
      }
      slippageEndpoint
      slippage {
        data {
          period
          daily_slippage_pct
          cumulative_slippage_pct
          daily_commission_pct
          cumulative_commission_pct
          ann_slippage_3_month_avg_pct
          ann_commission_3_month_avg_pct
          __typename
        }
        summary {
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
          __typename
        }
        formats
        __typename
      }
      positionsEndpoint
      positions {
        data {
          instrument_id
          account
          exchange
          symbol_root
          expiry
          currency
          multiplier
          instrument_type
          quantity
          price
          period
          initial_margin
          maintenance_margin
          positions_pnl_pct
          margin_per_contract
          position_value
          maintenance_margin_pv
          absolute_pnl
          relative_pnl
          price_change
          price_diff
          maintenance_margin_relative
          __typename
        }
        formats
        __typename
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      summaryType
      pnlType
      leverage
      leverageToDisplay
      minDollarPositionValueToShow
      cashLevel
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      posTrack {
        positions {
          name
          accountPosition
          modelPosition
          status
          instrumentId
          __typename
        }
        alertStatus
        isAlertVisible
        accountLastValid
        accountStatus
        modelLastValid
        modelStatus
        leverage
        __typename
      }
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
        __typename
      }
      milestones {
        title
        date
        color
        __typename
      }
      excludeFromOverallTotals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPortfolio = /* GraphQL */ `
  mutation CreatePortfolio(
    $input: CreatePortfolioInput!
    $condition: ModelPortfolioConditionInput
  ) {
    createPortfolio(input: $input, condition: $condition) {
      id
      name
      displayName
      slug
      portfolioManager
      description
      account {
        broker
        accountNum
        accountName
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      status
      type
      autoReconTimestamp
      autoRecon {
        instrumentID
        name
        modelPosition
        modelChanges
        cumModelContractsTraded
        cumModelContractsTradedRounded
        accountPosition
        positionChanges
        cumAccountContractsTraded
        differenceChanges
        positionDifference
        cumContractsTradedDiff
        __typename
      }
      lastSync
      pnlEndpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
          trade_level
          deposit
          __typename
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
          __typename
        }
        __typename
      }
      slippageEndpoint
      slippage {
        data {
          period
          daily_slippage_pct
          cumulative_slippage_pct
          daily_commission_pct
          cumulative_commission_pct
          ann_slippage_3_month_avg_pct
          ann_commission_3_month_avg_pct
          __typename
        }
        summary {
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
          __typename
        }
        formats
        __typename
      }
      positionsEndpoint
      positions {
        data {
          instrument_id
          account
          exchange
          symbol_root
          expiry
          currency
          multiplier
          instrument_type
          quantity
          price
          period
          initial_margin
          maintenance_margin
          positions_pnl_pct
          margin_per_contract
          position_value
          maintenance_margin_pv
          absolute_pnl
          relative_pnl
          price_change
          price_diff
          maintenance_margin_relative
          __typename
        }
        formats
        __typename
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      summaryType
      pnlType
      leverage
      leverageToDisplay
      minDollarPositionValueToShow
      cashLevel
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      posTrack {
        positions {
          name
          accountPosition
          modelPosition
          status
          instrumentId
          __typename
        }
        alertStatus
        isAlertVisible
        accountLastValid
        accountStatus
        modelLastValid
        modelStatus
        leverage
        __typename
      }
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
        __typename
      }
      milestones {
        title
        date
        color
        __typename
      }
      excludeFromOverallTotals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePortfolio = /* GraphQL */ `
  mutation UpdatePortfolio(
    $input: UpdatePortfolioInput!
    $condition: ModelPortfolioConditionInput
  ) {
    updatePortfolio(input: $input, condition: $condition) {
      id
      name
      displayName
      slug
      portfolioManager
      description
      account {
        broker
        accountNum
        accountName
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      status
      type
      autoReconTimestamp
      autoRecon {
        instrumentID
        name
        modelPosition
        modelChanges
        cumModelContractsTraded
        cumModelContractsTradedRounded
        accountPosition
        positionChanges
        cumAccountContractsTraded
        differenceChanges
        positionDifference
        cumContractsTradedDiff
        __typename
      }
      lastSync
      pnlEndpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
          trade_level
          deposit
          __typename
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
          __typename
        }
        __typename
      }
      slippageEndpoint
      slippage {
        data {
          period
          daily_slippage_pct
          cumulative_slippage_pct
          daily_commission_pct
          cumulative_commission_pct
          ann_slippage_3_month_avg_pct
          ann_commission_3_month_avg_pct
          __typename
        }
        summary {
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
          __typename
        }
        formats
        __typename
      }
      positionsEndpoint
      positions {
        data {
          instrument_id
          account
          exchange
          symbol_root
          expiry
          currency
          multiplier
          instrument_type
          quantity
          price
          period
          initial_margin
          maintenance_margin
          positions_pnl_pct
          margin_per_contract
          position_value
          maintenance_margin_pv
          absolute_pnl
          relative_pnl
          price_change
          price_diff
          maintenance_margin_relative
          __typename
        }
        formats
        __typename
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      summaryType
      pnlType
      leverage
      leverageToDisplay
      minDollarPositionValueToShow
      cashLevel
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      posTrack {
        positions {
          name
          accountPosition
          modelPosition
          status
          instrumentId
          __typename
        }
        alertStatus
        isAlertVisible
        accountLastValid
        accountStatus
        modelLastValid
        modelStatus
        leverage
        __typename
      }
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
        __typename
      }
      milestones {
        title
        date
        color
        __typename
      }
      excludeFromOverallTotals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePortfolio = /* GraphQL */ `
  mutation DeletePortfolio(
    $input: DeletePortfolioInput!
    $condition: ModelPortfolioConditionInput
  ) {
    deletePortfolio(input: $input, condition: $condition) {
      id
      name
      displayName
      slug
      portfolioManager
      description
      account {
        broker
        accountNum
        accountName
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      status
      type
      autoReconTimestamp
      autoRecon {
        instrumentID
        name
        modelPosition
        modelChanges
        cumModelContractsTraded
        cumModelContractsTradedRounded
        accountPosition
        positionChanges
        cumAccountContractsTraded
        differenceChanges
        positionDifference
        cumContractsTradedDiff
        __typename
      }
      lastSync
      pnlEndpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
          trade_level
          deposit
          __typename
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
          __typename
        }
        __typename
      }
      slippageEndpoint
      slippage {
        data {
          period
          daily_slippage_pct
          cumulative_slippage_pct
          daily_commission_pct
          cumulative_commission_pct
          ann_slippage_3_month_avg_pct
          ann_commission_3_month_avg_pct
          __typename
        }
        summary {
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
          __typename
        }
        formats
        __typename
      }
      positionsEndpoint
      positions {
        data {
          instrument_id
          account
          exchange
          symbol_root
          expiry
          currency
          multiplier
          instrument_type
          quantity
          price
          period
          initial_margin
          maintenance_margin
          positions_pnl_pct
          margin_per_contract
          position_value
          maintenance_margin_pv
          absolute_pnl
          relative_pnl
          price_change
          price_diff
          maintenance_margin_relative
          __typename
        }
        formats
        __typename
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      summaryType
      pnlType
      leverage
      leverageToDisplay
      minDollarPositionValueToShow
      cashLevel
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      posTrack {
        positions {
          name
          accountPosition
          modelPosition
          status
          instrumentId
          __typename
        }
        alertStatus
        isAlertVisible
        accountLastValid
        accountStatus
        modelLastValid
        modelStatus
        leverage
        __typename
      }
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
        __typename
      }
      milestones {
        title
        date
        color
        __typename
      }
      excludeFromOverallTotals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPortfolioManager = /* GraphQL */ `
  mutation CreatePortfolioManager(
    $input: CreatePortfolioManagerInput!
    $condition: ModelPortfolioManagerConditionInput
  ) {
    createPortfolioManager(input: $input, condition: $condition) {
      name
      displayName
      sortOrder
      color
      displayMarketView
      slug
      portfolios {
        items {
          id
          name
          displayName
          slug
          portfolioManager
          description
          account {
            broker
            accountNum
            accountName
            __typename
          }
          portfolioMeta {
            cashLevel
            impliedEquity
            leverageSetting
            leverage
            leverageToDisplay
            minDollarPositionValueToShow
            dailyReturnThreshold
            __typename
          }
          status
          type
          autoReconTimestamp
          autoRecon {
            instrumentID
            name
            modelPosition
            modelChanges
            cumModelContractsTraded
            cumModelContractsTradedRounded
            accountPosition
            positionChanges
            cumAccountContractsTraded
            differenceChanges
            positionDifference
            cumContractsTradedDiff
            __typename
          }
          lastSync
          pnlEndpoint
          historicalPnl {
            data {
              period
              official
              portfolio_value
              daily_return
              cumulative_return
              daily_return_pct
              cumulative_return_pct
              leverage
              leverage_cash
              realized_leverage
              return_cash
              cumulative_return_cash
              margin_initial
              margin_maintenance
              pct_drawdown
              trade_level
              deposit
              __typename
            }
            formats
            summary {
              total_cumulative_return_pct
              avg_daily_return_pct
              avg_calendar_daily_return_pct
              __typename
            }
            __typename
          }
          slippageEndpoint
          slippage {
            data {
              period
              daily_slippage_pct
              cumulative_slippage_pct
              daily_commission_pct
              cumulative_commission_pct
              ann_slippage_3_month_avg_pct
              ann_commission_3_month_avg_pct
              __typename
            }
            summary {
              total_cumulative_slippage_pct
              avg_daily_slippage_pct
              avg_calendar_daily_slippage_pct
              __typename
            }
            formats
            __typename
          }
          positionsEndpoint
          positions {
            data {
              instrument_id
              account
              exchange
              symbol_root
              expiry
              currency
              multiplier
              instrument_type
              quantity
              price
              period
              initial_margin
              maintenance_margin
              positions_pnl_pct
              margin_per_contract
              position_value
              maintenance_margin_pv
              absolute_pnl
              relative_pnl
              price_change
              price_diff
              maintenance_margin_relative
              __typename
            }
            formats
            __typename
          }
          metaData {
            lastPNLDate
            todaysPNL
            todaysPNLValue
            yesterdaysPNLValue
            monthToDate
            quarterToDate
            yearToDate
            cumulativeReturnCash
            exposure
            todays_drawdown
            cashLevel
            leverageSetting
            currentLeverage
            marginMaintenance
            marginMaintenancePortfolioValue
            sharpeRatio
            highWater
            total_cumulative_return_pct
            avg_daily_return_pct
            avg_calendar_daily_return_pct
            total_cumulative_slippage_pct
            avg_daily_slippage_pct
            avg_calendar_daily_slippage_pct
            total_number_of_pnl_days
            total_number_of_slippage_days
            total_number_of_positions
            total_number_of_commodities
            total_number_of_unofficial_days
            __typename
          }
          summaryType
          pnlType
          leverage
          leverageToDisplay
          minDollarPositionValueToShow
          cashLevel
          leverageSetting
          dailyReturnThreshold
          maxMarginPerPosition
          maxMarginPerPortfolio
          posTrack {
            positions {
              name
              accountPosition
              modelPosition
              status
              instrumentId
              __typename
            }
            alertStatus
            isAlertVisible
            accountLastValid
            accountStatus
            modelLastValid
            modelStatus
            leverage
            __typename
          }
          health {
            position_differences
            traded_differences
            unofficial_numbers
            daily_return_warning
            max_position_by_portfolio
            max_position_by_position
            __typename
          }
          milestones {
            title
            date
            color
            __typename
          }
          excludeFromOverallTotals
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePortfolioManager = /* GraphQL */ `
  mutation UpdatePortfolioManager(
    $input: UpdatePortfolioManagerInput!
    $condition: ModelPortfolioManagerConditionInput
  ) {
    updatePortfolioManager(input: $input, condition: $condition) {
      name
      displayName
      sortOrder
      color
      displayMarketView
      slug
      portfolios {
        items {
          id
          name
          displayName
          slug
          portfolioManager
          description
          account {
            broker
            accountNum
            accountName
            __typename
          }
          portfolioMeta {
            cashLevel
            impliedEquity
            leverageSetting
            leverage
            leverageToDisplay
            minDollarPositionValueToShow
            dailyReturnThreshold
            __typename
          }
          status
          type
          autoReconTimestamp
          autoRecon {
            instrumentID
            name
            modelPosition
            modelChanges
            cumModelContractsTraded
            cumModelContractsTradedRounded
            accountPosition
            positionChanges
            cumAccountContractsTraded
            differenceChanges
            positionDifference
            cumContractsTradedDiff
            __typename
          }
          lastSync
          pnlEndpoint
          historicalPnl {
            data {
              period
              official
              portfolio_value
              daily_return
              cumulative_return
              daily_return_pct
              cumulative_return_pct
              leverage
              leverage_cash
              realized_leverage
              return_cash
              cumulative_return_cash
              margin_initial
              margin_maintenance
              pct_drawdown
              trade_level
              deposit
              __typename
            }
            formats
            summary {
              total_cumulative_return_pct
              avg_daily_return_pct
              avg_calendar_daily_return_pct
              __typename
            }
            __typename
          }
          slippageEndpoint
          slippage {
            data {
              period
              daily_slippage_pct
              cumulative_slippage_pct
              daily_commission_pct
              cumulative_commission_pct
              ann_slippage_3_month_avg_pct
              ann_commission_3_month_avg_pct
              __typename
            }
            summary {
              total_cumulative_slippage_pct
              avg_daily_slippage_pct
              avg_calendar_daily_slippage_pct
              __typename
            }
            formats
            __typename
          }
          positionsEndpoint
          positions {
            data {
              instrument_id
              account
              exchange
              symbol_root
              expiry
              currency
              multiplier
              instrument_type
              quantity
              price
              period
              initial_margin
              maintenance_margin
              positions_pnl_pct
              margin_per_contract
              position_value
              maintenance_margin_pv
              absolute_pnl
              relative_pnl
              price_change
              price_diff
              maintenance_margin_relative
              __typename
            }
            formats
            __typename
          }
          metaData {
            lastPNLDate
            todaysPNL
            todaysPNLValue
            yesterdaysPNLValue
            monthToDate
            quarterToDate
            yearToDate
            cumulativeReturnCash
            exposure
            todays_drawdown
            cashLevel
            leverageSetting
            currentLeverage
            marginMaintenance
            marginMaintenancePortfolioValue
            sharpeRatio
            highWater
            total_cumulative_return_pct
            avg_daily_return_pct
            avg_calendar_daily_return_pct
            total_cumulative_slippage_pct
            avg_daily_slippage_pct
            avg_calendar_daily_slippage_pct
            total_number_of_pnl_days
            total_number_of_slippage_days
            total_number_of_positions
            total_number_of_commodities
            total_number_of_unofficial_days
            __typename
          }
          summaryType
          pnlType
          leverage
          leverageToDisplay
          minDollarPositionValueToShow
          cashLevel
          leverageSetting
          dailyReturnThreshold
          maxMarginPerPosition
          maxMarginPerPortfolio
          posTrack {
            positions {
              name
              accountPosition
              modelPosition
              status
              instrumentId
              __typename
            }
            alertStatus
            isAlertVisible
            accountLastValid
            accountStatus
            modelLastValid
            modelStatus
            leverage
            __typename
          }
          health {
            position_differences
            traded_differences
            unofficial_numbers
            daily_return_warning
            max_position_by_portfolio
            max_position_by_position
            __typename
          }
          milestones {
            title
            date
            color
            __typename
          }
          excludeFromOverallTotals
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePortfolioManager = /* GraphQL */ `
  mutation DeletePortfolioManager(
    $input: DeletePortfolioManagerInput!
    $condition: ModelPortfolioManagerConditionInput
  ) {
    deletePortfolioManager(input: $input, condition: $condition) {
      name
      displayName
      sortOrder
      color
      displayMarketView
      slug
      portfolios {
        items {
          id
          name
          displayName
          slug
          portfolioManager
          description
          account {
            broker
            accountNum
            accountName
            __typename
          }
          portfolioMeta {
            cashLevel
            impliedEquity
            leverageSetting
            leverage
            leverageToDisplay
            minDollarPositionValueToShow
            dailyReturnThreshold
            __typename
          }
          status
          type
          autoReconTimestamp
          autoRecon {
            instrumentID
            name
            modelPosition
            modelChanges
            cumModelContractsTraded
            cumModelContractsTradedRounded
            accountPosition
            positionChanges
            cumAccountContractsTraded
            differenceChanges
            positionDifference
            cumContractsTradedDiff
            __typename
          }
          lastSync
          pnlEndpoint
          historicalPnl {
            data {
              period
              official
              portfolio_value
              daily_return
              cumulative_return
              daily_return_pct
              cumulative_return_pct
              leverage
              leverage_cash
              realized_leverage
              return_cash
              cumulative_return_cash
              margin_initial
              margin_maintenance
              pct_drawdown
              trade_level
              deposit
              __typename
            }
            formats
            summary {
              total_cumulative_return_pct
              avg_daily_return_pct
              avg_calendar_daily_return_pct
              __typename
            }
            __typename
          }
          slippageEndpoint
          slippage {
            data {
              period
              daily_slippage_pct
              cumulative_slippage_pct
              daily_commission_pct
              cumulative_commission_pct
              ann_slippage_3_month_avg_pct
              ann_commission_3_month_avg_pct
              __typename
            }
            summary {
              total_cumulative_slippage_pct
              avg_daily_slippage_pct
              avg_calendar_daily_slippage_pct
              __typename
            }
            formats
            __typename
          }
          positionsEndpoint
          positions {
            data {
              instrument_id
              account
              exchange
              symbol_root
              expiry
              currency
              multiplier
              instrument_type
              quantity
              price
              period
              initial_margin
              maintenance_margin
              positions_pnl_pct
              margin_per_contract
              position_value
              maintenance_margin_pv
              absolute_pnl
              relative_pnl
              price_change
              price_diff
              maintenance_margin_relative
              __typename
            }
            formats
            __typename
          }
          metaData {
            lastPNLDate
            todaysPNL
            todaysPNLValue
            yesterdaysPNLValue
            monthToDate
            quarterToDate
            yearToDate
            cumulativeReturnCash
            exposure
            todays_drawdown
            cashLevel
            leverageSetting
            currentLeverage
            marginMaintenance
            marginMaintenancePortfolioValue
            sharpeRatio
            highWater
            total_cumulative_return_pct
            avg_daily_return_pct
            avg_calendar_daily_return_pct
            total_cumulative_slippage_pct
            avg_daily_slippage_pct
            avg_calendar_daily_slippage_pct
            total_number_of_pnl_days
            total_number_of_slippage_days
            total_number_of_positions
            total_number_of_commodities
            total_number_of_unofficial_days
            __typename
          }
          summaryType
          pnlType
          leverage
          leverageToDisplay
          minDollarPositionValueToShow
          cashLevel
          leverageSetting
          dailyReturnThreshold
          maxMarginPerPosition
          maxMarginPerPortfolio
          posTrack {
            positions {
              name
              accountPosition
              modelPosition
              status
              instrumentId
              __typename
            }
            alertStatus
            isAlertVisible
            accountLastValid
            accountStatus
            modelLastValid
            modelStatus
            leverage
            __typename
          }
          health {
            position_differences
            traded_differences
            unofficial_numbers
            daily_return_warning
            max_position_by_portfolio
            max_position_by_position
            __typename
          }
          milestones {
            title
            date
            color
            __typename
          }
          excludeFromOverallTotals
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
