import React from "react"
import moment from "moment"

const useDateControls = () => {
  const initialDateFilter = {
    startIndex: "",
    endIndex: "",
  }
  const [dateFilter, setDateFilter] = React.useState(initialDateFilter)
  const [selectedDateFilter, setSelectedDateFilter] =
    React.useState(initialDateFilter)

  const resetDateFilter = () => setDateFilter(initialDateFilter)
  const resetSelectedDateFilter = () => setSelectedDateFilter(initialDateFilter)

  const setStartSelectDate = (startIndex) =>
    setSelectedDateFilter((f) => ({ ...f, startIndex }))
  const setEndSelectDate = (endIndex) =>
    setSelectedDateFilter((f) => ({ ...f, endIndex }))

  const zoomOut = () => {
    resetDateFilter()
    resetSelectedDateFilter()
  }
  const handle_date_filter = ({ startIndex, endIndex }) => {
    if (startIndex === endIndex || endIndex === "") {
      resetSelectedDateFilter()
    } else {
      if (moment(startIndex).isAfter(moment(endIndex)))
        [startIndex, endIndex] = [endIndex, startIndex]

      const date_diff = Math.abs(
        moment(startIndex).diff(moment(endIndex), "days")
      )
      if (date_diff > 5) {
        setDateFilter({ endIndex, startIndex })
      }
    }
  }

  const dateRangeFilter = (period) =>
    dateFilter.endIndex !== "" && dateFilter.startIndex !== ""
      ? moment(period).isSameOrBefore(dateFilter.endIndex) &&
        moment(period).isSameOrAfter(dateFilter.startIndex)
      : true

  return {
    dateFilter,
    resetDateFilter,
    selectedDateFilter,
    setStartSelectDate,
    setEndSelectDate,
    zoomOut,
    handle_date_filter,
    dateRangeFilter,
    resetSelectedDateFilter,
  }
}

export default useDateControls
