import React from "react"
import {
  Col,
  Container,
  Content,
  FlexboxGrid,
  Header,
  IconButton,
  List,
  Nav,
  Navbar,
  Panel,
} from "rsuite"
import { FaConfluence } from "react-icons/fa"
import { SiGraylog } from "react-icons/si"
import { DiScrum } from "react-icons/di"
import { Icon } from "@rsuite/icons"
import { AiOutlineHome } from "react-icons/ai"
import { Link } from "react-router-dom"

const IconButtonNav = ({ to, icon, children }) => {
  return (
    <FlexboxGrid>
      <FlexboxGrid.Item>
        <IconButton
          href={to}
          target="_blank"
          rel="noreferrer"
          icon={<Icon as={icon} />}
        >
          {children}
        </IconButton>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}

const ListItemIconLink = ({ to, icon, text }) => {
  return (
    <List.Item style={{ padding: 10 }}>
      <IconButtonNav to={to} icon={icon}>
        {text}
      </IconButtonNav>
    </List.Item>
  )
}

const resource_list = [
  {
    to: "https://metagora.atlassian.net/wiki/spaces/QUANT/pages/152993828/Contact+Information",
    icon: FaConfluence,
    text: "Contact List",
  },
  {
    to: "https://graylog.metagora.io/",
    icon: SiGraylog,
    text: "Graylog Server",
  },
  {
    to: "https://metagora.atlassian.net/wiki/spaces/QUANT/pages",
    icon: FaConfluence,
    text: "Confluence documentation",
  },
  {
    to: "http://192.168.100.10/standup",
    icon: DiScrum,
    text: "Scrum presentation",
  },
  {
    to: "https://metagora.atlassian.net/wiki/spaces/QUANT/pages/453640193/Managed+Voting+Corporate+Actions+Manager",
    icon: FaConfluence,
    text: "Managed Voting (Corporate Actions Manager)",
  },
]

const ResourcesPage = () => {
  return (
    <Container>
      <Header>
        <Navbar>
          <Nav>
            <Nav.Item
              as={Link}
              to="/"
              style={{
                fontSize: 20,
              }}
            >
              <Icon as={AiOutlineHome} />
            </Nav.Item>
          </Nav>
        </Navbar>
      </Header>
      <Content>
        <FlexboxGrid
          justify="center"
          align="middle"
          style={{
            height: "80vh",
          }}
        >
          <FlexboxGrid.Item as={Col} colspan={24} md={12}>
            <Panel bordered>
              <List>
                {resource_list.map((li, inx) => (
                  <ListItemIconLink key={inx} {...li} />
                ))}
              </List>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  )
}

export default ResourcesPage
