import React from "react"
import { Button, IconButton, Modal } from "rsuite"

const FormModal = ({
  buttonText = "",
  headerText,
  size = "xs",
  children,
  icon,
}) => {
  const [isOpen, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Modal open={isOpen} onClose={handleClose} size={size}>
        <Modal.Header>
          <Modal.Title>{headerText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children({ handleClose })}</Modal.Body>
      </Modal>
      {icon ? (
        <IconButton
          onClick={handleOpen}
          circle
          icon={icon}
          appearance="default"
        />
      ) : (
        <Button onClick={handleOpen} appearance="subtle">
          {buttonText}
        </Button>
      )}
    </>
  )
}

export default FormModal
