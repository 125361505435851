import React from "react"
import { NavLink } from "react-router-dom"
import { Nav, Navbar } from "rsuite"
import { Icon } from "@rsuite/icons"
import AdminIcon from "@rsuite/icons/Admin"
import UserInfoIcon from "@rsuite/icons/UserInfo"
import ExploreIcon from "@rsuite/icons/Explore"
import { BsGraphUp } from "react-icons/bs"
import useAuth from "../AmplifyAuth/useAuth"
import { useTheme } from "@aws-amplify/ui-react"

import { AppNavMenu } from "../../sections/AppHome/AppHome"

const PADDING_SETTING = 5
const ITEM_PADDING_SETTING = 5

const MainNavBarIconItem = ({ to, icon }) => {
  const { tokens } = useTheme()
  return (
    <Nav.Item as="div" style={{ padding: ITEM_PADDING_SETTING }}>
      <NavLink
        to={to}
        style={({ isActive }) => ({
          color: isActive ? "white" : tokens.colors.blue[20],
        })}
      >
        <Icon as={icon} style={{ fontSize: "1.8em" }} />
      </NavLink>
    </Nav.Item>
  )
}

const MainNavBar = () => {
  const { userMeta } = useAuth()
  return (
    <Navbar
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 888,
        height: 40,
      }}
    >
      <AppNavMenu />

      <Nav style={{ padding: PADDING_SETTING }}>
        {(userMeta?.isQuant || userMeta?.isPortfolioManager) && (
          <MainNavBarIconItem to="/quant" icon={BsGraphUp} />
        )}
      </Nav>

      <Nav pullRight style={{ padding: PADDING_SETTING }}>
        {userMeta?.isAdmin && (
          <MainNavBarIconItem to="/quant/admin" icon={AdminIcon} />
        )}
        {userMeta?.isQuant && (
          <MainNavBarIconItem to="/resources" icon={ExploreIcon} />
        )}
        <MainNavBarIconItem to="/profile" icon={UserInfoIcon} />
      </Nav>
    </Navbar>
  )
}

export default MainNavBar
