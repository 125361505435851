import { useTheme } from "@aws-amplify/ui-react"
import React from "react"
import { RiMailCheckFill, RiMailForbidFill } from "react-icons/ri"
import { NavLink, Outlet, useNavigate } from "react-router-dom"
import useAuth from "./useAuth"
import {
  Button,
  FlexboxGrid,
  List,
  Nav,
  Navbar,
  Panel,
  PanelGroup,
  Stack,
} from "rsuite"
import AppVersion from "../common/AppVersion"
import { Icon } from "@rsuite/icons"
import { AiOutlineHome } from "react-icons/ai"
import { useQueryClient } from "@tanstack/react-query"

const NavItemLink = ({ to, children }) => {
  const { tokens } = useTheme()
  return (
    <Nav.Item as="div">
      <NavLink
        to={to}
        end
        style={({ isActive }) => ({
          color: isActive ? tokens.colors.white : tokens.colors.neutral[60],
          backgroundColor: isActive ? tokens.colors.neutral[90] : "inherit",
          padding: 5,
        })}
      >
        {children}
      </NavLink>
    </Nav.Item>
  )
}

export const ProfileLayout = () => {
  const { userMeta } = useAuth()
  return (
    <>
      <Navbar>
        <Nav>
          <NavItemLink to="/">
            <Icon as={AiOutlineHome} style={{ fontSize: 20 }} />
          </NavItemLink>
          {(userMeta?.isQuant || userMeta?.isPortfolioManager) && (
            <NavItemLink to="/quant">Portfolios</NavItemLink>
          )}

          <NavItemLink to="/profile">General</NavItemLink>
        </Nav>
        <Nav pullRight>
          <NavItemLink to="access">Access</NavItemLink>
          <Nav.Item>
            <AppVersion />
          </Nav.Item>
        </Nav>
      </Navbar>
      <FlexboxGrid align="middle" justify="center">
        <FlexboxGrid.Item colspan={12}>
          <Outlet />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  )
}

export const General = () => {
  const {
    userMeta,
    actions: { signOut },
  } = useAuth()
  const navigate = useNavigate()

  const queryClient = useQueryClient()

  return (
    <div>
      <Panel>
        <h5>Username: {userMeta?.username}</h5>
        <Stack justifyContent="space-between">
          <Stack alignItems="center" spacing={2}>
            {userMeta?.email_verified ? (
              <RiMailCheckFill color="green" />
            ) : (
              <RiMailForbidFill color="red" />
            )}
            <div>{userMeta?.email}</div>
          </Stack>
          <Button
            onClick={() => {
              const confirm = window.confirm(
                `This will clear defaults and log you out. Do you really want to continue?`
              )
              if (confirm === true) {
                localStorage.clear()

                queryClient.clear()
                signOut()
                navigate("/")
              }
            }}
            appearance="ghost"
          >
            Reset Defaults
          </Button>
        </Stack>
      </Panel>
      <Button
        onClick={() => {
          signOut()
          navigate("/")
        }}
        appearance="primary"
        block
      >
        <h3>Sign Out</h3>
      </Button>
    </div>
  )
}

export const Access = () => {
  const { userMeta } = useAuth()
  return (
    <Panel>
      <PanelGroup>
        <Panel header={<h4>Access</h4>}>
          <List bordered>
            {userMeta?.access?.map((gp, inx) => (
              <List.Item key={inx}>{gp}</List.Item>
            ))}
          </List>
        </Panel>
        <Panel header={<h4>Groups</h4>}>
          <List bordered>
            {userMeta?.groups?.map((gp, inx) => (
              <List.Item key={inx}>{gp}</List.Item>
            ))}
          </List>
        </Panel>
      </PanelGroup>
    </Panel>
  )
}
