import { useEffect, useState } from "react"
import { useDebounce } from "./useDebounce"

export const useFilterManagerLists = (search, managers) => {
  const [filteredManagers, setFilteredManagers] = useState([])

  const debounceSearch = useDebounce(search)

  useEffect(() => {
    setFilteredManagers((prevState) => {
      if (debounceSearch === "") {
        return managers?.sort((a, b) => {
          return a.sortOrder - b.sortOrder
        })
      }

      prevState = managers
        ?.filter((pS) =>
          pS.name.toLowerCase().includes(debounceSearch.toLowerCase())
        )
        .sort((a, b) => {
          return a.sortOrder - b.sortOrder
        })

      return [...prevState]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearch, managers?.length])

  return { filteredManagers }
}
