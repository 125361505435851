import React from "react"
import {
  header_summary_config,
  header_extended_config,
} from "../lib/headerConfig"
import { useTheme } from "@aws-amplify/ui-react"
import { Stack, Tooltip, Whisper } from "rsuite"
import { useControls } from "../../../lib/controls/ControlContext"

export const PortfolioHeader = ({ metaData }) => {
  const { expandHeader, compactPnlTable } = useControls()
  const header_summary = expandHeader.isDefault
    ? [
        ...header_summary_config(metaData),
        ...header_extended_config(metaData, compactPnlTable.isDefault),
      ]
    : header_summary_config(metaData)

  const { tokens } = useTheme()
  const itemStyle = {
    lineHeight: tokens.space.medium,
    fontWeight: tokens.fontWeights.bold,
    fontSize: tokens.fontSizes.xsmall,
    whiteSpace: "nowrap" /* prevent text wrap */,
  }
  return (
    <Stack
      wrap
      spacing={10}
      onClick={expandHeader.toggle}
      style={{
        cursor: expandHeader.isDefault ? "zoom-in" : "zoom-out",
        padding: 3,
      }}
    >
      {header_summary.map(({ mobile_text, tooltip, value, formatFn }, i) => {
        return (
          <Whisper
            key={i}
            placement="autoVerticalStart"
            trigger="hover"
            speaker={<Tooltip>{tooltip}</Tooltip>}
          >
            <div style={itemStyle}>
              <div>{mobile_text}</div>
              <div>{!!formatFn ? formatFn(value) : null}</div>
            </div>
          </Whisper>
        )
      })}
    </Stack>
  )
}

export default PortfolioHeader
