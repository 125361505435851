import { formats } from "../../../lib/utils"
import {
  cell_format,
  cell_raw_format,
} from "../../../components/common/DataTable/utils"

export const header_summary_config = (metaData) => [
  {
    text: "Today's P/L",
    mobile_text: "PNL",
    tooltip: "Today's P/L",
    dataKey: "todaysPNL",
    sort_key: "metaData.todaysPNL",
    value: metaData?.todaysPNL,
    formatFn: cell_format(formats.pct),
    width: 65,
  },
  {
    text: "Month To Date",
    mobile_text: "MTD",
    tooltip: "Month To Date",
    sort_key: "metaData.monthToDate",
    dataKey: "monthToDate",
    value: metaData?.monthToDate,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Quarter To Date",
    mobile_text: "QTD",
    tooltip: "Quarter To Date",
    sort_key: "metaData.quarterToDate",
    dataKey: "quarterToDate",
    value: metaData?.quarterToDate,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Year To Date",
    mobile_text: "YTD",
    tooltip: "Year To Date",
    sort_key: "metaData.yearToDate",
    dataKey: "yearToDate",
    value: metaData?.yearToDate,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Drawdown",
    mobile_text: "DD",
    tooltip: "Drawdown",
    sort_key: "metaData.todays_drawdown",
    dataKey: "todays_drawdown",
    value: metaData?.todays_drawdown,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 85,
  },
  {
    text: "Sharpe Ratio",
    mobile_text: "SR",
    tooltip: "Sharpe Ratio",
    sort_key: "metaData.sharpeRatio",
    dataKey: "sharpeRatio",
    value: metaData?.sharpeRatio,
    formatFn: cell_raw_format(formats.float_2),
    width: 60,
  },
  {
    text: "Margin Maintenance / Portfolio Value",
    mobile_text: "MM/PV",
    tooltip: "Margin Maintenance / Portfolio Value",
    sort_key: "metaData.marginMaintenancePortfolioValue",
    dataKey: "marginMaintenancePortfolioValue",
    disable_value_color: true,
    value: metaData?.marginMaintenancePortfolioValue,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
]

export const header_extended_config = (metaData, compact = false) => [
  {
    text: "Cumulative Return Cash",
    mobile_text: "CRC",
    tooltip: "Cumulative Return Cash",
    sort_key: "metaData.cumulativeReturnCash",
    dataKey: "cumulativeReturnCash",
    value: metaData?.cumulativeReturnCash,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 85,
  },
  {
    text: "Exposure",
    mobile_text: "EX",
    tooltip: "Exposure",
    sort_key: "metaData.exposure",
    dataKey: "exposure",
    // value: port?.cashLevel ? port?.cashLevel * port?.leverageSetting : null,
    value: metaData?.exposure,
    disable_value_color: true,
    hide_icon: true,
    formatFn: cell_raw_format(compact ? formats.us_abbr : formats.currency),
    width: 110,
  },
  {
    text: "Cash Level (as per ML)",
    mobile_text: "CL-ML",
    tooltip: "Cash Level (as per ML)",
    sort_key: "metaData.cashLevel",
    dataKey: "cashLevel",
    disable_value_color: true,
    value: metaData?.cashLevel,
    hide_icon: true,
    formatFn: cell_raw_format(compact ? formats.us_abbr : formats.currency),
    width: 110,
  },
  {
    text: "Leverage Setting (as per ML)",
    mobile_text: "LVG-ML",
    tooltip: "Leverage Setting (as per ML)",
    sort_key: "portfolioMeta.leverageSetting",
    dataKey: "leverageSetting",
    disable_value_color: true,
    value: metaData?.leverageSetting,
    hide_icon: true,
    formatFn: cell_raw_format(formats.string),
    width: 65,
  },
  {
    text: "Margin Maintenance",
    mobile_text: "MM",
    tooltip: "Margin Maintenance",
    sort_key: "metaData.marginMaintenance",
    dataKey: "marginMaintenance",
    disable_value_color: true,
    value: metaData?.marginMaintenance,
    hide_icon: true,
    formatFn: cell_raw_format(compact ? formats.us_abbr : formats.currency),
    width: 110,
  },

  {
    text: "Today's Portfolio Value",
    mobile_text: "TPV",
    tooltip: "Today's Portfolio Value",
    sort_key: "metaData.todaysPNLValue",
    dataKey: "todaysPNLValue",
    disable_value_color: true,
    value: metaData?.todaysPNLValue,
    hide_icon: true,
    formatFn: cell_raw_format(compact ? formats.us_abbr : formats.currency),
    width: 110,
  },

  {
    text: "High Water Mark",
    mobile_text: "HIGH",
    tooltip: "High Water Mark",
    sort_key: "metaData.highWater",
    dataKey: "highWater",
    value: metaData?.highWater,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Total Cumulative Return",
    mobile_text: "TCR",
    tooltip: "Total Cumulative Return",
    sort_key: "metaData.total_cumulative_return_pct",
    dataKey: "total_cumulative_return_pct",
    value: metaData?.total_cumulative_return_pct,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Avg Daily Return",
    mobile_text: "ADR",
    tooltip: "Avg Daily Return",
    sort_key: "metaData.avg_daily_return_pct",
    dataKey: "avg_daily_return_pct",
    value: metaData?.avg_daily_return_pct,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Avg Daily Calendar Return",
    mobile_text: "ADCR",
    tooltip: "Avg Daily Calendar Return",
    sort_key: "metaData.avg_calendar_daily_return_pct",
    dataKey: "avg_calendar_daily_return_pct",
    value: metaData?.avg_calendar_daily_return_pct,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Total Cumulative Slippage",
    mobile_text: "TCS",
    tooltip: "Total Cumulative Slippage",
    sort_key: "metaData.total_cumulative_slippage_pct",
    dataKey: "total_cumulative_slippage_pct",
    value: metaData?.total_cumulative_slippage_pct,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Avg Daily Slippage",
    mobile_text: "ADS",
    tooltip: "Avg Daily Slippage",
    sort_key: "metaData.avg_daily_slippage_pct",
    dataKey: "avg_daily_slippage_pct",
    value: metaData?.avg_daily_slippage_pct,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Avg Daily Calendar Slippage",
    mobile_text: "ADCS",
    tooltip: "Avg Daily Calendar Slippage",
    sort_key: "metaData.avg_calendar_daily_slippage_pct",
    dataKey: "avg_calendar_daily_slippage_pct",
    value: metaData?.avg_calendar_daily_slippage_pct,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
]

const header_config = (metaData) => [
  {
    text: "Today's P/L",
    mobile_text: "PNL",
    tooltip: "Today's P/L",
    sort_key: "metaData.todaysPNL",
    dataKey: "todaysPNL",
    value: metaData?.todaysPNL,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Month To Date",
    mobile_text: "MTD",
    tooltip: "Month To Date",
    sort_key: "metaData.monthToDate",
    dataKey: "monthToDate",
    value: metaData?.monthToDate,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Quarter To Date",
    mobile_text: "QTD",
    tooltip: "Quarter To Date",
    sort_key: "metaData.quarterToDate",
    dataKey: "quarterToDate",
    value: metaData?.quarterToDate,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Year To Date",
    mobile_text: "YTD",
    tooltip: "Year To Date",
    sort_key: "metaData.yearToDate",
    dataKey: "yearToDate",
    value: metaData?.yearToDate,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Drawdown",
    mobile_text: "DD",
    tooltip: "Drawdown",
    sort_key: "metaData.todays_drawdown",
    dataKey: "todays_drawdown",
    value: metaData?.todays_drawdown,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Sharpe Ratio",
    mobile_text: "SR",
    tooltip: "Sharpe Ratio",
    sort_key: "metaData.sharpeRatio",
    dataKey: "sharpeRatio",
    value: metaData?.sharpeRatio,
    formatFn: (v) => (!!v ? `${v.toFixed(2)}` : "--"),
    width: 75,
  },
  {
    text: "Margin Maintenance / Portfolio Value",
    mobile_text: "MM/PV",
    tooltip: "Margin Maintenance / Portfolio Value",
    sort_key: "metaData.marginMaintenancePortfolioValue",
    dataKey: "marginMaintenancePortfolioValue",
    disable_value_color: true,
    value: metaData?.marginMaintenancePortfolioValue,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Cumulative Return Cash",
    mobile_text: "CRC",
    tooltip: "Cumulative Return Cash",
    sort_key: "metaData.cumulativeReturnCash",
    dataKey: "cumulativeReturnCash",
    value: metaData?.cumulativeReturnCash,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Today's Portfolio Value",
    mobile_text: "TPV",
    tooltip: "Today's Portfolio Value",
    sort_key: "metaData.todaysPNLValue",
    dataKey: "todaysPNLValue",
    disable_value_color: true,
    value: metaData?.todaysPNLValue,
    hide_icon: true,
    formatFn: formats.currency,
    width: 75,
  },
  {
    text: "Exposure",
    mobile_text: "EX",
    tooltip: "Exposure",
    sort_key: "metaData.exposure",
    dataKey: "exposure",
    // value: port?.cashLevel ? port?.cashLevel * port?.leverageSetting : null,
    value: metaData?.exposure,
    disable_value_color: true,
    hide_icon: true,
    formatFn: formats.currency,
    width: 75,
  },
  {
    text: "Cash Level (as per ML)",
    mobile_text: "CL-ML",
    tooltip: "Cash Level (as per ML)",
    sort_key: "metaData.cashLevel",
    dataKey: "cashLevel",
    disable_value_color: true,
    value: metaData?.cashLevel,
    hide_icon: true,
    formatFn: formats.currency,
    width: 75,
  },
  {
    text: "Leverage Setting (as per ML)",
    mobile_text: "LVG-ML",
    tooltip: "Leverage Setting (as per ML)",
    sort_key: "portfolioMeta.leverageSetting",
    disable_value_color: true,
    value: metaData?.leverageSetting,
    hide_icon: true,
    formatFn: formats.string,
    width: 75,
  },
  {
    text: "Margin Maintenance",
    mobile_text: "MM",
    tooltip: "Margin Maintenance",
    sort_key: "metaData.marginMaintenance",
    dataKey: "marginMaintenance",
    disable_value_color: true,
    value: metaData?.marginMaintenance,
    hide_icon: true,
    formatFn: formats.currency,
    width: 75,
  },

  {
    text: "High Water Mark",
    mobile_text: "HIGH",
    tooltip: "High Water Mark",
    sort_key: "metaData.highWater",
    dataKey: "highWater",
    value: metaData?.highWater,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Total Cumulative Return",
    mobile_text: "TCR",
    tooltip: "Total Cumulative Return",
    sort_key: "metaData.total_cumulative_return_pct",
    dataKey: "total_cumulative_return_pct",
    value: metaData?.total_cumulative_return_pct,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Avg Daily Return",
    mobile_text: "ADR",
    tooltip: "Avg Daily Return",
    sort_key: "metaData.avg_daily_return_pct",
    dataKey: "avg_daily_return_pct",
    value: metaData?.avg_daily_return_pct,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Avg Daily Calendar Return",
    mobile_text: "ADCR",
    tooltip: "Avg Daily Calendar Return",
    sort_key: "metaData.avg_calendar_daily_return_pct",
    dataKey: "avg_calendar_daily_return_pct",
    value: metaData?.avg_calendar_daily_return_pct,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Total Cumulative Slippage",
    mobile_text: "TCS",
    tooltip: "Total Cumulative Slippage",
    sort_key: "metaData.total_cumulative_slippage_pct",
    dataKey: "total_cumulative_slippage_pct",
    value: metaData?.total_cumulative_slippage_pct,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Avg Daily Slippage",
    mobile_text: "ADS",
    tooltip: "Avg Daily Slippage",
    sort_key: "metaData.avg_daily_slippage_pct",
    dataKey: "avg_daily_slippage_pct",
    value: metaData?.avg_daily_slippage_pct,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
  {
    text: "Avg Daily Calendar Slippage",
    mobile_text: "ADCS",
    tooltip: "Avg Daily Calendar Slippage",
    sort_key: "metaData.avg_calendar_daily_slippage_pct",
    dataKey: "avg_calendar_daily_slippage_pct",
    value: metaData?.avg_calendar_daily_slippage_pct,
    hide_icon: true,
    formatFn: cell_format(formats.pct),
    width: 75,
  },
]

export default header_config
