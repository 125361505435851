import React from "react"
import stale_data_tile from "../../images/metagora_stale_data_error_tile.png"

const FullScreenAlert = ({ visibility }) => {
  const percentage_covered = visibility > 100 ? 0 : 100 - visibility
  const containerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${stale_data_tile})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    zIndex: -1000,
    opacity: 0.5,
  }

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: `${percentage_covered}%`,
    backgroundImage: `linear-gradient(to bottom,  rgba(0,0,0,1) ${percentage_covered}%, transparent)`,
  }

  return (
    <div style={containerStyle}>
      <div style={overlayStyle} />
    </div>
  )
}

export default FullScreenAlert
