import React from "react"
import { Icon } from "@rsuite/icons"
import { Button, Stack, Tooltip, Whisper } from "rsuite"
import {
  FaFlask,
  FaDollarSign,
  FaChartLine,
  FaFile,
  FaBook,
  FaPlay,
  FaPause,
  FaRegNewspaper,
  FaRegLightbulb,
  FaCompass,
  FaBalanceScale,
} from "react-icons/fa"

import { BiMapAlt } from "react-icons/bi"
import { MdSpeed, MdPaid } from "react-icons/md"
import { useTheme } from "@aws-amplify/ui-react"

const AppIcons = {
  PRODUCTION: {
    icon: FaDollarSign,
    type: "type",
  },
  BENCHMARK: {
    icon: MdSpeed,
    type: "type",
  },
  EXPERIMENTAL: {
    icon: FaFlask,
    type: "type",
  },
  DEVELOPMENT: {
    icon: FaRegLightbulb,
    type: "type",
  },
  MODEL: {
    icon: FaChartLine,
    type: "type",
  },
  LEGACY: {
    icon: FaBook,
    type: "type",
  },
  PAPER: {
    icon: FaRegNewspaper,
    type: "type",
    divider: true,
  },

  DRAFT: {
    icon: FaFile,
    type: "status",
  },
  LIVE: {
    icon: FaPause,
    type: "status",
  },
  PAUSED: {
    icon: FaPlay,
    type: "status",
    divider: true,
  },

  historical: {
    icon: MdPaid,
    type: "endpoint",
  },
  dashboard: {
    icon: BiMapAlt,
    type: "endpoint",
  },
  slippage: {
    icon: FaCompass,
    type: "endpoint",
  },

  auto_reconciliation: {
    icon: FaBalanceScale,
    type: "endpoint",
  },
}
const StatusFilters = ({ filterByIconArray, setFilterByIconArray }) => {
  const { tokens } = useTheme()

  const onFilterByIcon = (status) => {
    setFilterByIconArray((prevState) => {
      const exists = prevState.find((pS) => pS.name === status.name)
      if (exists) {
        const newState = prevState.filter((pS) => pS.name !== status.name)

        return [...newState]
      }
      return [...prevState, status]
    })
  }

  const onClear = () => {
    setFilterByIconArray([])
  }

  return (
    <Stack spacing={10} justifyContent="space-between">
      <Stack spacing={10}>
        {Object.keys(AppIcons).map((iconKey, index) => (
          <Whisper
            controlId={`control-id-${index}-status`}
            trigger="hover"
            speaker={<Tooltip>{iconKey.toUpperCase()}</Tooltip>}
            placement="top"
            key={index}
          >
            <div>
              <Icon
                key={index}
                onClick={() =>
                  onFilterByIcon({ ...AppIcons[iconKey], name: iconKey })
                }
                style={{ cursor: "pointer" }}
                as={AppIcons[iconKey].icon}
                fill={
                  filterByIconArray.find((fBI) => fBI.name === iconKey)
                    ? tokens.colors.icons[iconKey]?.value
                    : "grey"
                }
              />
            </div>
          </Whisper>
        ))}
        <Button appearance="subtle" onClick={onClear}>
          Clear
        </Button>
      </Stack>
    </Stack>
  )
}

export default StatusFilters
