import React from "react"
import { Form, InputGroup, Input, Whisper, Popover } from "rsuite"
import CopyIcon from "@rsuite/icons/Copy"
import TrashIcon from "@rsuite/icons/Trash"
import { updateFormValue } from "../controls/lib/utils"

const METAGORA_ENDPOINT = process.env.REACT_APP_ENDPOINT

const TextArea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
))

// Reusable Endpoint component
const EndpointInputGroup = ({
  label,
  endpoint,
  setFormValue,
  keyName,
  query,
  rows = 1,
}) => {
  return (
    <Form.Group controlId={keyName}>
      <Form.ControlLabel>{label} Endpoint</Form.ControlLabel>
      <InputGroup>
        <InputGroup.Button
          onClick={() => {
            setFormValue((fv) => ({
              ...fv,
              [keyName]: query,
            }))
          }}
        >
          Default
        </InputGroup.Button>
        <Form.Control
          name={keyName}
          label={label}
          accepter={TextArea}
          rows={rows}
        />
        <InputGroup.Button
          disabled={!endpoint}
          onClick={() => updateFormValue(setFormValue, keyName, endpoint, "")}
        >
          <TrashIcon />
        </InputGroup.Button>
        <InputGroup.Button
          disabled={!endpoint}
          onClick={() =>
            navigator.clipboard?.writeText(METAGORA_ENDPOINT + endpoint)
          }
        >
          <Whisper
            trigger="hover"
            controlId="control-id-hover"
            placement="top"
            speaker={
              <Popover>
                <p>Copy to Clipboard</p>
              </Popover>
            }
          >
            <CopyIcon />
          </Whisper>
        </InputGroup.Button>
      </InputGroup>
    </Form.Group>
  )
}

export default EndpointInputGroup
