// Define the leverage colors.
export const LEVERAGE_COLORS = {
  0: [27, 20, 100], // Deep blue.
  1: [6, 82, 221], // Blue.
  2: [18, 203, 196], // Light blue.
  3: [18, 137, 167], // Bluegreen.
  4: [0, 148, 50], // Green.
  5: [163, 203, 56], // Olive.
  6: [255, 195, 18], // Yellow.
  7: [247, 159, 31], // Orange.
  8: [238, 90, 36], // Red.
  9: [234, 32, 39], // Dark red.
}

// Define a function to generate a CSS bar.
export const cssBar = (color, percentage) => {
  const colorStr = color.join(",")
  return `linear-gradient(-90deg, rgba(${colorStr}, 0.25) 0%, rgba(${colorStr}, 0.15) ${percentage}%, rgba(0,0,0,0) ${percentage}%, rgba(0,0,0,0) 100%)`
}

// Define a function to calculate leverage colors.
export const calculateLeverageColors = (baseline) => {
  const thresholds = [
    baseline - 1,
    baseline - 0.5,
    baseline - 0.25,
    baseline - 0.1,
    baseline + 0.1,
    baseline + 0.6,
    baseline + 1.0,
    baseline + 1.4,
    baseline + 2,
  ]
  return (value) => {
    for (let i = 0; i < thresholds.length; i++) {
      if (value < thresholds[i]) {
        return i
      }
    }
    return 9 // Return dark red for all other cases.
  }
}

// Define a function to style a row based on PnL.
const leverageRowStyle = (leverage, baseLev, divider) => {
  const getValue = calculateLeverageColors(baseLev)
  const percentage = ((leverage / divider) * 100).toFixed()
  const color = LEVERAGE_COLORS[getValue(leverage)]

  return {
    backgroundImage: cssBar(color, percentage),
    backgroundPosition: "right",
  }
}

export default leverageRowStyle
