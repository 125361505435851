import React from "react"
import { Heading, Stack, Badge, Text, List } from "rsuite"
import { useNavigate } from "react-router-dom"
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine"
import CardContainer from "../../../components/common/CardContainer"

const ManagerItem = ({
  manager,
  index,
  isFromModal,
  modalSelectedManager,
  setModalSelectedManager,
}) => {
  const navigate = useNavigate()

  const onViewPortfolio = (e) => {
    e.stopPropagation()
    if (isFromModal) {
      setModalSelectedManager(manager)
    } else {
      navigate(`/quant/admin/${manager.slug}`)
    }
  }

  return (
    <>
      <List.Item
        style={{
          padding: 0,
          boxShadow: "none",
          borderRadius: "10px",
          marginBottom: "10px",
        }}
        key={index}
        index={index}
        onClick={onViewPortfolio}
      >
        <CardContainer>
          <Stack justifyContent="space-between" align="middle">
            <Stack.Item>
              <Stack spacing={10}>
                <Heading level={6}>{manager.name}</Heading>
              </Stack>
              <Stack alignItems="center" spacing={10}>
                <Text size="sm">{manager.slug}</Text>
              </Stack>
            </Stack.Item>
            <Stack spacing={3}>
              <Badge color="violet" content={manager.portfolios.length} />
              {isFromModal && (
                <ArrowRightLineIcon
                  style={{
                    marginRight: "15px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  color={
                    modalSelectedManager?.id === manager?.id
                      ? "orange"
                      : "white"
                  }
                />
              )}
            </Stack>
          </Stack>
        </CardContainer>
      </List.Item>
    </>
  )
}

export default ManagerItem
