export const metagoraPortfolioData = /* GraphQL */ `
  query MetagoraPortfolioData($input: MetagoraPortfolioInput) {
    metagoraPortfolioData(input: $input) {
      id
      name
      displayName
      description
      slug
      portfolioManager
      account {
        broker
        accountNum
        accountName
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
      }
      status
      type
      autoReconTimestamp
      autoRecon {
        name
        instrumentID
        modelPosition
        modelChanges
        cumModelContractsTraded
        cumModelContractsTradedRounded
        accountPosition
        positionChanges
        cumAccountContractsTraded
        differenceChanges
        positionDifference
        cumContractsTradedDiff
      }
      lastSync
      pnlEndpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
        }
      }
      slippageEndpoint
      slippage {
        data {
          period
          daily_slippage_pct
          cumulative_slippage_pct
          daily_commission_pct
          cumulative_commission_pct
          ann_slippage_3_month_avg_pct
          ann_commission_3_month_avg_pct
        }
        summary {
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
        }
        formats
      }
      positionsEndpoint
      positions {
        data {
          symbol_root
          expiry
          currency
          multiplier
          instrument_type
          quantity
          price
          period
          initial_margin
          maintenance_margin
          positions_pnl_pct
          margin_per_contract
          position_value
          maintenance_margin_pv
          absolute_pnl
          relative_pnl
          price_change
          price_diff
          maintenance_margin_relative
        }
        formats
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        monthToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
      }
      summaryType
      pnlType
      leverage
      leverageToDisplay
      minDollarPositionValueToShow
      cashLevel
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      posTrack {
        positions {
          name
          accountPosition
          modelPosition
          status
          instrumentId
        }
        alertStatus
        isAlertVisible
        accountLastValid
        accountStatus
        modelLastValid
        modelStatus
        leverage
      }
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
      }
      milestones {
        title
        date
        color
      }
      excludeFromOverallTotals
      createdAt
      updatedAt
    }
  }
`

export const getManagerAndPortfolios = /* GraphQL */ `
  query GetManagerAndPortfolios($slug: String!, $nextToken: String) {
    managerBySlug(slug: $slug) {
      items {
        name
        displayName
        sortOrder
        color
        displayMarketView
        slug
        portfolios(nextToken: $nextToken) {
          items {
            id
            name
            displayName
            slug
            portfolioManager
            description
            account {
              broker
              accountNum
              accountName
            }
            portfolioMeta {
              cashLevel
              impliedEquity
              leverageSetting
              leverage
              leverageToDisplay
              minDollarPositionValueToShow
              dailyReturnThreshold
            }
            status
            type
            autoReconTimestamp
            autoRecon {
              name
              instrumentID
              modelPosition
              modelChanges
              cumModelContractsTraded
              cumModelContractsTradedRounded
              accountPosition
              positionChanges
              cumAccountContractsTraded
              differenceChanges
              positionDifference
              cumContractsTradedDiff
            }
            lastSync
            pnlEndpoint
            historicalPnl {
              formats
            }
            slippageEndpoint
            slippage {
              formats
            }
            positionsEndpoint
            positions {
              formats
            }
            metaData {
              lastPNLDate
              todaysPNL
              todaysPNLValue
              monthToDate
              quarterToDate
              yearToDate
              cumulativeReturnCash
              exposure
              todays_drawdown
              cashLevel
              leverageSetting
              marginMaintenance
              marginMaintenancePortfolioValue
              sharpeRatio
              highWater
              total_cumulative_return_pct
              avg_daily_return_pct
              avg_calendar_daily_return_pct
              total_cumulative_slippage_pct
              avg_daily_slippage_pct
              avg_calendar_daily_slippage_pct
              total_number_of_pnl_days
              total_number_of_slippage_days
              total_number_of_positions
              total_number_of_commodities
            }
            summaryType
            pnlType
            leverage
            leverageToDisplay
            minDollarPositionValueToShow
            cashLevel
            leverageSetting
            dailyReturnThreshold
            maxMarginPerPosition
            maxMarginPerPortfolio
            posTrack {
              alertStatus
              isAlertVisible
              accountLastValid
              accountStatus
              modelLastValid
              modelStatus
              leverage
            }
            health {
              position_differences
              traded_differences
              unofficial_numbers
              daily_return_warning
              max_position_by_portfolio
              max_position_by_position
            }
            milestones {
              title
              date
              color
            }
            excludeFromOverallTotals
            createdAt
            updatedAt
          }
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const getManagerAndPortfoliosOld = /* GraphQL */ `
  query GetPortfolioManager($id: ID!, $nextToken: String) {
    getPortfolioManager(id: $id) {
      name
      displayName
      sortOrder
      color
      slug
      displayMarketView
      portfolios(nextToken: $nextToken) {
        items {
          id
          name
          displayName
          description
          slug
          portfolioManager
          account {
            broker
            accountNum
            accountName
          }
          portfolioMeta {
            cashLevel
            impliedEquity
            leverageSetting
            leverage
            leverageToDisplay
            minDollarPositionValueToShow
          }
          autoRecon {
            positionDifference
          }
          posTrack {
            positions {
              name
              accountPosition
              modelPosition
              status
              instrumentId
            }
            alertStatus
            isAlertVisible
            accountLastValid
            accountStatus
            modelLastValid
            modelStatus
            leverage
          }
          status
          type
          lastSync
          pnlEndpoint
          slippageEndpoint
          positionsEndpoint

          positions {
            data {
              maintenance_margin_relative
            }
            formats
          }
          metaData {
            lastPNLDate
            todaysPNL
            todaysPNLValue
            monthToDate
            yearToDate
            quarterToDate
            cumulativeReturnCash
            exposure
            todays_drawdown
            cashLevel
            leverageSetting
            marginMaintenance
            marginMaintenancePortfolioValue
            sharpeRatio
            highWater
            total_cumulative_return_pct
            avg_daily_return_pct
            avg_calendar_daily_return_pct
            total_cumulative_slippage_pct
            avg_daily_slippage_pct
            avg_calendar_daily_slippage_pct
            total_number_of_pnl_days
            total_number_of_slippage_days
            total_number_of_positions
            total_number_of_commodities
          }
          health {
            position_differences
            traded_differences
            unofficial_numbers
            daily_return_warning
            max_position_by_portfolio
            max_position_by_position
          }
          milestones {
            title
            date
            color
          }
          excludeFromOverallTotals
          summaryType
          leverage
          leverageToDisplay
          minDollarPositionValueToShow
          cashLevel
          leverageSetting
          dailyReturnThreshold
          maxMarginPerPosition
          maxMarginPerPortfolio
          createdAt
          updatedAt
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`

export const listPortfolioManagers = /* GraphQL */ `
  query ListPortfolioManagers(
    $filter: ModelPortfolioManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortfolioManagers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        displayName
        sortOrder
        color
        slug
        displayMarketView
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const listAllManagers = /* GraphQL */ `
  query ListPortfolioManagers(
    $filter: ModelPortfolioManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortfolioManagers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        displayName
        sortOrder
        color
        slug
        displayMarketView
        portfolios {
          items {
            id
            name
            displayName
            slug
            status
            type
            pnlEndpoint
            slippageEndpoint
            positionsEndpoint
          }
          nextToken
        }

        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const managerBySlug = /* GraphQL */ `
  query ManagerBySlug(
    $slug: String!
    $name: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPortfolioManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    managerBySlug(
      slug: $slug
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        displayName
        sortOrder
        color
        displayMarketView
        slug
        portfolios {
          items {
            id
            name
            displayName
            description
            slug
            portfolioManager
            account {
              broker
              accountNum
              accountName
            }
            portfolioMeta {
              cashLevel
              impliedEquity
              leverageSetting
              leverage
              leverageToDisplay
              minDollarPositionValueToShow
              dailyReturnThreshold
            }
            status
            type
            autoReconTimestamp
            autoRecon {
              name
              instrumentID
              modelPosition
              modelChanges
              cumModelContractsTraded
              cumModelContractsTradedRounded
              accountPosition
              positionChanges
              cumAccountContractsTraded
              differenceChanges
              positionDifference
              cumContractsTradedDiff
            }
            lastSync
            pnlEndpoint
            historicalPnl {
              formats
            }
            slippageEndpoint
            slippage {
              formats
            }
            positionsEndpoint
            positions {
              formats
            }
            metaData {
              lastPNLDate
              todaysPNL
              todaysPNLValue
              monthToDate
              quarterToDate
              yearToDate
              cumulativeReturnCash
              exposure
              todays_drawdown
              cashLevel
              leverageSetting
              marginMaintenance
              marginMaintenancePortfolioValue
              sharpeRatio
              highWater
              total_cumulative_return_pct
              avg_daily_return_pct
              avg_calendar_daily_return_pct
              total_cumulative_slippage_pct
              avg_daily_slippage_pct
              avg_calendar_daily_slippage_pct
              total_number_of_pnl_days
              total_number_of_slippage_days
              total_number_of_positions
              total_number_of_commodities
            }
            health {
              position_differences
              traded_differences
              unofficial_numbers
              daily_return_warning
              max_position_by_portfolio
              max_position_by_position
            }
            milestones {
              title
              date
              color
            }
            excludeFromOverallTotals
            summaryType
            pnlType
            leverage
            leverageToDisplay
            minDollarPositionValueToShow
            cashLevel
            leverageSetting
            dailyReturnThreshold
            maxMarginPerPosition
            maxMarginPerPortfolio
            createdAt
            updatedAt
          }
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const syncPortfolio = /* GraphQL */ `
  mutation SyncPortfolio($portfolioId: ID!) {
    syncPortfolio(portfolioId: $portfolioId) {
      id
      name
      displayName
      slug
      portfolioManager
      description
      account {
        broker
        accountNum
        accountName
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      status
      type
      autoReconTimestamp
      lastSync
      pnlEndpoint
      slippageEndpoint
      positionsEndpoint
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      summaryType
      pnlType
      leverage
      leverageToDisplay
      minDollarPositionValueToShow
      cashLevel
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`
