import React, { useEffect, useState } from "react"
import { fetchUserAttributes, fetchAuthSession } from "aws-amplify/auth"
import getUserInfo from "./lib/getUserInfo"
import ReactGA from "react-ga4"
import { useAuthenticator } from "@aws-amplify/ui-react"

let AuthContext
const { Provider, Consumer } = (AuthContext = React.createContext())

const get_groups = (session) => {
  const {
    tokens: {
      accessToken: { payload },
    },
  } = session

  const groups = payload["cognito:groups"]
  return groups
}

const get_user_info = (user) => {
  const access = user["custom:access"]?.split(",") || []
  const slack_id = user["custom:slack_id"] || null
  const { email, email_verified } = user
  return { access, slack_id, email, email_verified }
}

const useUserMetaData = (user) => {
  const [attributes, setAttributes] = useState(null)
  useEffect(() => {
    async function fetchUserInfo() {
      const session = await fetchAuthSession()
      const attributes = await fetchUserAttributes()
      const attr = get_user_info(attributes)
      const groups = get_groups(session)
      setAttributes(getUserInfo({ groups, ...attr, ...user }))
    }
    fetchUserInfo()
  }, [user])

  return attributes
}

const useGAuser = (user_id) => {
  useEffect(() => {
    if (user_id) {
      // console.log(user_id)
      ReactGA.set({ user_id })
      // ReactGA.send("pageview")
    }
  }, [user_id])
}

const AuthProvider = ({ children }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const userMeta = useUserMetaData(user)

  useGAuser(userMeta?.userId)

  return (
    !!userMeta && (
      <Provider
        value={{
          user,
          userMeta,
          actions: { signOut },
        }}
      >
        {children}
      </Provider>
    )
  )
}

export { AuthContext, Consumer as AuthConsumer, AuthProvider }
