import { Notification, Text, useToaster } from "rsuite"

const headerTypes = {
  success: "Success!",
  warning: "Warning!",
  failed: "Failed!",
}

export const useNotify = () => {
  const toaster = useToaster()

  const notify = (messgage, type = "success", placement = "bottomEnd") => {
    toaster.push(
      <Notification type={type} header={headerTypes[type]}>
        <Text>{messgage}</Text>
      </Notification>,
      { placement: placement }
    )
  }

  return { notify }
}
