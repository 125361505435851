import { useMemo } from "react"

const auto_recon_fields = [
  "ticker",
  "expiry",
  "modelPosition",
  "accountPosition",
  "positionDifference",
  "cumModelContractsTraded",
  "cumAccountContractsTraded",
  "cumContractsTradedDiff",
]

const risk_fields = [
  "ticker",
  "expiry",
  "maintenance_margin_relative",
  "initial_margin",
  "margin_per_contract",
  "maintenance_margin",
]

const summaryFields = [
  "ticker",
  "relative_pnl",
  "absolute_pnl",
  "endQuantity",
  "maintenance_margin_relative",
  "margin_per_contract",
  "maintenance_margin",
]

const totals_fields = [
  "relative_pnl",
  "absolute_pnl",
  "maintenance_margin_relative",
  "maintenance_margin",
  "margin_per_contract",
]

const columnKeyMap = (config) => ({
  AutoRecon: auto_recon_fields,
  Risk: risk_fields,
  Summary: summaryFields,
  Positions: config.map((column) => column.field),
  All: config.map((column) => column.field),
  null: config.map((column) => column.field),
})

const useTradeServerColumns = (config, tradeServerMode) => {
  const all_columns = config.map((column) => ({
    field: column.field,
    label: column.abbr_label,
  }))
  const columnMap = useMemo(() => columnKeyMap(config), [config])

  const columnKeys = useMemo(
    () => columnMap[tradeServerMode] || [],
    [columnMap, tradeServerMode]
  )

  const columns = useMemo(
    () => config.filter((column) => columnKeys.includes(column.field)),
    [config, columnKeys]
  )

  return { columns, all_columns, totals_fields }
}

export default useTradeServerColumns

export const active_field = (mode) => {
  let field
  switch (mode) {
    case "Positions":
      field = "position_value"
      break
    case "Summary":
      field = "relative_pnl"
      break
    case "Risk":
      field = "maintenance_margin_relative"
      break
    default:
      field = "position_value"
      break
  }
  return field
}
