import { useContext } from "react"
import { AuthContext } from "./AuthContext"

export const auth_groups = {
  IS_QUANT: ({ userMeta }) => userMeta.isQuant || userMeta.isSuperAdmin,
  IS_SUPER_ADMIN: ({ userMeta }) => userMeta.isSuperAdmin,
  IS_ADMIN: ({ userMeta }) => userMeta.isAdmin || userMeta.isSuperAdmin,
  IS_PM: ({ userMeta }) => userMeta.isPortfolioManager || userMeta.isSuperAdmin,
  TRADE_SERVER_ACCESS: ({ userMeta }) =>
    userMeta.tradeServerAccess || userMeta.isSuperAdmin,
}

const useAuth = () => {
  const auth = useContext(AuthContext)
  return auth
}

export default useAuth
