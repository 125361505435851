import { Access, General, ProfileLayout } from "./Profile"
import ErrorPage from "../common/ErrorPage"

const profile_route = {
  path: "profile",
  element: <ProfileLayout />,
  errorElement: <ErrorPage />,
  children: [
    {
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <General />,
        },

        {
          path: "access",
          element: <Access />,
        },
      ],
    },
  ],
}

export default profile_route
