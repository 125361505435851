import React from "react"
import { Button, Drawer } from "rsuite"

const DrawerComp = ({ title, children, label, size = "sm" }) => {
  const [isOpen, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <>
      <Button onClick={handleOpen} appearance="subtle">
        {label}
      </Button>

      <Drawer
        placement="right"
        backdrop="static"
        open={isOpen}
        size={size}
        onClose={handleClose}
      >
        <Drawer.Header>
          <Drawer.Title>{title}</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={handleClose}>Cancel</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>{children({ handleClose })}</Drawer.Body>
      </Drawer>
    </>
  )
}

export default DrawerComp
