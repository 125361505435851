import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { List, Text } from "rsuite"
import ManagerItem from "./ManagerItem"

import styles from "./Managers.module.css"
import { useNotify } from "../../../hooks/useNotify"
import { useDialog } from "../../../hooks/useDialog"
import ConfirmDialog from "../../../components/common/ConfirmDialog"

const ManagersList = ({
  managers,
  isSortingEnabled,
  isFromModal,
  setModalSelectedManager,
  modalSelectedManager,
}) => {
  const { onDialogClose, onDialogOpen, isOpen } = useDialog()
  const { notify } = useNotify()

  const [sortIndex, setSortIndex] = useState({
    oldIndex: 0,
    newIndex: 0,
  })

  const mutation = useMutation({
    mutationKey: ["updateManager"],
  })

  const handleSortEnd = ({ newIndex, oldIndex }) => {
    if (oldIndex === newIndex) return
    setSortIndex({
      oldIndex,
      newIndex,
    })
    onDialogOpen()
  }

  const onConfirm = () => {
    mutation.mutate(
      {
        id: managers[sortIndex.oldIndex].id,
        sortOrder: sortIndex.newIndex,
      },
      {
        onSuccess: () => notify("Changes have been saved!"),
      }
    )
    mutation.mutate(
      {
        id: managers[sortIndex.newIndex].id,
        sortOrder: sortIndex.oldIndex,
      },
      {
        onSuccess: () => notify("Changes have been saved!"),
      }
    )
    onDialogClose()
  }

  return (
    <div
      className={
        isFromModal
          ? styles.modalManagersItemsContainer
          : styles.managersItemsContainer
      }
    >
      <List sortable={isSortingEnabled} onSortEnd={handleSortEnd}>
        {managers?.map((manager, index) => (
          <ManagerItem
            key={index}
            manager={manager}
            index={index}
            isFromModal={isFromModal}
            setModalSelectedManager={setModalSelectedManager}
            modalSelectedManager={modalSelectedManager}
          />
        ))}
      </List>
      <ConfirmDialog
        onClose={onDialogClose}
        onConfirm={onConfirm}
        isOpen={isOpen}
        isLoading={mutation.isLoading}
      >
        <Text>Are you sure you want save these changes?</Text>
      </ConfirmDialog>
    </div>
  )
}

export default ManagersList
