import { getPortfolio } from "../../../routes/secure_loaders"
import { useQuery } from "@tanstack/react-query"
import useAuth from "../../../components/AmplifyAuth/useAuth"

const REFETCH_INTERVAL = 1000 * 60 * 3

export const portfolioQuery = (portfolioSlug, managerSlug) => ({
  queryKey: [managerSlug, portfolioSlug],
  queryFn: async () => await getPortfolio(managerSlug, portfolioSlug),
  enabled: !!portfolioSlug && !!managerSlug,
  refetchInterval: REFETCH_INTERVAL,
})

export const usePortfolio = (managerSlug, portfolioSlug) => {
  const auth = useAuth()

  return useQuery({
    ...portfolioQuery(portfolioSlug, managerSlug),
    select: (portfolio) => {
      return {
        account: portfolio.account,
        type: portfolio.type,
        portfolioManager: portfolio.portfolioManager,
        metaData: portfolio.metaData,
        id: portfolio.id,
        name: portfolio.name,
        displayName: portfolio.displayName,
        slug: portfolio.slug,
        status: portfolio.status,
        description: portfolio.description || "",
        leverageToDisplay: portfolio.leverageToDisplay,
        leverage: portfolio.leverage || 1,
        minDollarPositionValueToShow:
          portfolio.minDollarPositionValueToShow || 25,
        dailyReturnThreshold: portfolio.dailyReturnThreshold,
        maxMarginPerPosition: portfolio.maxMarginPerPosition,
        maxMarginPerPortfolio: portfolio.maxMarginPerPortfolio,
        summaryType: portfolio.summaryType || "DAILY",
        pnlEndpoint: portfolio.pnlEndpoint || "",
        positionsEndpoint: portfolio.positionsEndpoint || "",
        slippageEndpoint: portfolio.slippageEndpoint || "",
        // ...portfolio,
      }
    },
    enabled: !!auth.userMeta,
  })
}
