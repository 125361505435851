import { formats } from "../../../../../lib/utils"
import {
  cell_format,
  cell_raw_format,
} from "../../../../../components/common/DataTable/utils"

const slippage_config = [
  {
    label: "Period",
    abbr_label: "P",
    field: "period",
    sortable: true,
    formatFn: cell_raw_format(formats.date("DD-MMM-YY")),
    fixed: true,
    width: 90,
  },
  {
    label: "Daily Slippage",
    abbr_label: "DS",
    field: "daily_slippage_pct",
    formatFn: cell_format(formats.pct),
    width: 60,
  },
  {
    label: "Cumulative Slippage",
    abbr_label: "CS",
    field: "rebased",
    formatFn: cell_format(formats.pct),
    width: 60,
  },
  {
    label: "Cumulative Commission",
    abbr_label: "CC",
    field: "cumulative_commission_pct",
    formatFn: cell_format(formats.pct),
    width: 60,
  },
  {
    label: "Ann Slip 3M",
    abbr_label: "AS 3M",
    field: "ann_slippage_3_month_avg_pct",
    formatFn: cell_format(formats.pct),
    width: 60,
  },
  {
    label: "Ann Comm 3M",
    abbr_label: "AC 3M",
    field: "ann_commission_3_month_avg_pct",
    formatFn: cell_format(formats.pct),
    width: 60,
  },
]

export default slippage_config
