import React from "react"
import DateFormat from "../../../../components/common/DateFormat"
import moment from "moment"
import { Button, Stack } from "rsuite"
import CloudReflashIcon from "@rsuite/icons/CloudReflash"
import { Icon } from "@rsuite/icons"
import useAuth from "../../../../components/AmplifyAuth/useAuth"
import usePortfolioSync from "../hooks/usePortfolioSync"

const SyncButton = ({ port, showFromNow, isLoading }) => {
  const { userMeta } = useAuth()
  const { isPortfolioLoading, errorMessage, sync } = usePortfolioSync(port.id)
  const fromNow = moment().diff(moment(port.lastSync), "minutes")

  return (
    <Button
      onClick={sync}
      loading={isPortfolioLoading || isLoading}
      block
      disabled={!userMeta?.isAdmin}
      appearance="subtle"
      style={{ padding: 3, margin: 0 }}
    >
      {port.lastSync ? (
        <div>
          <Stack justifyContent="space-between" alignItems="center">
            {!!errorMessage ? (
              <p color="orange">{errorMessage}</p>
            ) : (
              <Stack spacing={5} alignItems="center">
                <Icon as={CloudReflashIcon} />
                <DateFormat date={port.lastSync} initialFormat={null} />
              </Stack>
            )}
            {showFromNow && <small>{fromNow.toLocaleString()} mins ago</small>}
          </Stack>
        </div>
      ) : (
        <span>Never Sync'd</span>
      )}
    </Button>
  )
}

export default SyncButton
