import { useState } from "react"

export const useDialog = () => {
  const [isOpen, setOpen] = useState(false)
  const onDialogOpen = () => {
    setOpen(true)
  }
  const onDialogClose = () => {
    setOpen(false)
  }

  return { onDialogClose, onDialogOpen, isOpen }
}
