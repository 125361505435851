import { useState } from "react"

import { Col, Row } from "rsuite"

import PortfolioItem from "./PortfolioItem"
import styles from "./Managers.module.css"

const PortfolioList = ({ portfolios = [] }) => {
  const [filteredPortfolios] = useState(portfolios || [])

  return (
    <>
      <Row className={styles.portfolioItemsContainer} gutter={10}>
        {filteredPortfolios?.map((port, index) => (
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            style={{ marginBottom: "10px" }}
            key={index}
          >
            <PortfolioItem portfolio={port} index={index} key={index} />
          </Col>
        ))}
      </Row>
    </>
  )
}

export default PortfolioList
