import React, { useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
  Row,
  Col,
  IconButton,
  Stack,
  Text,
  Heading,
  Container,
  Header,
  Content,
  Divider,
  Drawer,
  Modal,
  Whisper,
  Popover,
  Loader,
} from "rsuite"
import FunnelIcon from "@rsuite/icons/Funnel"
import GearIcon from "@rsuite/icons/Gear"
import CloseOutlineIcon from "@rsuite/icons/CloseOutline"
import AddOutlineIcon from "@rsuite/icons/AddOutline"
import ArrowLeftLineIcon from "@rsuite/icons/ArrowLeftLine"
import MenuIcon from "@rsuite/icons/Menu"
import { useManagers } from "./lib/useManager"
import PMPortfolioDetail from "./components/PMPortfolioDetail"
import PortfolioViewItem from "./components/PortfolioViewItem"

import { useFilterPortfolios } from "../../hooks/useFilterPortfolios"
import { useSelectedPortfolio } from "../../hooks/useSelectedPortfolio"
import ManagerForm from "./components/ManagerForm"
import FormModal from "../../components/common/FormModal"
import useAuth from "../../components/AmplifyAuth/useAuth"
import CreateNewPortfolio from "../Portfolios/Settings/controls/CreateNewPortfolio"

import styles from "./components/Managers.module.css"
import ChangeListIcon from "@rsuite/icons/ChangeList"
import CardContainer from "../../components/common/CardContainer"
import StatusFilters from "./components/StatusFilters"

const ManagerView = () => {
  const { managerSlug } = useParams()
  const [searchParams] = useSearchParams()
  const { userMeta } = useAuth()
  const { data: manager, isLoading } = useManagers(managerSlug)

  const query = searchParams.get("q")
  const navigate = useNavigate()

  const { portfolios } = manager || {}

  const [search] = useState("")

  const [filterByIconArray, setFilterByIconArray] = useState([])
  const [isPortfolioDrawerOpen, setIsPortfolioDrawerOpen] = useState(false)
  const [isAddPortfolioOpen, setIsAddPortfolioOpen] = useState(false)
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false)
  const { filteredPortfolios } = useFilterPortfolios(
    portfolios,
    search,
    filterByIconArray
  )
  const { selectedPortfolio, changeSelectedPortfolio } = useSelectedPortfolio(
    portfolios,
    query
  )

  const onAddPortfolioClick = () => {
    setIsAddPortfolioOpen(true)
  }

  const onBack = () => {
    navigate("/quant/admin")
  }

  return (
    <Container className={styles.managerContainer}>
      <Header>
        <Row style={{ paddingTop: 0 }}>
          <Col xs={24}>
            <div className={styles.managerPortfolioSettingsMobile}>
              <Stack
                justifyContent="space-between"
                style={{ padding: "20px 0" }}
              >
                <IconButton
                  circle
                  icon={<ArrowLeftLineIcon />}
                  appearance="default"
                  onClick={onBack}
                />
                <IconButton
                  circle
                  icon={<MenuIcon />}
                  appearance="default"
                  onClick={() => setIsNavDrawerOpen(true)}
                />
              </Stack>
            </div>
            <div className={styles.managerPortfolioSettingsDesktop}>
              <Stack
                alignItems="center"
                justifyContent="space-between"
                style={{ padding: "0.5rem" }}
              >
                <Stack spacing={30}>
                  <Stack alignItems="center" spacing={6}>
                    <Stack>
                      <IconButton
                        circle
                        icon={<ArrowLeftLineIcon />}
                        appearance="default"
                        onClick={onBack}
                        size="sm"
                      />
                    </Stack>
                    <Divider vertical />
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Heading level={4}>{manager?.name}</Heading>
                      <Text size="sm" style={{ color: "#828387" }}>
                        Manage {manager?.name} setting and portfolios
                      </Text>
                    </Stack>
                  </Stack>
                  <FormModal
                    icon={<GearIcon />}
                    size="md"
                    headerText="Manager Settings"
                  >
                    {({ handleClose }) => (
                      <ManagerForm
                        manager={manager}
                        isAdmin={userMeta?.isAdmin}
                        handleClose={handleClose}
                      />
                    )}
                  </FormModal>
                </Stack>
                <Stack alignItems="flex-end" direction="column">
                  <Text size="md">{userMeta?.email}</Text>
                  <Text size="sm" style={{ color: "#828387" }}>
                    {userMeta?.groups.join(", ")}
                  </Text>
                </Stack>
              </Stack>
            </div>
          </Col>
        </Row>
      </Header>
      {isLoading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          style={{ padding: "30px" }}
        >
          <Loader content="Loading Portfolio Data..." vertical size="md" />
        </Stack>
      ) : (
        <Content>
          <Row gutter={20}>
            <Col xs={8} className={styles.managerPortfolioList}>
              <Stack
                alignItems="center"
                justifyContent="space-between"
                spacing={5}
                style={{ margin: "10px 0 10px 0", paddingRight: "10px" }}
              >
                <div>
                  <Heading level={6} style={{ color: "orange" }}>
                    Portfolios
                  </Heading>
                  <Text size="sm" style={{ color: "#828387" }}>
                    List of {manager?.name} portfolios
                  </Text>
                </div>

                <Stack spacing={5}>
                  {isAddPortfolioOpen ? (
                    <>
                      <Stack.Item grow={1}>
                        <CreateNewPortfolio portfolioManager={manager?.name} />
                      </Stack.Item>
                      <IconButton
                        onClick={() => setIsAddPortfolioOpen(false)}
                        circle
                        icon={<CloseOutlineIcon />}
                        appearance="default"
                      />
                    </>
                  ) : (
                    <>
                      <div className={styles.statusFilterFunnel}>
                        <Whisper
                          trigger="click"
                          placement="bottomStart"
                          speaker={
                            <Popover>
                              <StatusFilters
                                filterByIconArray={filterByIconArray}
                                setFilterByIconArray={setFilterByIconArray}
                              />
                            </Popover>
                          }
                        >
                          <IconButton
                            circle
                            icon={<FunnelIcon />}
                            appearance="default"
                          />
                        </Whisper>
                      </div>
                      <IconButton
                        onClick={onAddPortfolioClick}
                        circle
                        icon={<AddOutlineIcon />}
                        appearance="default"
                      />
                    </>
                  )}
                </Stack>
              </Stack>
              <CardContainer
                className={styles.statusFilter}
                style={{ marginBottom: "10px" }}
              >
                <StatusFilters
                  filterByIconArray={filterByIconArray}
                  setFilterByIconArray={setFilterByIconArray}
                />
              </CardContainer>
              {portfolios?.length === 0 ? (
                <Stack
                  justifyContent="center"
                  direction="column"
                  style={{ marginTop: "50px" }}
                  spacing={10}
                >
                  <Text size="lg">Create a portfolio</Text>
                  <IconButton
                    onClick={onAddPortfolioClick}
                    circle
                    icon={<AddOutlineIcon />}
                    appearance="default"
                  />
                </Stack>
              ) : (
                <Row
                  className={styles.portfolioViewItemsContainer}
                  style={{ paddingRight: "10px" }}
                >
                  {filteredPortfolios?.map((portfolio, index) => (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={12}
                      style={{ marginBottom: "10px" }}
                      key={index}
                    >
                      <PortfolioViewItem
                        portfolio={portfolio}
                        key={index}
                        selectedPortfolio={selectedPortfolio}
                        setSelectedPortfolio={changeSelectedPortfolio}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </Col>

            <Col xs={24} md={24} lg={16} xl={16}>
              <Stack
                justifyContent="space-between"
                style={{ margin: "10px 0 20px 0" }}
              >
                <div>
                  <Heading
                    className={styles.viewDisplayName}
                    level={6}
                    style={{ color: "orange" }}
                  >
                    {selectedPortfolio?.displayName}
                  </Heading>
                  {selectedPortfolio?.displayName && (
                    <Text size="sm" style={{ color: "#828387" }}>
                      Portfolio details and settings
                    </Text>
                  )}
                </div>
                <IconButton
                  onClick={() => setIsPortfolioDrawerOpen(true)}
                  circle
                  icon={<ChangeListIcon />}
                  appearance="default"
                  className={styles.selectPortfolioButton}
                />
              </Stack>

              <PMPortfolioDetail
                key={manager?.id}
                manager={manager}
                isLoading={isLoading}
                selectedPortfolio={selectedPortfolio}
                setSelectedPortfolio={changeSelectedPortfolio}
              />
            </Col>
          </Row>
        </Content>
      )}
      <Drawer
        size="xs"
        placement="right"
        open={isNavDrawerOpen}
        onClose={() => setIsNavDrawerOpen(false)}
      >
        <Drawer.Header>
          <Drawer.Title>
            <Heading level={4}>{manager?.name}</Heading>
            <Text size="sm" style={{ color: "#828387" }}>
              Manage {manager?.name} setting and portfolios
            </Text>
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body style={{ padding: "20px 50px 50px 50px" }}>
          <Stack spacing={30}>
            <div>
              <FormModal
                buttonText="Settings"
                size="md"
                headerText="Manager Settings"
              >
                {({ handleClose }) => (
                  <ManagerForm
                    manager={manager}
                    isAdmin={userMeta?.isAdmin}
                    handleClose={handleClose}
                  />
                )}
              </FormModal>
            </div>
          </Stack>
          <Stack
            alignItems="flex-end"
            direction="column"
            justifyContent="flex-end"
            style={{ height: "100%" }}
          >
            <Text size="md">{userMeta?.email}</Text>
            <Text size="sm" style={{ color: "#828387" }}>
              {userMeta?.groups.join(", ")}
            </Text>
          </Stack>
        </Drawer.Body>
      </Drawer>

      <Modal
        open={isPortfolioDrawerOpen}
        onClose={() => setIsPortfolioDrawerOpen(false)}
        size="full"
        containerClassName={styles.portfoliosModalContainer}
      >
        <Modal.Header>
          <Modal.Title>{manager?.name} portfolios </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={24}>
              <CardContainer style={{ margin: "20px 0" }}>
                <StatusFilters
                  filterByIconArray={filterByIconArray}
                  setFilterByIconArray={setFilterByIconArray}
                />
              </CardContainer>
            </Col>
          </Row>
          <Row gutter={10}>
            {filteredPortfolios?.map((portfolio, index) => (
              <Col
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                style={{ marginBottom: "3px" }}
                key={index}
              >
                <PortfolioViewItem
                  portfolio={portfolio}
                  key={index}
                  selectedPortfolio={selectedPortfolio}
                  setSelectedPortfolio={changeSelectedPortfolio}
                  setIsPortfolioDrawerOpen={setIsPortfolioDrawerOpen}
                />
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default ManagerView
