import React from "react"
import { remove } from "aws-amplify/storage"
import { useMutation } from "@tanstack/react-query"
import { Button } from "rsuite"

const clearS3Portfolio = (element) =>
  remove(element, {
    level: "public",
  })

const DeletePortfolioBtn = ({ portfolio }) => {
  const mutation = useMutation({
    mutationKey: ["deletePortfolio"],
  })
  const { id, displayName, portfolioManager, slug, lastSync } = portfolio
  const handleDelete = () => {
    mutation.mutate(id, {
      onSuccess: () => {
        if (lastSync) {
          clearS3Portfolio(`${portfolioManager}/${slug}/metaData.json`)
          clearS3Portfolio(`${portfolioManager}/${slug}/positions.json`)
          clearS3Portfolio(`${portfolioManager}/${slug}/slippage.json`)
          clearS3Portfolio(`${portfolioManager}/${slug}/historical.json`)
        }
      },
    })
  }
  return (
    <Button
      appearance="ghost"
      color="red"
      onClick={() => {
        const confirm = window.confirm(
          `Do you really want to remove ${displayName}?`
        )
        if (confirm === true) {
          handleDelete()
        }
      }}
    >
      Remove
    </Button>
  )
}

export default DeletePortfolioBtn
