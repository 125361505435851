import React from "react"
import { Stack } from "rsuite"
import PortStatus from "../../Portfolios/components/PortStatus"

const ManagerStatus = ({ portfolio }) => {
  return (
    <Stack spacing={3}>
      <Stack.Item style={{ display: "flex", alignItems: "center" }}>
        <PortStatus status={portfolio.status} pId={portfolio.id} showTooltip />
      </Stack.Item>
      <Stack.Item style={{ display: "flex", alignItems: "center" }}>
        <PortStatus status={portfolio.type} showTooltip />
      </Stack.Item>
    </Stack>
  )
}

export default ManagerStatus
