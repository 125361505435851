import { Dashboard } from "../../Dashboard/Dashboard"
import portfolios_route from "../../Portfolios/portfolios_route"
import ManagerView from "../ManagerView"
import ManagersAdmin from "../ManagersAdmin"
import { Outlet } from "react-router-dom"

const managers_route = {
  path: "quant",
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <Dashboard />,
    },
    {
      path: "admin",
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <ManagersAdmin />,
        },
        {
          path: ":managerSlug",
          element: <ManagerView />,
        },
      ],
    },
    {
      path: ":managerSlug",
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        portfolios_route,
      ],
    },
  ],
}

export default managers_route
