import React from "react"
import { Button } from "rsuite"

const SaveToClipboard = ({ data, label }) => {
  const save_to_clipboard = (value) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(value)
    }
  }
  return (
    <Button
      appearance="ghost"
      onClick={() => save_to_clipboard(data)}
      size="sm"
      disabled={!navigator.clipboard}
    >
      {label}
    </Button>
  )
}

export default SaveToClipboard
