import { useTheme } from "@aws-amplify/ui-react"
import { Icon } from "@rsuite/icons"
import React from "react"
import {
  RiParkingBoxFill,
  RiAlertFill,
  RiBlazeFill,
  RiPercentLine,
  RiBookFill,
} from "react-icons/ri"
import { Link } from "react-router-dom"
import { Stack } from "rsuite"

export const getPortfolioHealth = (port) => port

const PortfolioHealth = ({ health, slug, style, metaData }) => {
  // console.log(health.traded_differences)
  const { tokens } = useTheme()
  if (!health) return null
  return (
    <Stack spacing={0} style={style}>
      {!!health.daily_return_warning && (
        <Link to={slug + "/historical"}>
          <Icon as={RiPercentLine} color={tokens.colors.metagora.red} />
        </Link>
      )}
      {!!health.position_differences &&
        metaData?.total_number_of_positions > 0 && (
          <Link to={slug + "/tradeserver?mode=AutoRecon"}>
            <Icon as={RiParkingBoxFill} color={tokens.colors.metagora.red} />
          </Link>
        )}
      {!!health.traded_differences &&
        !health.position_differences &&
        metaData?.total_number_of_positions > 0 && (
          <Link to={slug + "/tradeserver?mode=AutoRecon"}>
            <Icon as={RiBookFill} color={tokens.colors.yellow[80]} />
          </Link>
        )}
      {!!health.unofficial_numbers && (
        <Link to={slug + "/historical"}>
          <Icon
            as={RiAlertFill}
            color={tokens.colors.metagora.unofficial_blue_health}
          />
        </Link>
      )}
      {(!!health.max_position_by_portfolio ||
        !!health.max_position_by_position) && (
        <Link to={slug + "/tradeserver?mode=All"}>
          <Icon as={RiBlazeFill} color={tokens.colors.metagora.red} />
        </Link>
      )}
    </Stack>
  )
}

export default PortfolioHealth
