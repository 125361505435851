import React from "react"
import { useControls } from "../../lib/controls/ControlContext"
import { Stack } from "rsuite"
import { abbreviate_number } from "../../lib/utils"
import { useDashboard } from "./lib/useDashboard"

const Totals = () => {
  const { data } = useDashboard()
  const {
    totals: { overallManagerTotalProduction, overallManagerTotalExposure },
  } = data || { totals: {} }
  const { isCollapsed, showManagerTotals } = useControls()
  return (
    <Stack
      spacing={0}
      direction="column"
      alignItems="flex-start"
      onClick={showManagerTotals.toggle}
      style={{ lineHeight: 0, padding: 10 }}
    >
      {!!overallManagerTotalProduction && (
        <Stack spacing={5}>
          <h6
            style={{
              fontWeight: "bold",
              fontSize: ".9em",
              padding: 0,
              margin: 0,
              lineHeight: 1,
            }}
          >
            &Sigma; AUM
          </h6>
          {!showManagerTotals.isDefault
            ? overallManagerTotalProduction
              ? !isCollapsed
                ? abbreviate_number(overallManagerTotalProduction, 2)
                : "$" + overallManagerTotalProduction.toLocaleString()
              : null
            : "XXX"}
        </Stack>
      )}
      {!!overallManagerTotalExposure && (
        <Stack spacing={5}>
          <h6
            style={{
              fontWeight: "bold",
              fontSize: ".9em",
              padding: 0,
              margin: 0,
            }}
          >
            &Sigma; EXP
          </h6>
          {!showManagerTotals.isDefault
            ? overallManagerTotalExposure
              ? !isCollapsed
                ? abbreviate_number(overallManagerTotalExposure, 2)
                : "$" + overallManagerTotalExposure.toLocaleString()
              : null
            : "XXX"}
        </Stack>
      )}
    </Stack>
  )
}

export default Totals
