import { useLocalStorage } from "../useLocalStorage"

const useDashboardControls = () => {
  const dashboardConfig = useLocalStorage("dashboardConfig", {
    muted: [],
    showMuted: false,
    editMode: false,
  })

  return {
    dashboardConfig,
  }
}
export default useDashboardControls
