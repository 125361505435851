import { RouterProvider } from "react-router-dom"
import { ThemeProvider } from "@aws-amplify/ui-react"
import { CustomProvider } from "rsuite"

import { metagoraTheme } from "./theme"
import router from "./routes/router"
import { ControlProvider } from "./lib/controls/ControlContext"

function App() {
  return (
    <CustomProvider theme="dark">
      <ControlProvider>
        <ThemeProvider theme={metagoraTheme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </ControlProvider>
    </CustomProvider>
  )
}

export default App
