import React from "react"
import { useMutation } from "@tanstack/react-query"
import {
  Button,
  ButtonToolbar,
  Form,
  IconButton,
  Panel,
  Schema,
  SelectPicker,
  Stack,
} from "rsuite"
import SaveIcon from "@rsuite/icons/legacy/Save"
import CustomDatePicker from "../components/CustomDatePicker"
import { formatQuery } from "./lib/utils"
import CustomCheckbox from "../components/CustomCheckbox"
import EndpointInputGroup from "../components/EndpointInputGroup"

const elementSettingsModel = Schema.Model({
  summaryType: Schema.Types.StringType(),
  pnlEndpoint: Schema.Types.StringType(),
  positionsEndpoint: Schema.Types.StringType(),
  slippageEndpoint: Schema.Types.StringType(),
})

const HistoricalPNLEndpoint = ({ endpoint, setFormValue, query }) => {
  const sinceMatch = endpoint.match(/&since=([0-9-]+)/)
  const untilMatch = endpoint.match(/&until=([0-9-]+)/)
  const isOfficialMatch = endpoint.match(/&is_official=(true)/)
  return (
    <Panel header="Historical P&L" bordered>
      <Stack spacing={10} style={{ paddingTop: 10 }}>
        <CustomDatePicker
          label="Since"
          match={sinceMatch}
          setFormValue={setFormValue}
          keyName="pnlEndpoint"
          queryName="since"
        />
        <CustomDatePicker
          label="Until"
          match={untilMatch}
          setFormValue={setFormValue}
          keyName="pnlEndpoint"
          queryName="until"
        />
        <CustomCheckbox
          queryName="is_official"
          match={isOfficialMatch}
          setFormValue={setFormValue}
          keyName="pnlEndpoint"
          query="&is_official=true"
          label="Official Only"
        />
      </Stack>
      <EndpointInputGroup
        label="Historical P&L"
        endpoint={endpoint}
        setFormValue={setFormValue}
        keyName="pnlEndpoint"
        query={query}
      />
    </Panel>
  )
}

const SlippageEndpoint = ({ endpoint, setFormValue, query }) => {
  const sinceMatch = endpoint.match(/&since=([0-9-]+)/)
  const untilMatch = endpoint.match(/&until=([0-9-]+)/)
  const isOfficialMatch = endpoint.match(/&is_official=(true)/)
  const formatRelativeMatch = endpoint.match(/&format=relative/)

  return (
    <Panel header="Slippage" bordered>
      <Stack spacing={10} style={{ paddingTop: 10 }}>
        <CustomDatePicker
          label="Since"
          match={sinceMatch}
          setFormValue={setFormValue}
          keyName="slippageEndpoint"
          queryName="since"
        />
        <CustomDatePicker
          label="Until"
          match={untilMatch}
          setFormValue={setFormValue}
          keyName="slippageEndpoint"
          queryName="until"
        />
        <CustomCheckbox
          queryName="format"
          match={formatRelativeMatch}
          setFormValue={setFormValue}
          keyName="slippageEndpoint"
          query="&format=relative"
          label="Format: relative"
        />
        <CustomCheckbox
          queryName="is_official"
          match={isOfficialMatch}
          setFormValue={setFormValue}
          keyName="slippageEndpoint"
          query="&is_official=true"
          label="Official Only"
        />
      </Stack>
      <EndpointInputGroup
        label="Slippage"
        endpoint={endpoint}
        setFormValue={setFormValue}
        keyName="slippageEndpoint"
        query={query}
        rows={3}
      />
    </Panel>
  )
}
const ModelDataSettings = ({ port, handleClose = () => {} }) => {
  const formRef = React.useRef()

  const [formValue, setFormValue] = React.useState({
    id: port.id,
    summaryType: port.summaryType || "DAILY",
    pnlEndpoint: port.pnlEndpoint || "",
    positionsEndpoint: port.positionsEndpoint || "",
    slippageEndpoint: port.slippageEndpoint || "",
  })
  const mutation = useMutation({
    mutationKey: ["updatePortfolio"],
  })
  const hasAnAccount = !!port.account && port.account.length > 0
  if (!hasAnAccount) return <div>No Account Set</div>

  const isIB = !!port?.account?.find(
    (acct) => acct.broker === "interactivebrokers"
  )
  const isBinance = !!port.account?.find((acct) => acct.broker === "binance")

  const endpointQuery = (prefix, isSlippage = false) =>
    formatQuery({
      pathPrefix: prefix,
      accts: port.account,
      isBinance,
      isSlippage,
    })

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      return
    }
    mutation.mutate(formValue, {
      onSuccess: handleClose,
    })
  }
  return (
    <Form
      ref={formRef}
      model={elementSettingsModel}
      formValue={formValue}
      onChange={setFormValue}
      fluid
    >
      <Form.Group controlId="summaryType">
        <Form.Control
          label="Data Frequency"
          name="summaryType"
          accepter={SelectPicker}
          data={["DAILY", "WEEKLY"].map((opt) => ({
            key: opt,
            value: opt,
            label: opt.split("_").join(" "),
          }))}
        />
      </Form.Group>
      <HistoricalPNLEndpoint
        endpoint={formValue.pnlEndpoint}
        setFormValue={setFormValue}
        query={endpointQuery(`/v2/top_pnl`)}
      />
      <Panel header="Positions" bordered>
        <EndpointInputGroup
          label="Positions"
          endpoint={formValue.positionsEndpoint}
          setFormValue={setFormValue}
          keyName="positionsEndpoint"
          query={endpointQuery(`/v2/positions`)}
        />
      </Panel>
      {isIB && (
        <SlippageEndpoint
          endpoint={formValue.slippageEndpoint}
          setFormValue={setFormValue}
          query={endpointQuery(`/v3/top_pnl`, true)}
        />
      )}

      <ButtonToolbar>
        <IconButton
          icon={<SaveIcon />}
          appearance="primary"
          onClick={handleSubmit}
          loading={mutation.isLoading}
        />
        <Button onClick={handleClose}>Close</Button>
      </ButtonToolbar>
    </Form>
  )
}

export default ModelDataSettings
