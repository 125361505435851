import * as gqlMut from "../graphql/mutations"
import * as customGqlMut from "./graphql"
import { GraphQL_API } from "./secure_loaders"

export const updatePortfolioManager = (input) =>
  GraphQL_API(gqlMut.updatePortfolioManager, { input })
    .then((d) => d.data.updatePortfolioManager)
    .catch((e) => {
      console.log(e)
      throw e.errors[0]
    })

export const createPortfolioManager = (input) =>
  GraphQL_API(gqlMut.createPortfolioManager, { input })
    .then((d) => d.data.createPortfolioManager)
    .catch((e) => {
      console.log(e)
      throw e.errors[0]
    })

export const deletePortfolioManager = (id) =>
  GraphQL_API(gqlMut.deletePortfolioManager, { input: { id } })
    .then((d) => d.data.deletePortfolioManager)
    .catch((e) => {
      throw e.errors[0]
    })

// ************ Portfolio ******************

export const syncPortfolio = (portfolioId) => {
  return GraphQL_API(customGqlMut.syncPortfolio, { portfolioId })
    .then((d) => d.data.syncPortfolio)
    .catch((e) => {
      console.log(e)
      throw e.errors[0]
    })
}

export const updatePortfolio = (input) =>
  GraphQL_API(gqlMut.updatePortfolio, { input })
    .then((d) => d.data.updatePortfolio)
    .catch((e) => {
      console.log(e)
      throw e.errors[0]
    })

export const createPortfolio = (input) =>
  GraphQL_API(gqlMut.createPortfolio, { input })
    .then((d) => d.data.createPortfolio)
    .catch((e) => {
      console.log(e)
      throw e.errors[0]
    })

export const deletePortfolio = (id) =>
  GraphQL_API(gqlMut.deletePortfolio, { input: { id } })
    .then((d) => d.data.deletePortfolio)
    .catch((e) => {
      throw e.errors[0]
    })
