import React from "react"
import PortStatus, { portTypes, portStatues } from "../../components/PortStatus"
import {
  Button,
  ButtonToolbar,
  Form,
  Input,
  Message,
  Schema,
  SelectPicker,
  Stack,
  toaster,
} from "rsuite"
import { useMutation } from "@tanstack/react-query"

const metaModel = Schema.Model({
  name: Schema.Types.StringType().isRequired("This field is required"),
  displayName: Schema.Types.StringType(),
  slug: Schema.Types.StringType().pattern(/^\S*$/, "Can not contain spaces"),
  description: Schema.Types.StringType(),
  type: Schema.Types.StringType()
    .isOneOf(portTypes, "Must be a real type")
    .isRequired("This field is required"),
  status: Schema.Types.StringType()
    .isOneOf(portStatues, "Must be a real status")
    .isRequired("This field is required"),
})

const TextArea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
))

const MetaDataSettings = ({ port, handleClose }) => {
  const formRef = React.useRef()
  const mutation = useMutation({
    mutationKey: ["updatePortfolio"],
  })
  const [formError, setFormError] = React.useState({})
  const [formValue, setFormValue] = React.useState({
    id: port.id,
    name: port.name,
    displayName: port.displayName,
    slug: port.slug,
    type: port.type,
    status: port.status,
    description: port.description || "",
  })
  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      toaster.push(<Message type="error">Form Error</Message>)
      return
    }
    mutation.mutate(formValue, {
      onSuccess: handleClose,
    })
  }

  return (
    <Form
      ref={formRef}
      model={metaModel}
      formValue={formValue}
      onChange={setFormValue}
      onCheck={setFormError}
    >
      <Stack spacing={5}>
        <Form.Group controlId="type">
          <Form.Control
            name="type"
            label={
              <span>
                <PortStatus status={formValue.type} />
                {` `}
                <span>Type</span>
              </span>
            }
            accepter={SelectPicker}
            data={portTypes.map((opt) => ({
              label: opt,
              value: opt,
            }))}
          />
        </Form.Group>
        <Form.Group controlId="status">
          <Form.Control
            label={
              <span>
                <PortStatus status={formValue.status} />
                {` `}
                <span>Status</span>
              </span>
            }
            name="status"
            accepter={SelectPicker}
            data={portStatues.map((opt) => ({
              label: opt,
              value: opt,
            }))}
          />
        </Form.Group>
      </Stack>
      <Form.Group controlId="name">
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control name="name" />
        <Form.HelpText>Only used internally</Form.HelpText>
      </Form.Group>
      <Form.Group controlId="displayName">
        <Form.ControlLabel>Display Name</Form.ControlLabel>
        <Form.Control name="displayName" />
      </Form.Group>
      <Form.Group controlId="slug">
        <Form.ControlLabel>Slug</Form.ControlLabel>
        <Form.Control name="slug" errorMessage={formError.slug} />
        <Form.HelpText>
          Used for the URL and for retrieving a Portfolio
        </Form.HelpText>
      </Form.Group>
      <Form.Group controlId="description">
        <Form.ControlLabel>Description</Form.ControlLabel>
        <Form.Control
          name="description"
          label="Description"
          accepter={TextArea}
          rows={10}
        />
      </Form.Group>

      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  )
}

export default MetaDataSettings
