import React from "react"
import { Button, Modal, Panel, Stack } from "rsuite"
import DateFormat from "../../../components/common/DateFormat"
import InfoOutlineIcon from "@rsuite/icons/InfoOutline"
import { Icon } from "@rsuite/icons"
import CopyIcon from "@rsuite/icons/Copy"
import CloudReflashIcon from "@rsuite/icons/CloudReflash"
import { useTheme } from "@aws-amplify/ui-react"
import PortStatus from "../components/PortStatus"

const PortfolioExtraInfo = ({ portfolio, isLoading }) => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { tokens } = useTheme()
  return (
    <>
      <Button
        onClick={handleOpen}
        appearance="subtle"
        loading={isLoading || !portfolio}
      >
        <Stack spacing={5}>
          <Icon
            as={InfoOutlineIcon}
            style={{
              fontSize: 20,
              color: tokens.colors.metagora.drawdown_pct,
            }}
          />
          <div className="no_mobile">Info</div>
        </Stack>
      </Button>
      {!!portfolio && (
        <Modal open={open} onClose={handleClose}>
          <Modal.Header>
            <Stack justifyContent="space-between">
              <h2>
                <PortStatus status={portfolio.type} />
                {portfolio.displayName}
              </h2>
              <Button
                startIcon={<Icon as={CopyIcon} />}
                onClick={() => navigator.clipboard?.writeText(portfolio.id)}
              >
                ID
              </Button>
            </Stack>
          </Modal.Header>
          <Modal.Body>
            <Stack direction="column" spacing={10} alignItems="flex-start">
              <Button startIcon={<Icon as={CloudReflashIcon} />}>
                <DateFormat date={portfolio.lastSync} initialFormat={null} />
              </Button>
              <Stack direction="column">
                {portfolio.account?.map((acct, inx) => (
                  <Button
                    key={inx}
                    startIcon={<Icon as={CopyIcon} />}
                    onClick={() =>
                      navigator.clipboard?.writeText(acct.accountNum)
                    }
                  >
                    {acct.accountNum}
                  </Button>
                ))}
              </Stack>
            </Stack>
            <Panel style={{ whiteSpace: "pre-line" }}>
              {portfolio.description}
            </Panel>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default PortfolioExtraInfo
