import React, { Suspense, lazy } from "react"
import { Loader } from "rsuite"
import { LoadingDashboardNav } from "./DashboardNav"

const DashboardNav = lazy(() => import("./DashboardNav"))
const ManagerDashboard = lazy(() => import("./components/ManagerDashboard"))
export const Dashboard = () => {
  return (
    <>
      <Suspense fallback={<LoadingDashboardNav />}>
        <DashboardNav />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ManagerDashboard />
      </Suspense>
    </>
  )
}
