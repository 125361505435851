import React from "react"

import { useParams } from "react-router-dom"

import { useTradeServer, usePositionsQuery } from "./useTradeServer"
import useTradeServerColumns, {
  active_field,
} from "./hooks/useTradeServerColumns"
import trade_server_data_config from "./trade_server_data_config"
import useTradeSeverConfig from "./hooks/useTradeSeverConfig"

let TraderDashboardContext
const { Provider, Consumer } = (TraderDashboardContext = React.createContext())

const TraderDashboardProvider = ({ children }) => {
  const { managerSlug, portfolioSlug } = useParams()

  const tradeServerMode = useTradeSeverConfig()

  const {
    data,
    isLoading,
    error: errors,
  } = usePositionsQuery(portfolioSlug, managerSlug)

  const {
    slug,
    id,
    positionsEndpoint,
    lastSync,
    positions = {},
    autoRecon = [],
  } = data || {}
  const { data: position_data } = positions

  const { columns, all_columns, totals_fields } = useTradeServerColumns(
    trade_server_data_config,
    tradeServerMode
  )

  const chart_field = active_field(tradeServerMode)

  const { ts_data, chart_data, summary, summary_chart_data, totals } =
    useTradeServer(position_data, autoRecon, chart_field, totals_fields)

  return (
    <Provider
      value={{
        columns,
        tradeServerMode,
        all_columns,
        isLoading,
        errors,
        ts_data: tradeServerMode === "Summary" ? summary : ts_data,
        chart_field,
        chart_data:
          tradeServerMode === "Summary" ? summary_chart_data : chart_data,
        totals,
        ui: {
          portfolio_id: id,
          lastSync,
          hasAutoRecon: Boolean(autoRecon?.length),
          portName: slug,
          endpoint: positionsEndpoint,
        },
      }}
    >
      {children}
    </Provider>
  )
}

export {
  TraderDashboardContext,
  Consumer as TraderDashboardConsumer,
  TraderDashboardProvider,
}
