export const metagoraPortfolioPositionData = /* GraphQL */ `
  query MetagoraPortfolioPositionData($input: MetagoraPortfolioInput) {
    metagoraPortfolioPositionData(input: $input) {
      id
      name
      displayName
      slug
      portfolioManager
      description
      account {
        broker
        accountNum
        accountName
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      status
      type
      autoReconTimestamp
      autoRecon {
        name
        instrumentID
        modelPosition
        modelChanges
        cumModelContractsTraded
        cumModelContractsTradedRounded
        accountPosition
        positionChanges
        cumAccountContractsTraded
        differenceChanges
        positionDifference
        cumContractsTradedDiff
      }
      lastSync
      positionsEndpoint
      positions {
        data {
          instrument_id
          account
          exchange
          symbol_root
          expiry
          currency
          multiplier
          instrument_type
          quantity
          price
          period
          initial_margin
          maintenance_margin
          positions_pnl_pct
          margin_per_contract
          position_value
          maintenance_margin_pv
          absolute_pnl
          relative_pnl
          price_change
          price_diff
          maintenance_margin_relative
          __typename
        }
        formats
        __typename
      }
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      minDollarPositionValueToShow
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      posTrack {
        positions {
          name
          accountPosition
          modelPosition
          status
          instrumentId
        }
        alertStatus
        isAlertVisible
        accountLastValid
        accountStatus
        modelLastValid
        modelStatus
        leverage
      }
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`
export const getLatestPerInstrument = /* GraphQL */ `
  query GetLatestPerInstrument($input: LatestPerInstrumentInput) {
    getLatestPerInstrument(input: $input) {
      items {
        period
        userId
        instrumentTradeId
        instrumentPriceId
        startPriceUsd
        endPriceUsd
        startPriceRaw
        endPriceRaw
        absolutePnl
        startQuantity
        endQuantity
        priceChange
        priceDiff
        percentagePnl
        __typename
      }
      instruments {
        id
        expiry
        ticker
        currency
        exchange
        isActive
        multiplier
        tradableId
        priceSource
        conversionTo
        priceProvider
        conversionFrom
        instrumentType
        tradingExchange
        __typename
      }
      __typename
    }
  }
`
export const getInstrumentPnL = /* GraphQL */ `
  query GetInstrumentPnL($input: InstrumentPnLInput) {
    getInstrumentPnL(input: $input) {
      items {
        period
        pnlUsd
        instrumentPnlUsd
        userID
        tradeInstrumentId
        quantity
        expiry
        ticker
        currency
        exchange
        isActive
        multiplier
        instrumentType
        tradingExchange
        __typename
      }
      __typename
    }
  }
`
