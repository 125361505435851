import _ from "lodash"

const QUANT_USERGROUP = process.env.REACT_APP_QUANT_GROUPNAME

const getUserInfo = ({ groups, access, username, slack_id, ...rest }) => {
  const inGroup = (name) => groups?.includes(name)
  return {
    ...rest,
    access: access ? [...access, "Benchmarks"] : null,
    isQuant: _.indexOf(access, QUANT_USERGROUP) >= 0,
    groups,
    isSuperAdmin: inGroup("SuperAdmin") && inGroup("Admin"),
    isAdmin: inGroup("Admin"),
    isManager: inGroup("Manager"),
    isPortfolioManager: inGroup("PortfolioManager"),
    tradeServerAccess: inGroup("TradeServer"),
    username,
    slack_id,
  }
}

export default getUserInfo
