import React from "react"
import { useState } from "react"
import { Form, Schema, ButtonToolbar, Button, SelectPicker } from "rsuite"
import { useMutation } from "@tanstack/react-query"
import { useListAllManagers } from "../../../Managers/lib/useManager"
import { useNavigate } from "react-router-dom"

const { StringType, ArrayType, ObjectType, NumberType } = Schema.Types

const MAX_MARGIN_BY_POSITION_THRESHOLD = 10
const MAX_MARGIN_BY_PORTFOLIO_THRESHOLD = 50
const DAILY_RETURN_DEFAULT = 20
const LEVERAGE_CHOICES = ["LEVERAGE", "REALIZED_LEVERAGE", "LEVERAGE_CASH"]

const portfolioModel = Schema.Model({
  name: StringType().isRequired("This field is required"),
  displayName: StringType().isRequired("This field is required"),
  slug: StringType().pattern(/^\S*$/, "Can not contain spaces"),
  portfolioManager: StringType(),
  type: StringType(),
  status: StringType(),
  account: ArrayType().of(
    ObjectType().shape({
      broker: StringType(),
      accountNum: StringType(),
    })
  ),
  positionsEndpoint: StringType(),
  slippageEndpoint: StringType(),
  pnlEndpoint: StringType(),
  dailyReturnThreshold: NumberType(),
  maxMarginPerPosition: NumberType(),
  maxMarginPerPortfolio: NumberType(),
  leverageToDisplay: StringType(),
  leverage: NumberType(),
  minDollarPositionValueToShow: NumberType(),
})

const DuplicatePortfolio = ({ portfolio, handleClose }) => {
  const { data } = useListAllManagers()
  const { managers } = data || []
  const navigate = useNavigate()

  const mutation = useMutation({
    mutationKey: ["createPortfolio"],
  })

  const formRef = React.useRef()

  const initialFormState = {
    name: portfolio.name + "_copy",
    displayName: portfolio.displayName + "_copy",
    slug: portfolio.slug + "_copy",
    portfolioManager: portfolio.portfolioManager,
    type: portfolio.type,
    status: "DRAFT",
    account: portfolio.account,
    positionsEndpoint: portfolio.positionsEndpoint,
    slippageEndpoint: portfolio.slippageEndpoint,
    pnlEndpoint: portfolio.pnlEndpoint,
    dailyReturnThreshold:
      portfolio.dailyReturnThreshold || DAILY_RETURN_DEFAULT,
    maxMarginPerPosition:
      portfolio.maxMarginPerPosition || MAX_MARGIN_BY_POSITION_THRESHOLD,
    maxMarginPerPortfolio:
      portfolio.maxMarginPerPortfolio || MAX_MARGIN_BY_PORTFOLIO_THRESHOLD,
    leverageToDisplay: portfolio.leverageToDisplay || LEVERAGE_CHOICES[0],
    leverage: portfolio.leverage || 1,
    minDollarPositionValueToShow: portfolio.minDollarPositionValueToShow || 25,
  }

  const [formValue, setFormValue] = useState(initialFormState)

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      return
    }

    mutation.mutate(
      {
        ...formValue,
        dailyReturnThreshold: +formValue.dailyReturnThreshold,
        maxMarginPerPosition: +formValue.maxMarginPerPosition,
        maxMarginPerPortfolio: +formValue.maxMarginPerPortfolio,
        leverage: +formValue.leverage,
        minDollarPositionValueToShow: +formValue.minDollarPositionValueToShow,
      },
      {
        onSuccess: () => {
          handleClose()
          const manager = managers.filter(
            (m) => m.name === formValue.portfolioManager
          )[0]
          navigate(`/admin/${manager.slug}`)
        },
      }
    )
  }

  return (
    <Form
      ref={formRef}
      model={portfolioModel}
      formValue={formValue}
      onChange={setFormValue}
    >
      <Form.Group controlId="name">
        <Form.ControlLabel>Name</Form.ControlLabel>
        <Form.Control name="name" style={{ width: "100%" }} />
      </Form.Group>
      <Form.Group controlId="displayName">
        <Form.ControlLabel>Display Name</Form.ControlLabel>
        <Form.Control name="displayName" style={{ width: "100%" }} />
      </Form.Group>
      <Form.Group controlId="slug">
        <Form.ControlLabel>Slug</Form.ControlLabel>
        <Form.Control name="slug" style={{ width: "100%" }} />
      </Form.Group>
      <Form.Group controlId="portfolioManager">
        <Form.Control
          block
          name="portfolioManager"
          accepter={SelectPicker}
          data={
            managers
              ?.sort((a, b) => a.sortOrder - b.sortOrder)
              .map((opt) => ({
                label: opt.displayName,
                value: opt.slug,
              })) || []
          }
        />
      </Form.Group>
      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  )
}

export default DuplicatePortfolio
