import { Outlet } from "react-router-dom"
import ReportingPage from "./ReportingPage"
import ReportingDashboard from "./ReportingDashboard"

const reporting_route = {
  path: "reporting",
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <ReportingDashboard />,
    },
    {
      path: ":type",
      element: <Outlet />,

      children: [
        {
          index: true,
          element: <ReportingPage />,
        },
      ],
    },
  ],
}

export default reporting_route
