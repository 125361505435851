import React from "react"
import { Text, Stack } from "rsuite"
import { MdOutlineSpeed } from "react-icons/md"
import { Icon } from "@rsuite/icons"
import { useNavigate } from "react-router-dom"

import DataSync from "./DataSync"
import ManagerStatus from "./ManagerStatus"
import PortfolioStatus from "./PortfolioStatus"
import CardContainer from "../../../components/common/CardContainer"
import { useTheme } from "@aws-amplify/ui-react"

const PortfolioItem = ({ portfolio, index }) => {
  const navigate = useNavigate()
  const { tokens } = useTheme()

  const onViewPortfolio = (e) => {
    navigate(`/quant/admin/${portfolio.manager_slug}?q=${portfolio.slug}`)
  }

  return (
    <CardContainer key={index} index={index} onClick={onViewPortfolio}>
      <Stack justifyContent="space-between">
        <div>
          <Stack spacing={10} justifyContent="flex-start">
            <Text size="sm">{portfolio.displayName}</Text>
          </Stack>
          <small>{portfolio.manager_name}</small>
        </div>
        <DataSync port={portfolio} />
      </Stack>
      <Stack spacing={5} alignItems="center" style={{ minHeight: "32px" }}>
        {portfolio.posTrack || portfolio.autoRecon ? (
          <Icon
            style={{ fontSize: "20px" }}
            as={MdOutlineSpeed}
            color={tokens.colors.icons.auto_reconciliation}
          />
        ) : null}
        <div style={{ minWidth: "50px" }}>
          <ManagerStatus portfolio={portfolio} />
        </div>
        <div style={{ minWidth: "50px" }}>
          <PortfolioStatus portfolio={portfolio} />
        </div>
      </Stack>
    </CardContainer>
  )
}

export default PortfolioItem
