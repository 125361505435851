import React from "react"
import { Heading, Stack } from "rsuite"
import { BiSolidSquare } from "react-icons/bi"
import { Icon } from "@rsuite/icons"

const HeaderBlock = ({ block }) => {
  return <Heading level={5}>{block.text.text}</Heading>
}

const DividerBlock = () => {
  return <hr />
}

const EmojiBlock = ({ block }) => {
  if (block.name === "large_blue_square")
    return (
      <Icon as={BiSolidSquare} color="blue" style={{ marginBottom: "3px" }} />
    )
  return null
}

const TextBlock = ({ block }) => {
  return (
    <span
      style={{
        fontWeight: block.style?.bold ? "bold" : "",
      }}
    >
      {block.text}
    </span>
  )
}

const LinkBlock = ({ block }) => {
  return (
    <a
      href={block.url}
      style={{
        fontWeight: block.style?.bold ? "bold" : "",
      }}
    >
      {block.text}
    </a>
  )
}

const RichTextSectionBlock = ({ block }) => {
  return (
    <div>
      <Stack spacing={10} alignItems="center">
        {block.elements.map((el, inx) => {
          if (el.type === "text") return <TextBlock block={el} key={inx} />
          if (el.type === "link") return <LinkBlock block={el} key={inx} />
          if (el.type === "emoji") return <EmojiBlock block={el} key={inx} />
          return null
        })}
      </Stack>
    </div>
  )
}
const RichTextListBlock = ({ block }) => {
  return block.elements.map((el, inx) => {
    if (el.type === "rich_text_section") {
      return <RichTextSectionBlock block={el} key={inx} />
    }
    return null
  })
}

const RichTextBlock = ({ block }) => {
  return block.elements.map((el, inx) => {
    if (el.type === "rich_text_section") {
      return <RichTextSectionBlock block={el} key={inx} />
    }
    if (el.type === "rich_text_list") {
      return <RichTextListBlock block={el} key={inx} />
    }
    return null
  })
}

const SlackBlocks = ({ blocks = [] }) => {
  return blocks.map((b, inx) => {
    if (b.type === "header") {
      return <HeaderBlock block={b} key={inx} />
    }
    if (b.type === "divider") {
      return <DividerBlock key={inx} />
    }
    if (b.type === "rich_text") {
      return <RichTextBlock block={b} key={inx} />
    }
    return null
  })
}

export default SlackBlocks
