import React, { useState } from "react"
import { useControls } from "../../lib/controls/ControlContext"
import { Badge, Nav, Navbar, Toggle } from "rsuite"
import { useListAllManagers } from "../Managers/lib/useManager"
import { NavLink } from "react-router-dom"
import Totals from "./Totals"
import PinIcon from "@rsuite/icons/Pin"
import PinedIcon from "@rsuite/icons/Pined"
import { Icon } from "@rsuite/icons"
import useDashboardConfig from "./lib/useDashboardConfig"
import { useDashboard } from "./lib/useDashboard"
import ExportModal from "./components/ExportModal"
import useAuth from "../../components/AmplifyAuth/useAuth"

const PADDING_SETTING = 5
const ITEM_PADDING_SETTING = 25
const EXPORT_TYPES_INITIAL_VALUE = [
  { name: "PRODUCTION", type: "type" },
  { name: "PAPER", type: "type" },
  { name: "MODEL", type: "type" },
]
export const LoadingDashboardNav = () => {
  return (
    <Navbar>
      <Nav></Nav>
    </Navbar>
  )
}

const DashboardNav = () => {
  const {
    showProduction,
    setShowProduction,
    dashboardConfig: [dConfig, setDConfig],
  } = useControls()
  const { handleExportIssue, dConfigFilter } = useDashboardConfig()
  const { data: dashboardData } = useDashboard(dConfigFilter)
  const { data } = useListAllManagers()
  const { activeManager } = data || {}

  const { userMeta } = useAuth()

  const [typeModalOpen, setTypeModalOpen] = useState(false)
  const [exportTypes, setExportTypes] = useState(EXPORT_TYPES_INITIAL_VALUE)

  const handleModalClose = () => {
    setExportTypes(EXPORT_TYPES_INITIAL_VALUE)
    setTypeModalOpen(false)
  }
  const handleExport = () => {
    handleExportIssue(
      dashboardData,
      exportTypes.map((e) => e.name)
    )
    handleModalClose()
  }

  return (
    <Navbar>
      <Nav style={{ padding: PADDING_SETTING }}>
        {!!activeManager && (
          <Nav.Item as="h4" style={{ padding: ITEM_PADDING_SETTING }}>
            <NavLink to="/quant">{activeManager?.displayName}</NavLink>
          </Nav.Item>
        )}
        <Nav.Item as={Totals} style={{ padding: ITEM_PADDING_SETTING }} />
      </Nav>
      <Nav pullRight style={{ padding: PADDING_SETTING }}>
        <Nav.Item
          onClick={() => setShowProduction((is) => !is)}
          style={{ padding: ITEM_PADDING_SETTING }}
        >
          <Toggle
            checked={showProduction}
            checkedChildren="Account Setup"
            unCheckedChildren="Portfolio"
          />
        </Nav.Item>
        {showProduction && userMeta.isQuant && (
          <Nav.Item
            onClick={() => setTypeModalOpen(true)}
            style={{ padding: ITEM_PADDING_SETTING }}
          >
            Export Issues
          </Nav.Item>
        )}

        <Nav.Item
          style={{ padding: ITEM_PADDING_SETTING }}
          onClick={() =>
            setDConfig({
              ...dConfig,
              editMode: !dConfig.editMode,
            })
          }
        >
          <Badge
            content={dConfig.muted.length ? dConfig.muted.length : false}
            color="blue"
          >
            <Icon as={dConfig.editMode ? PinIcon : PinedIcon} />
          </Badge>
        </Nav.Item>
      </Nav>
      <ExportModal
        typeModalOpen={typeModalOpen}
        handleModalClose={handleModalClose}
        setExportTypes={setExportTypes}
        exportTypes={exportTypes}
        handleExport={handleExport}
      />
    </Navbar>
  )
}

export default DashboardNav
