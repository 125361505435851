import React, { useEffect, lazy, Suspense, useMemo } from "react"
import { useDashboard } from "../Dashboard/lib/useDashboard"
import {
  Button,
  Col,
  Grid,
  Header,
  Heading,
  IconButton,
  Input,
  InputGroup,
  Loader,
  Message,
  Panel,
  Row,
  Stack,
  Text,
  useToaster,
} from "rsuite"
import { FaChartBar } from "react-icons/fa"
import SearchIcon from "@rsuite/icons/Search"
import ArrowLeftLineIcon from "@rsuite/icons/ArrowLeftLine"
import slack_report from "./lib/slack_report"
import useAuth from "../../components/AmplifyAuth/useAuth"
import { send_to_slack } from "./lib/slack_api"

import ReportingHeader from "./components/ReportingHeader"
import styles from "./components/Reporting.module.css"
import { useNavigate, useParams } from "react-router-dom"
import CountCard from "../../components/common/CountCard"

const TEMReport = lazy(() => import("./TEMReport"))
const SlackBlocks = lazy(() => import("./components/SlackBlocks"))

const find_portfolios = (managers = [], threshold = 0.1) => {
  return managers
    .filter((p) => ["PRODUCTION", "PAPER"].indexOf(p.type) >= 0)
    .map((p) => ({
      ...p,
      notes: " ",
      reporting_threshold: threshold,
      leverage_delta: +Math.abs(p.currentLeverage - p.leverageSetting).toFixed(
        2
      ),
      leverage_pct: (p.currentLeverage / p.leverageSetting) * 100,
      leverage_threshold: +(p.leverageSetting * threshold).toFixed(4),
    }))
    .filter((p) => Math.abs(p.leverage_delta) > p.leverage_threshold)
}

// const steps = [
//   { title: "Select Threshold", description: "Choose the Reporting Threshold." },
//   {
//     title: "Select Portfolios",
//     description: "Choose the Portfolios to report on.",
//   },
//   { title: "Confirm", description: "Confirm the Slack Report." },
//   {
//     title: "Send To Slack",
//     description: "Send Report to the #trade_monitoring Slack Channel",
//   },
// ]

const message = (text) => (
  <Message showIcon type="error" closable>
    {text}
  </Message>
)

const useSlackReporting = () => {
  const toaster = useToaster()
  const { userMeta } = useAuth()
  const [slack_blocks, setSlackBlocks] = React.useState()

  const onConvertToSlack = ({ portfolios, reportingThreshold }) => {
    const selected = portfolios.filter((p) => p.selected)
    if (selected.length > 0) {
      const slack_report_data = slack_report({
        slack_id: userMeta?.slack_id,
        report: selected,
        threshold: reportingThreshold,
      })
      setSlackBlocks(slack_report_data)
      return true
    } else {
      toaster.push(message("Please choose a Portfolio to report on"), {
        duration: 5000,
      })
      return false
    }
  }
  const onSendToSlack = () => {
    const confirm = window.confirm(
      `Do you certify that this report is correct?`
    )
    if (confirm === true) {
      send_to_slack(slack_blocks)
    }
  }
  const onClearSlack = () => {
    setSlackBlocks(null)
  }

  return {
    slack_blocks,
    onConvertToSlack,
    onSendToSlack,
    onClearSlack,
  }
}

const useReportData = () => {
  const { data, isLoading } = useDashboard()
  const [portfolios, setPortfolios] = React.useState([])
  const [reportingThreshold, setReportingThreshold] = React.useState(null)

  useEffect(() => {
    setPortfolios(() => data?.managers || [])
  }, [data?.managers])

  const onSelectWithThreshold = (value) => {
    if (!value) {
      setPortfolios(data?.managers || [])
      return
    }
    setReportingThreshold(value)
    setPortfolios(() => [...find_portfolios(data?.managers, value)])
  }

  const onResetReport = () => {
    setPortfolios([])
  }

  return {
    isLoading,
    portfolios: portfolios,
    hasData: portfolios.length > 0,
    reportingThreshold,
    onSelectWithThreshold,
    onResetReport,
  }
}

const ReportingPage = () => {
  const {
    isLoading,
    portfolios,
    reportingThreshold,
    onSelectWithThreshold,
    onResetReport,
    hasData,
  } = useReportData()

  const navigate = useNavigate()

  const { type } = useParams()

  const [reportSteps, setSteps] = React.useState(0)
  const [leverageValue, setLeverageValue] = React.useState(null)
  const { slack_blocks, onConvertToSlack, onSendToSlack, onClearSlack } =
    useSlackReporting()

  const onReportReset = () => {
    onResetReport()
    onClearSlack()
    setSteps(0)
  }

  const isTypeOfficial = type === "official"

  const unofficialPNL = useMemo(() => {
    return portfolios.filter((portfolio) => portfolio.health.unofficial_numbers)
  }, [portfolios])

  return (
    <Grid fluid style={{ padding: "0 0.8rem" }}>
      <Header>
        <Grid fluid style={{ paddingTop: 0 }}>
          <Row>
            <Col xs={24}>
              <ReportingHeader title="Reporting Dashboard" />
            </Col>
          </Row>
        </Grid>
      </Header>
      <Row>
        <Col xs={24}>
          <Stack alignItems="center" spacing={6}>
            <IconButton
              circle
              icon={<ArrowLeftLineIcon />}
              appearance="default"
              style={{ marginBottom: "0.5rem" }}
              onClick={() => navigate("/reporting")}
            />
            <Text size="sm" style={{ marginBottom: "5px" }}>
              Return
            </Text>
          </Stack>
        </Col>
      </Row>
      <Row>
        {isTypeOfficial && (
          <Col
            xs={24}
            md={24}
            lg={6}
            xl={6}
            style={{ marginBottom: "0.5rem", position: "sticky", top: "10px" }}
          >
            <Stack direction="column" spacing={10}>
              <Stack.Item style={{ width: "100%", marginBottom: "0.5rem" }}>
                <CountCard
                  managers={portfolios}
                  icon={FaChartBar}
                  title="PNL"
                  count={portfolios?.length || 0}
                  bg="info"
                  sparkLineData
                />
              </Stack.Item>
            </Stack>
            <Panel bodyFill className={styles.cardItem}>
              {!isLoading && (
                <>
                  <Heading level={6} style={{ marginBottom: "0.5rem" }}>
                    Settings
                  </Heading>

                  <InputGroup inside style={{ marginBottom: "0.5rem" }}>
                    <Input placeholder="Leverage" onChange={setLeverageValue} />
                    <InputGroup.Button>
                      <SearchIcon />
                    </InputGroup.Button>
                  </InputGroup>

                  <Stack spacing={8} style={{ marginBottom: "1rem" }}>
                    <Stack.Item flex={1}>
                      <Button
                        style={{ width: "100%" }}
                        onClick={() => {
                          setSteps(1)
                          onSelectWithThreshold(leverageValue)
                        }}
                      >
                        Select {leverageValue}%
                      </Button>
                    </Stack.Item>
                    <Stack.Item flex={1}>
                      <Button
                        style={{ width: "100%" }}
                        disabled={!hasData}
                        onClick={() => {
                          setSteps(0)
                          onReportReset()
                        }}
                      >
                        Restart
                      </Button>
                    </Stack.Item>
                  </Stack>
                  {reportSteps === 2 && (
                    <>
                      <Heading level={6} style={{ marginBottom: "0.5rem" }}>
                        Slack Settings
                      </Heading>

                      <Stack spacing={8} style={{ marginBottom: "0.5rem" }}>
                        <Stack.Item flex={1}>
                          <Button
                            style={{ width: "100%" }}
                            onClick={() => {
                              setSteps(1)
                              onClearSlack()
                            }}
                          >
                            Clear Slack
                          </Button>
                        </Stack.Item>
                        <Stack.Item flex={1}>
                          <Button
                            style={{ width: "100%" }}
                            onClick={onSendToSlack}
                            color="green"
                            appearance="primary"
                          >
                            Send to Slack
                          </Button>
                        </Stack.Item>
                      </Stack>
                    </>
                  )}
                </>
              )}
            </Panel>
          </Col>
        )}

        <Col
          xs={24}
          md={24}
          lg={isTypeOfficial ? 18 : 24}
          xl={isTypeOfficial ? 18 : 24}
          style={{ marginBottom: "0.5rem" }}
        >
          <Row>
            <Col xs={24}>
              {reportSteps !== 2 && (
                <Suspense fallback={<Loader />}>
                  <TEMReport
                    key={portfolios.length}
                    portfolios={isTypeOfficial ? portfolios : unofficialPNL}
                    submitLabel="Convert to Slack"
                    onSubmit={(d) => {
                      if (onConvertToSlack(d)) {
                        setSteps(2)
                      }
                    }}
                    reportingThreshold={reportingThreshold}
                  />
                </Suspense>
              )}

              {reportSteps === 2 && (
                <Suspense fallback={<Loader />}>
                  <Panel bodyFill className={styles.cardItem}>
                    <SlackBlocks blocks={slack_blocks?.blocks} />
                  </Panel>
                </Suspense>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  )
}

export default ReportingPage
