import React, { useMemo, useState } from "react"
import { Col, Heading, Panel, Row, SelectPicker, Stack, Text } from "rsuite"
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine"
import { Icon } from "@rsuite/icons"
import { Line, LineChart, ResponsiveContainer } from "recharts"

import CardContainer from "./CardContainer"
import { useNavigate } from "react-router-dom"
import { useTheme } from "@aws-amplify/ui-react"

const CountCard = ({
  managers,
  icon,
  title,
  count,
  link,
  sparkLineData,
  bg,
}) => {
  const { tokens } = useTheme()
  const navigate = useNavigate()

  const [selectValue, setSelectValue] = useState("")

  const sparkLineValue = useMemo(() => {
    return [
      { value: 0 },
      ...(managers?.map((manager) => ({
        value: manager[selectValue] || 0,
      })) || []),
    ]
  }, [selectValue, managers])

  return (
    <CardContainer bgColor={tokens.colors.metagora[`bg_${bg}`]}>
      <Row>
        <Col sm={12}>
          <Stack
            justifyContent="center"
            alignItems="flex-start"
            direction="column"
            style={{ paddingTop: "10px" }}
          >
            <Icon
              as={icon}
              style={{
                fontSize: 30,
                color: "#828387",
                marginBottom: "0.5rem",
              }}
            />
            <Text
              size="md"
              style={{ color: "#ffa71e", marginBottom: "0.8rem" }}
            >
              {title}
            </Text>
          </Stack>
        </Col>
        <Col sm={12}>
          <Row>
            <Col xs={24}>
              {sparkLineData && (
                <SelectPicker
                  size="sm"
                  style={{ width: 200 }}
                  onChange={setSelectValue}
                  value={selectValue}
                  data={[
                    {
                      label: "PNL",
                      value: "todaysPNL",
                    },
                    {
                      label: "Return Percent",
                      value: "avg_daily_return_pct",
                    },
                  ]}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              {sparkLineData && (
                <ResponsiveContainer height={52}>
                  <LineChart
                    width={200}
                    data={sparkLineValue}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#8884d8"
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Panel
        bodyFill
        style={{ backgroundColor: "#1b1d24", padding: "0.4rem 0.4rem" }}
      >
        <Stack alignItems="center" justifyContent="space-between">
          <Heading level={6}>{count}</Heading>
          {link && (
            <ArrowRightLineIcon
              onClick={() => navigate(link)}
              style={{
                fontSize: "1.5rem",
                color: "#ffa71e",
                cursor: "pointer",
              }}
            />
          )}
        </Stack>
      </Panel>
    </CardContainer>
  )
}

export default CountCard
