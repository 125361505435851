import { useEffect, useState } from "react"
import _ from "lodash"

const getRebasedData = (data, field) => {
  const base = data
    ? data[field] > 0
      ? -data[field]
      : Math.abs(data[field])
    : 0

  return base
}

export const rebaseSlippageData = (slippage) => {
  const first = _.head(slippage)
  const baseCumSlip = getRebasedData(first, "cumulative_slippage_pct")
  const rebasedD = slippage.map((d) => {
    const rebasedCumSlipPct =
      d.cumulative_slippage_pct + baseCumSlip + first.daily_slippage_pct
    const rebasedSlip = {
      ...d,
      rebased: rebasedCumSlipPct,
    }
    return rebasedSlip
  })
  return rebasedD
}

const useRebasedSlippageAxis = (slippage) => {
  const [rebasedData, setData] = useState([])

  useEffect(() => {
    if (slippage?.length > 0) {
      const rebasedD = rebaseSlippageData(slippage)
      setData(rebasedD)
    }
  }, [slippage])

  return rebasedData
}

export default useRebasedSlippageAxis
