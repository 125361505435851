import React from "react"
import {
  Input,
  ButtonGroup,
  IconButton,
  Schema,
  Grid,
  Row,
  Col,
  Text,
  DatePicker,
  SelectPicker,
  Stack,
  Badge,
} from "rsuite"
// import { useMutation } from "@tanstack/react-query"
import PlusIcon from "@rsuite/icons/Plus"
import MinusIcon from "@rsuite/icons/Minus"
import { useTheme } from "@aws-amplify/ui-react"

const { ArrayType, StringType, ObjectType } = Schema.Types

export const milestoneModel = Schema.Model({
  milstones: ArrayType().of(
    ObjectType().shape({
      title: StringType().isRequired("Required."),
      date: StringType().isRequired("Required."),
      color: StringType().isRequired("Required."),
    })
  ),
})

const ErrorMessage = ({ children }) => (
  <span style={{ color: "red" }}>{children}</span>
)

const MilstoneItem = ({ rowValue = {}, onChange, rowIndex, rowError }) => {
  const { tokens } = useTheme()

  const colors = tokens.colors.milestone.value.map((color) => ({
    label: color.toUpperCase(),
    value: color,
  }))
  const handleChangeTitle = (value) => {
    onChange(rowIndex, { ...rowValue, title: value })
  }
  const handleChangeDate = (value) => {
    onChange(rowIndex, { ...rowValue, date: new Date(value) })
  }

  const handleChangeColor = (value) => {
    onChange(rowIndex, { ...rowValue, color: value })
  }

  return (
    <>
      <Row style={{ marginBottom: "10px" }} gutter={10}>
        <Col xs={24} md={24} lg={8} xl={8} style={{ marginBottom: "10px" }}>
          <Text>Title</Text>
          <Input value={rowValue.title || ""} onChange={handleChangeTitle} />
          {rowError ? (
            <ErrorMessage>{rowError.title.errorMessage}</ErrorMessage>
          ) : null}
        </Col>

        <Col xs={24} md={24} lg={8} xl={8} style={{ marginBottom: "10px" }}>
          <Text>Date</Text>
          <DatePicker
            block
            value={new Date(rowValue.date) || ""}
            onChange={handleChangeDate}
          />
          {rowError ? (
            <ErrorMessage>{rowError.date.errorMessage}</ErrorMessage>
          ) : null}
        </Col>
        <Col xs={24} md={24} lg={8} xl={8} style={{ marginBottom: "10px" }}>
          <Text>Color</Text>
          <SelectPicker
            data={colors}
            searchable={false}
            renderMenuItem={renderMenuItem}
            value={rowValue.color || ""}
            onChange={handleChangeColor}
            block
          />

          {rowError ? (
            <ErrorMessage>{rowError.color.errorMessage}</ErrorMessage>
          ) : null}
        </Col>
      </Row>
    </>
  )
}

const renderMenuItem = (label, item) => {
  return (
    <Stack justifyContent="space-between">
      <Text>{label}</Text>
      <Badge color={item.value} />
    </Stack>
  )
}

const MilestoneInput = ({ value = [], onChange, fieldError }) => {
  const errors = fieldError ? fieldError.array : []
  const [milestones, setMilestones] = React.useState(value)
  const handleChangeMilestones = (nextMilestone) => {
    setMilestones(nextMilestone)
    onChange(nextMilestone)
  }
  const handleInputChange = (rowIndex, value) => {
    const nextMilestone = [...milestones]
    nextMilestone[rowIndex] = value
    handleChangeMilestones(nextMilestone)
  }

  const handleMinus = () => {
    handleChangeMilestones(milestones.slice(0, -1))
  }
  const handleAdd = () => {
    handleChangeMilestones(
      milestones.concat([{ title: "", date: new Date(), color: "red" }])
    )
  }
  return (
    <Grid fluid>
      {milestones.map((rowValue, index) => (
        <MilstoneItem
          key={index}
          rowIndex={index}
          rowValue={rowValue}
          rowError={errors[index] ? errors[index].object : null}
          onChange={handleInputChange}
        />
      ))}
      <Row style={{ marginBottom: "5px" }}>
        <Col>
          <ButtonGroup size="xs">
            <IconButton onClick={handleAdd} icon={<PlusIcon />} />

            <IconButton onClick={handleMinus} icon={<MinusIcon />} />
          </ButtonGroup>
        </Col>
      </Row>
    </Grid>
  )
}

export default MilestoneInput
