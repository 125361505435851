import React from "react"
import {
  Link,
  isRouteErrorResponse,
  useLocation,
  useRouteError,
} from "react-router-dom"
import { FlexboxGrid, Panel } from "rsuite"
import FlexboxGridItem from "rsuite/esm/FlexboxGrid/FlexboxGridItem"

const ErrorPage = () => {
  const location = useLocation()
  const error = useRouteError()
  console.log(error)

  if (isRouteErrorResponse(error)) {
    console.log("isRouteErrorResponse", error)
  }

  return (
    <FlexboxGrid
      justify="center"
      align="middle"
      style={{
        height: "100vh",
      }}
      id="error-img"
    >
      <FlexboxGridItem>
        <Panel
          shaded
          bordered
          style={{
            display: "inline-block",
            width: "30rem",
            fontWeight: "bold",
            backgroundColor: "black",
            color: "white",
          }}
        >
          <Panel>
            <pre>{JSON.stringify(error, true, 2)}</pre>
            <i>{error.statusText || error.message}</i>
            <p>{error.recoverySuggestion}</p>
          </Panel>
          <Link to={location.pathname}>Return</Link>
        </Panel>
      </FlexboxGridItem>
    </FlexboxGrid>
  )
}

export default ErrorPage
