import moment from "moment"
import { Button, ButtonGroup, Stack } from "rsuite"
import { formatString } from "./utils"

const chart_config = {
  chart_margin: {
    top: 5,
    right: 15,
    left: 0,
    bottom: 10,
  },
  xAxisPadding: { left: 20, right: 20 },
  xAxisTick: (tick) => `${moment(tick, "YYYY-MM-DD").format("M-DD")}`,
  yTickFormatter: (tick) => (Math.abs(tick) >= 0.5 ? `${tick.toFixed(2)}` : ""),
  percTickFormatter: (tick) =>
    Math.abs(tick) >= 0.7 ? `${tick.toFixed(2)}%` : "",
  legendWrapperStyle: {
    fontWeight: "bold",
    paddingBottom: 10,
    fontSize: ".8em",
  },
  chart_height: 300,
}

export default chart_config

export const renderLegend =
  (handleClick) =>
  ({ payload }) => {
    return (
      <Stack spacing={5} justifyContent="center">
        <ButtonGroup>
          {payload.map((item, inx) => {
            return (
              item.type !== "none" && (
                <Button
                  key={inx}
                  style={{ color: item?.color?.value, whiteSpace: "nowrap" }}
                  appearance="subtle"
                  onClick={() => handleClick(item?.value)}
                >
                  {formatString(item?.value)}
                </Button>
              )
            )
          })}
        </ButtonGroup>
      </Stack>
    )
  }
