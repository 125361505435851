import React from "react"
import { Text, Stack, Loader, Progress } from "rsuite"
import moment from "moment"
import ReloadIcon from "@rsuite/icons/Reload"
import usePortfolioSync from "../../Portfolios/Settings/hooks/usePortfolioSync"
import { useTheme } from "@aws-amplify/ui-react"

function alertColor(pctValue, colors) {
  if (pctValue < 40) {
    return colors.green[80]
  } else if (pctValue >= 40 && pctValue < 60) {
    return colors.blue[60]
  } else if (pctValue >= 60 && pctValue < 80) {
    return colors.orange[80]
  } else if (pctValue >= 80) {
    return colors.red[80]
  } else {
    return pctValue
  }
}

const DataSync = ({ port }) => {
  const { sync, isLoading, isError } = usePortfolioSync(port.id)

  const { tokens } = useTheme()
  const fromNow = moment().diff(moment(port.lastSync), "minutes")
  const percentage = +((fromNow / 60) * 100).toFixed()
  const colorNum = alertColor(percentage, tokens.colors)
  const syncDate = moment(port.lastSync, "YYYY-MM-DD h:mm a").isValid()
    ? moment(port.lastSync, "YYYY-MM-DD h:mm a").format("MM-DD h:mm a")
    : null

  if (isError)
    return (
      <Stack onClick={sync}>
        <Text size="sm" color="red">
          Sync error!
        </Text>
      </Stack>
    )

  if (isLoading)
    return (
      <Stack alignItems="center" spacing={6}>
        <Text size="sm">Syncing</Text>
        <Loader />
      </Stack>
    )

  return port.lastSync ? (
    <Stack
      onClick={sync}
      style={{
        width: 22,
        display: "inline-block",
      }}
    >
      <Stack.Item
        style={{
          position: "relative",
          textAlign: "center",
        }}
      >
        <Progress.Circle
          percent={percentage}
          showInfo={false}
          strokeColor={colorNum.value}
          strokeWidth={10}
        />
        <div
          style={{ position: "absolute", left: 0, right: 0, top: "1px" }}
          title={syncDate}
        >
          <Text size="sm">
            <ReloadIcon />
          </Text>
        </div>
      </Stack.Item>
    </Stack>
  ) : (
    <Text size="sm">
      <span>Never Sync'd</span>
    </Text>
  )
}

export default DataSync
