import React, { lazy, Suspense } from "react"
import { SlippageProvider, useSlippage } from "./lib/SlippageContext"
import { Col, Grid, Loader, Nav, Navbar, Row } from "rsuite"
import { BsFillFileSpreadsheetFill } from "react-icons/bs"

import CSVDownload from "../../../../components/common/CSVDownload"
import SyncButton from "../../Settings/controls/SyncButton"

const SlippageBarChart = lazy(() =>
  import("./charts/SlippageBarChart/SlippageBarChart")
)
const CommissionChart = lazy(() => import("./charts/CommissionChart"))
const SlippageTable = lazy(() => import("./SlippageTable"))

const SlippageNavBar = () => {
  const {
    data,
    displayName,
    ui: { portfolio_id, lastSync, endpoint },
  } = useSlippage()
  return (
    <Navbar style={{ background: "black" }}>
      <Nav pullRight>
        <Nav.Item>
          <SyncButton
            port={{
              lastSync: lastSync,
              id: portfolio_id,
            }}
          />
        </Nav.Item>
        <Nav.Item
          style={{ padding: 10 }}
          as={`a`}
          href={process.env.REACT_APP_ENDPOINT + endpoint}
          target="_blank"
          rel="noreferrer"
          icon={<BsFillFileSpreadsheetFill />}
        />
        <Nav.Item
          style={{ padding: 10 }}
          icon={
            <CSVDownload
              data={data}
              filename={displayName}
              label={`Download CSV`}
            />
          }
        />
      </Nav>
    </Navbar>
  )
}
const SlippagePage = () => {
  return (
    <SlippageProvider>
      <Grid style={{ width: "100%" }} fluid>
        <Row>
          <Col xs={24}>
            <Suspense fallback={<Loader />}>
              <SlippageNavBar />
            </Suspense>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Suspense fallback={<Loader />}>
              <SlippageBarChart />
            </Suspense>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Suspense fallback={<Loader />}>
              <CommissionChart />
            </Suspense>
          </Col>
        </Row>
        <Row>
          <Col xs={24} id="slippage">
            <Suspense fallback={<Loader />}>
              <SlippageTable />
            </Suspense>
          </Col>
        </Row>
      </Grid>
    </SlippageProvider>
  )
}

export default SlippagePage
