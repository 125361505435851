import React, { Suspense, lazy } from "react"
import { Button, FlexboxGrid, Loader, Stack } from "rsuite"
import _ from "lodash"
import { useTradeServer } from "../lib/useTradeServer"

const TradeServerPnL = lazy(() => import("./TradeServerPnL"))

const TradeServerPnlPage = () => {
  const { ts_data, instruments, accountInfo } = useTradeServer()

  const [selectedInstruments, setInstruments] = React.useState([])

  const handleToggle = (id) => {
    setInstruments((prev) =>
      prev.indexOf(id) >= 0 ? prev.filter((ins) => ins !== id) : [...prev, id]
    )
  }

  return (
    <FlexboxGrid>
      <FlexboxGrid.Item colspan={3}>
        <FlexboxGrid justify="center" style={{ paddingTop: 20 }}>
          <FlexboxGrid.Item colspan={20}>
            <Stack wrap spacing={3}>
              {_.orderBy(instruments, ["ticker"]).map((ins, inx) => (
                <Stack.Item key={inx}>
                  <Button
                    style={{ width: 70 }}
                    appearance={
                      selectedInstruments.indexOf(ins.id) >= 0
                        ? "primary"
                        : "default"
                    }
                    onClick={() => {
                      handleToggle(ins.id)
                    }}
                  >
                    {ins.ticker}
                  </Button>
                </Stack.Item>
              ))}
            </Stack>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={18}>
        <Suspense fallback={<Loader />}>
          <TradeServerPnL
            user={accountInfo}
            selectedInstruments={selectedInstruments}
            instruments={instruments}
            instrument_pnls={ts_data}
          />
        </Suspense>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  )
}

export default TradeServerPnlPage
