import React from "react"
import { Heading, Panel, Stack, Text } from "rsuite"
import { useNavigate } from "react-router-dom"
import styles from "./Reporting.module.css"

const ReportUnofficial = ({ unofficialPNL }) => {
  const navigate = useNavigate()
  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: "1rem", padding: "0rem 1rem 0 0" }}
      >
        <div style={{ margin: "10px 0" }}>
          <Heading level={6} style={{ color: "orange" }}>
            Unofficial PNL
          </Heading>
          <Text size="sm" style={{ color: "#828387" }}>
            List of unofiacial PNL
          </Text>
        </div>
      </Stack>
      <Stack
        justifyContent="space-between"
        style={{
          marginBottom: "0.5rem",
          padding: "0rem 1rem",
          width: "100%",
        }}
      >
        <Text size="sm" style={{ color: "#ffa71e", width: "140px" }}>
          Name
        </Text>
        <Stack.Item flex={0.8}>
          <Text size="sm" style={{ color: "#ffa71e" }}>
            Account
          </Text>
        </Stack.Item>
        <Stack.Item flex={0.4}>
          <Text size="sm" style={{ color: "#ffa71e" }}>
            Date
          </Text>
        </Stack.Item>
      </Stack>
      <div
        style={{
          height: "410px",
          overflow: "auto",
          paddingRight: "15px",
        }}
      >
        {unofficialPNL?.map((manager, index) => (
          <Panel
            key={index}
            bodyFill
            className={styles.cardItem}
            style={{
              marginBottom: "0.5rem",
              backgroundColor: "#1b1d24",
              padding: "0.8rem 1rem",
            }}
          >
            <Stack
              alignItems="center"
              justifyContent="space-between"
              style={{ width: "100%" }}
            >
              <Stack
                direction="column"
                alignItems="flex-start"
                spacing={5}
                style={{ width: "140px" }}
              >
                <Text
                  size="sm"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(
                      `/quant/${manager.manager_slug}/${manager.slug}/historical?r=YTD`
                    )
                  }
                >
                  {manager.displayName}
                </Text>{" "}
                <Text size="sm">{manager.manager_displayName}</Text>
              </Stack>
              <Stack.Item flex={1}>
                <Text size="sm">{manager.account}</Text>
              </Stack.Item>
              <Stack.Item flex={0.4}>
                <Text size="sm">{manager.lastPNLDate}</Text>
              </Stack.Item>
            </Stack>
          </Panel>
        ))}
      </div>
    </>
  )
}

export default ReportUnofficial
