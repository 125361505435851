import {
  useToggleLocalStorage,
  useBooleanLocalStorage,
} from "./useToggleLocalStorage"

const VISIBILITY = {
  SHOW: "SHOW",
  HIDE: "HIDE",
}

const useLocalControls = () => {
  const primaryKey = useToggleLocalStorage(
    "primaryKey",
    "cumulative_return_pct",
    "cumulative_return_cash"
  )
  const compactPnlTable = useToggleLocalStorage(
    "compactPnlTable",
    VISIBILITY.SHOW,
    VISIBILITY.HIDE
  )

  const expandHeader = useToggleLocalStorage(
    "expandHeader",
    VISIBILITY.SHOW,
    VISIBILITY.HIDE
  )
  const showLabels = useToggleLocalStorage(
    "showLabels",
    VISIBILITY.SHOW,
    VISIBILITY.HIDE
  )
  const showTooltip = useToggleLocalStorage(
    "showTooltip",
    VISIBILITY.SHOW,
    VISIBILITY.HIDE
  )
  const showManagerTotals = useToggleLocalStorage(
    "dashboard-viewTotals",
    VISIBILITY.SHOW,
    VISIBILITY.HIDE
  )
  const showTradeServerSummary = useToggleLocalStorage(
    "tradeserver-summary",
    "Summary",
    "Positions"
  )

  const showTradeServerColors = useToggleLocalStorage(
    "tradeserver-colors",
    VISIBILITY.HIDE,
    VISIBILITY.SHOW
  )

  const [showChart, toggleShowChart] = useBooleanLocalStorage(
    "traderdashboard-chart",
    "false"
  )

  return {
    primaryKey,
    compactPnlTable,
    expandHeader,
    showLabels,
    showTooltip,
    showManagerTotals,
    showTradeServerSummary,
    showTradeServerColors,
    showChart,
    toggleShowChart,
  }
}

export default useLocalControls
