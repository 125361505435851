import { useState } from "react"
import useAuth from "../../../components/AmplifyAuth/useAuth"
import slack_report from "../lib/slack_report"
import { send_to_slack } from "../lib/slack_api"

export const useSlackReporting = () => {
  const { userMeta } = useAuth()
  const [slack_blocks, setSlackBlocks] = useState()

  const onConvertToSlack = ({ portfolios, reportingThreshold }) => {
    if (portfolios.length > 0) {
      const slack_report_data = slack_report({
        slack_id: userMeta?.slack_id,
        report: portfolios,
        threshold: reportingThreshold,
      })

      setSlackBlocks(slack_report_data)
      return true
    } else {
      return false
    }
  }
  const onSendToSlack = () => {
    const confirm = window.confirm(
      `Do you certify that this report is correct?`
    )
    if (confirm === true) {
      send_to_slack(slack_blocks)
    }
  }
  const onClearSlack = () => {
    setSlackBlocks(null)
  }

  return {
    slack_blocks,
    onConvertToSlack,
    onSendToSlack,
    onClearSlack,
  }
}
