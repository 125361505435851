import axios from "axios"

const SLACK_ENDPOINT = process.env.REACT_APP_SLACK_ENDPOINT

export const send_to_slack = async (data) => {
  console.log(data)
  try {
    await axios.post(SLACK_ENDPOINT, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
  } catch (error) {
    console.log(error)
  }
}
