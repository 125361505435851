import React from "react"
import {
  Input,
  ButtonGroup,
  IconButton,
  Schema,
  SelectPicker,
  Grid,
  Row,
  Col,
  Text,
} from "rsuite"
// import { useMutation } from "@tanstack/react-query"
import PlusIcon from "@rsuite/icons/Plus"
import MinusIcon from "@rsuite/icons/Minus"

const broker_list = [
  { key: "binance", value: "binance", label: "Binance" },
  {
    key: "interactivebrokers",
    value: "interactivebrokers",
    label: "Interactive Brokers",
  },

  {
    key: "rawmodel_interactivebrokers",
    value: "rawmodel_interactivebrokers",
    label: "Raw Model Interactive Brokers",
  },
]

const { ArrayType, StringType, ObjectType } = Schema.Types

export const accountModel = Schema.Model({
  accounts: ArrayType().of(
    ObjectType().shape({
      accountName: StringType().isRequired("Required."),
      accountNum: StringType().isRequired("Required."),
      broker: StringType().isRequired("Required."),
    })
  ),
})

const ErrorMessage = ({ children }) => (
  <span style={{ color: "red" }}>{children}</span>
)

const AccountItem = ({ rowValue = {}, onChange, rowIndex, rowError }) => {
  const handleChangeName = (value) => {
    onChange(rowIndex, { ...rowValue, accountName: value })
  }
  const handleChangeNum = (value) => {
    onChange(rowIndex, { ...rowValue, accountNum: value })
  }
  const handleChangeBroker = (value) => {
    onChange(rowIndex, { ...rowValue, broker: value })
  }
  return (
    <>
      <Row style={{ marginBottom: "20px" }}>
        <Col xs={24} md={24} lg={12} xl={12} style={{ marginBottom: "10px" }}>
          <Text>Broker</Text>
          <SelectPicker
            style={{ width: "100%" }}
            data={broker_list}
            value={rowValue.broker || ""}
            onChange={handleChangeBroker}
          />
          {rowError ? (
            <ErrorMessage>{rowError.broker.errorMessage}</ErrorMessage>
          ) : null}
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }} gutter={10}>
        <Col xs={24} md={24} lg={12} xl={12} style={{ marginBottom: "10px" }}>
          <Text>Account Name</Text>
          <Input
            value={rowValue.accountName || ""}
            onChange={handleChangeName}
          />
          {rowError ? (
            <ErrorMessage>{rowError.accountName.errorMessage}</ErrorMessage>
          ) : null}
        </Col>

        <Col xs={24} md={24} lg={12} xl={12} style={{ marginBottom: "10px" }}>
          <Text>Account Number</Text>
          <Input value={rowValue.accountNum || ""} onChange={handleChangeNum} />
          {rowError ? (
            <ErrorMessage>{rowError.accountNum.errorMessage}</ErrorMessage>
          ) : null}
        </Col>
      </Row>
    </>
  )
}

const AccountInput = ({ value = [], onChange, fieldError }) => {
  const errors = fieldError ? fieldError.array : []
  const [accounts, setAccounts] = React.useState(value)
  const handleChangeAccounts = (nextAccounts) => {
    setAccounts(nextAccounts)
    onChange(nextAccounts)
  }
  const handleInputChange = (rowIndex, value) => {
    const nextAccounts = [...accounts]
    nextAccounts[rowIndex] = value
    handleChangeAccounts(nextAccounts)
  }

  const handleMinus = () => {
    handleChangeAccounts(accounts.slice(0, -1))
  }
  const handleAdd = () => {
    handleChangeAccounts(
      accounts.concat([{ accountName: "", accountNum: "", broker: "" }])
    )
  }
  return (
    <Grid fluid>
      {accounts.map((rowValue, index) => (
        <AccountItem
          key={index}
          rowIndex={index}
          rowValue={rowValue}
          rowError={errors[index] ? errors[index].object : null}
          onChange={handleInputChange}
        />
      ))}
      <Row style={{ marginBottom: "5px" }}>
        <Col>
          <ButtonGroup size="xs">
            <IconButton onClick={handleAdd} icon={<PlusIcon />} />

            <IconButton onClick={handleMinus} icon={<MinusIcon />} />
          </ButtonGroup>
        </Col>
      </Row>
    </Grid>
  )
}

export default AccountInput
