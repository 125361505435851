import { json } from "react-router-dom"

const getAccessFilter = (field, userMeta) => {
  const { access, isQuant } = userMeta || { access: [], isQuant: false }
  try {
    if (access.length === 0 && !isQuant) {
      throw json(
        {
          message: "Access Required",
        },
        {
          status: 401,
        }
      )
    }
    const filter = isQuant
      ? {}
      : {
          or: access.map((pm) => ({
            [field]: { eq: pm },
          })),
        }
    return {
      filter,
      access,
      isQuant,
    }
  } catch (error) {
    // console.log(error)
    throw error
  }
}

export default getAccessFilter
