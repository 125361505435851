import { useLocalStorage } from "../useLocalStorage"

export const useToggleLocalStorage = (key, defaultValue, alternateValue) => {
  const [value, setValue] = useLocalStorage(key, defaultValue)
  const toggle = () =>
    setValue((prev) => (prev === defaultValue ? alternateValue : defaultValue))
  return {
    toggle,
    isDefault: value === defaultValue,
    setValue,
    value,
  }
}

export const useBooleanLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useLocalStorage(key, defaultValue)
  const toggle = () => setValue((prev) => (prev === "true" ? "false" : "true"))
  return [value === "true", toggle]
}
