import React from "react"
import { NavLink } from "react-router-dom"
import { Nav } from "rsuite"

import app_checklist_btn from "../../images/buttons/app_checklist.png"
import app_pm_btn from "../../images/buttons/app_pm.png"
import app_quant_btn from "../../images/buttons/app_quant.png"
import app_profile_btn from "../../images/buttons/app_profile.png"
import app_settings_btn from "../../images/buttons/app_settings.png"
import useAuth, { auth_groups } from "../../components/AmplifyAuth/useAuth"
import _ from "lodash"

import liver_logo from "../../images/Liver_Capital_icon_large_transparent.png"

const app_buttons = [
  {
    to: "quant",
    label: "PM",
    img: app_pm_btn,
    authFn: auth_groups.IS_PM,
  },
  {
    to: "quant",
    label: "Quant",
    img: app_quant_btn,
    authFn: auth_groups.IS_QUANT,
  },

  // {
  //   to: "reporting",
  //   label: "Reporting",
  //   img: app_reporting_btn,
  //   authFn: auth_groups.IS_SUPER_ADMIN,
  // },

  {
    to: "resources",
    label: "Resources",
    img: app_checklist_btn,
    authFn: auth_groups.IS_QUANT,
  },
  {
    to: "profile",
    label: "Profile",
    img: app_profile_btn,
    authFn: () => true,
  },
  {
    to: "quant/admin",
    label: "Admin",
    img: app_settings_btn,
    authFn: auth_groups.IS_ADMIN,
  },
]

export const AppNavMenu = () => {
  const auth = useAuth()
  return (
    <Nav>
      <Nav.Menu
        icon={<img src={liver_logo} alt="Liver Capital" width={25} />}
        placement="topStart"
        noCaret
      >
        {_.orderBy(app_buttons, ["badge"], ["desc"]).map(
          ({ to, img, label, authFn }, inx) =>
            authFn(auth) && (
              <Nav.Item
                key={inx}
                as={NavLink}
                to={to}
                icon={<img src={img} width={30} alt={label} />}
              >
                {label}
              </Nav.Item>
            )
        )}
      </Nav.Menu>
    </Nav>
  )
}
