import React, { useEffect } from "react"
import { Link, Outlet, useParams } from "react-router-dom"
import PortfolioHeader from "../connected/PortfolioHeader"
import { usePortfolio } from "../lib/usePortfolio"
import { Nav, Navbar, Panel, Stack } from "rsuite"
import PortfolioExtraInfo from "../connected/PortfolioExtraInfo"
import { AiOutlineHome } from "react-icons/ai"
import { Icon } from "@rsuite/icons"
import GearIcon from "@rsuite/icons/Gear"
import { useTheme } from "@aws-amplify/ui-react"
import PortStatus from "../components/PortStatus"
import DrawerComp from "../../../components/common/DrawerComp"
import MetaDataSettings from "../Settings/controls/MetaDataSettings"
import LeverageSettings from "../Settings/controls/LeverageSettings"
import PortfolioHealthControls from "../Settings/controls/PortfolioHealthControls"
import ModelDataSettings from "../Settings/controls/ModelDataSettings"
import useAuth from "../../../components/AmplifyAuth/useAuth"
import ControlsNavMenu from "../components/ControlsNavMenu"
import DateFormat from "../../../components/common/DateFormat"
import moment from "moment"
import PortfolioNav from "../connected/PortfolioNav"
import FullScreenAlert from "../../../components/alerts/FullScreenAlert"
import ReactGA from "react-ga4"

const PortfolioContentLayout = () => {
  // const location = useLocation()
  const { managerSlug, portfolioSlug } = useParams()
  const {
    data: portfolio,
    isLoading,
    isError,
  } = usePortfolio(managerSlug, portfolioSlug)
  const { tokens } = useTheme()
  const mockPortfolio = { metaData: {}, type: "none" }
  let port = isLoading ? mockPortfolio : portfolio
  const { userMeta } = useAuth()

  if (isError) {
    port = {
      displayName: "No Portfolio",
      portfolioManager: "N/A",
      type: "none",
      metaData: {
        lastPNLDate: false,
      },
    }
  }

  const lastPnl = moment(port.metaData?.lastPNLDate)
  const today = moment()
  var duration = lastPnl.diff(today, "days")
  // console.log(location)
  useEffect(() => {
    if (port.type !== "none" && !!port.portfolioManager && !!port.displayName) {
      ReactGA.send({
        hitType: "pageview",
        title: `${port.portfolioManager} ${port.displayName}`,
      })
    }
  }, [port])

  return (
    <Panel bodyFill>
      <Navbar>
        <Nav>
          <Nav.Item
            as={Link}
            to="/quant"
            style={{
              color: tokens.colors.neutral[40],
              fontSize: 20,
              padding: 10,
            }}
          >
            <Icon as={AiOutlineHome} />
          </Nav.Item>
          <Nav.Item
            as={Stack}
            alignItems="flex-start"
            justifyContent="center"
            style={{ padding: 4 }}
            icon={<PortStatus status={port?.type} size="1.5em" />}
          >
            <div>
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  lineHeight: 0.5,
                  color: tokens.colors.neutral[20],
                }}
              >
                {port.displayName}
              </div>
              <Link
                to={`/quant/${port.portfolioManager}`}
                style={{
                  color: tokens.colors.neutral[40],
                  textDecoration: "none",
                  fontSize: 12,
                }}
              >
                {port.portfolioManager}
              </Link>
            </div>
          </Nav.Item>
          {duration < 0 && (
            <Nav.Item as={Stack} spacing={3}>
              <span
                className="no_mobile"
                style={{
                  color: Math.abs(duration) >= 3 ? "red" : "inherit",
                  fontWeight: "bold",
                }}
              >
                Last PnL {moment.duration(duration, "days").humanize(true)}:
              </span>
              <DateFormat date={port.metaData.lastPNLDate} format="DD-MMM-YY" />
            </Nav.Item>
          )}
        </Nav>

        <Nav pullRight>
          <Nav.Item
            as={PortfolioExtraInfo}
            portfolio={port}
            isLoading={isLoading}
          />
          <Nav.Menu icon={<GearIcon />} placement="leftStart">
            <ControlsNavMenu />
            {userMeta?.isAdmin && (
              <Nav.Menu
                icon={<GearIcon />}
                placement="leftStart"
                openDirection="start"
              >
                <Nav.Item>
                  <DrawerComp title="Meta Settings" label="Meta" size="xs">
                    {({ handleClose }) => (
                      <MetaDataSettings
                        port={portfolio}
                        handleClose={handleClose}
                      />
                    )}
                  </DrawerComp>
                </Nav.Item>
                <Nav.Item>
                  <DrawerComp
                    title="Leverage Settings"
                    label="Leverage"
                    size="xs"
                  >
                    {({ handleClose }) => (
                      <LeverageSettings
                        port={portfolio}
                        handleClose={handleClose}
                      />
                    )}
                  </DrawerComp>
                </Nav.Item>
                <Nav.Item>
                  <DrawerComp title="Health Settings" label="Health" size="xs">
                    {({ handleClose }) => (
                      <PortfolioHealthControls
                        port={portfolio}
                        handleClose={handleClose}
                      />
                    )}
                  </DrawerComp>
                </Nav.Item>
                <Nav.Item>
                  <DrawerComp title="Model Settings" label="Model" size="md">
                    {({ handleClose }) => (
                      <ModelDataSettings
                        port={portfolio}
                        handleClose={handleClose}
                      />
                    )}
                  </DrawerComp>
                </Nav.Item>
              </Nav.Menu>
            )}
          </Nav.Menu>
        </Nav>
        <PortfolioNav port={port} />
      </Navbar>
      <Panel bodyFill>
        <PortfolioHeader metaData={port?.metaData} />
      </Panel>
      <Outlet context={{ portfolio: port }} />
      <FullScreenAlert
        visibility={
          Math.abs(duration) > 3 ? (Math.abs(duration) / 20) * 100 : 0
        }
      />
    </Panel>
  )
}

export default PortfolioContentLayout
