export const metagoraPortfolioSlippageData = /* GraphQL */ `
  query MetagoraPortfolioSlippageData($input: MetagoraPortfolioInput) {
    metagoraPortfolioSlippageData(input: $input) {
      id
      name
      displayName
      slug
      portfolioManager
      description
      account {
        broker
        accountNum
        accountName
        __typename
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
        dailyReturnThreshold
        __typename
      }
      status
      type
      lastSync
      slippageEndpoint
      slippage {
        data {
          period
          daily_slippage_pct
          cumulative_slippage_pct
          daily_commission_pct
          cumulative_commission_pct
          ann_slippage_3_month_avg_pct
          ann_commission_3_month_avg_pct
          __typename
        }
        summary {
          total_cumulative_slippage_pct
          avg_daily_slippage_pct
          avg_calendar_daily_slippage_pct
          __typename
        }
        formats
        __typename
      }
      positionsEndpoint
      metaData {
        lastPNLDate
        todaysPNL
        todaysPNLValue
        yesterdaysPNLValue
        monthToDate
        quarterToDate
        yearToDate
        cumulativeReturnCash
        exposure
        todays_drawdown
        cashLevel
        leverageSetting
        currentLeverage
        marginMaintenance
        marginMaintenancePortfolioValue
        sharpeRatio
        highWater
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_cumulative_slippage_pct
        avg_daily_slippage_pct
        avg_calendar_daily_slippage_pct
        total_number_of_pnl_days
        total_number_of_slippage_days
        total_number_of_positions
        total_number_of_commodities
        total_number_of_unofficial_days
        __typename
      }
      summaryType
      pnlType
      leverage
      leverageToDisplay
      minDollarPositionValueToShow
      cashLevel
      leverageSetting
      dailyReturnThreshold
      maxMarginPerPosition
      maxMarginPerPortfolio
      health {
        position_differences
        traded_differences
        unofficial_numbers
        daily_return_warning
        max_position_by_portfolio
        max_position_by_position
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`
