import React from "react"
import { Heading, Stack, Text } from "rsuite"
import useAuth from "../../../components/AmplifyAuth/useAuth"

const ReportingHeader = ({ title }) => {
  const { userMeta } = useAuth()

  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      style={{ padding: "0.5rem" }}
    >
      <Stack.Item>
        <Heading level={4}>{title}</Heading>
        <Text size="sm" style={{ color: "#828387" }}>
          {userMeta?.groups.join(", ")}
        </Text>
      </Stack.Item>
      <Stack alignItems="flex-end" direction="column">
        <Text size="md">{userMeta?.email}</Text>
        <Text size="sm" style={{ color: "#828387" }}>
          {userMeta?.username}
        </Text>
      </Stack>
    </Stack>
  )
}

export default ReportingHeader
