import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import managers_reducer from "../../Managers/lib/managers_reducer"
import useAuth from "../../../components/AmplifyAuth/useAuth"
import { metagoraDashboardData } from "./graphql"
import { generateClient } from "aws-amplify/api"

const REFETCH_INTERVAL = 1000 * 60 * 3

const client = generateClient()
const GraphQL_API = (query, variables = {}) => {
  return client.graphql({ query, variables })
}

const getDashboardData = async (managerSlug, userMeta) => {
  // console.log(managerSlug, userMeta.access)
  try {
    const { data } = await GraphQL_API(metagoraDashboardData, {
      input: { managerSlug },
      filter: userMeta.access,
    })
    if (!data.metagoraDashboardData || !data.metagoraDashboardData.managers) {
      throw new Error("No Data")
    }
    return data.metagoraDashboardData
  } catch (e) {
    console.log(e)
    throw e
  }
}

const getDashboardQuery = (managerSlug, userMeta, autoRefetch = true) => ({
  queryKey: ["dashboard", managerSlug ?? "all"],
  queryFn: () => getDashboardData(managerSlug, userMeta),
  refetchInterval: autoRefetch ? REFETCH_INTERVAL : false,
})

export const useDashboard = (filterFn, autoRefetch = true) => {
  const { managerSlug } = useParams()
  const auth = useAuth()
  const query = useQuery({
    ...getDashboardQuery(managerSlug, auth.userMeta, autoRefetch),
    select: (d) => ({
      totals: d.totals,
      ...managers_reducer(d.managers, filterFn),
    }),
    enabled: !!auth.userMeta,
  })

  return query
}
