import React from "react"
import { DatePicker } from "rsuite"
import moment from "moment"
import { updateFormValue } from "../controls/lib/utils"

// Reusable DatePicker component
const CustomDatePicker = ({
  label,
  match,
  setFormValue,
  keyName,
  queryName,
}) => {
  const dateValue = match ? new Date(moment(match[1]).format()) : null
  return (
    <DatePicker
      label={label}
      style={{ width: 200, display: "block", marginBottom: 10 }}
      oneTap
      value={dateValue}
      onClean={() => {
        if (match) {
          updateFormValue(setFormValue, keyName, match[0], "")
        }
      }}
      onSelect={(e) => {
        const date = moment(e).format("yyyy-MM-D")
        setFormValue((fv) => ({
          ...fv,
          [keyName]: fv[keyName] + `&${queryName}=${date}`,
        }))
      }}
    />
  )
}

export default CustomDatePicker
