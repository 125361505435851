import { formats } from "../../../../../lib/utils"
import {
  cell_format,
  cell_raw_format,
  style_by_value,
} from "../../../../../components/common/DataTable/utils"
import leverageRowStyle from "./leverageRowStyle.js"
import { Icon } from "@rsuite/icons"
import { RiAlertFill } from "react-icons/ri"
import { Tooltip, Whisper } from "rsuite"

export const weekly_pnl_data = () => [
  {
    label: "Period",
    abbr_label: "P",
    field: "period",
    sortable: true,
    formatFn: cell_raw_format(formats.date("YY-MMM-DD")),
    width: 90,
    fixed: true,
  },
  {
    label: "Portfolio Value",
    abbr_label: "PV",
    field: "portfolio_value",
    formatFn: cell_raw_format(formats.currency),
    width: 100,
    fixed: true,
  },
  {
    label: "ABS Return",
    abbr_label: "AR",
    field: "abs_return",
    format: "USD",
  },
  {
    label: "Cumulative Return",
    abbr_label: "CR",
    field: "rebased_return",
    format: "USD",
  },
  {
    label: "Cumulative Return",
    abbr_label: `CR`,
    field: "cumulative_return_pct",
    formatFn: cell_format(formats.pct),
    width: 60,
  },
  {
    label: "Return Cash",
    abbr_label: "RC",
    field: "return_cash",
    formatFn: cell_format(formats.pct),
    width: 60,
  },
  {
    label: "Leverage",
    abbr_label: "L",
    field: "leverage_cash",
    format: "leverage",
  },
]

const pnl_data_config = ({ leverageToDisplay, leverage, compact }) => [
  {
    label: "Period",
    abbr_label: "P",
    field: "period",
    sortable: true,
    formatFn: (rowData, dataKey) => formats.date("DD-MMM-YY")(rowData[dataKey]),
    width: 70,
    fixed: true,
  },
  {
    label: "Portfolio Value",
    abbr_label: "PV",
    field: "portfolio_value",
    formatFn: (rowData, dataKey) => (
      <span style={{ position: "relative" }}>
        {rowData.deposit > 0 ? (
          <Whisper
            speaker={
              <Tooltip
                placement="top"
                trigger="hover"
                style={{
                  color: "rgba(4, 255, 4, .9)",
                  backgroundColor: "rgb(50,50,50)",
                  fontWeight: "bold",
                }}
              >
                {compact
                  ? formats.us_abbr(rowData.deposit)
                  : formats.currency(rowData.deposit)}{" "}
                Deposit
              </Tooltip>
            }
          >
            <span
              style={{
                color: "rgba(4, 255, 4, .9)",
                position: "absolute",
                left: -15,
              }}
            >
              D
            </span>
          </Whisper>
        ) : null}
        {rowData.deposit < 0 ? (
          <Whisper
            speaker={
              <Tooltip
                placement="top"
                trigger="hover"
                style={{
                  color: "rgba(255, 80, 0, .9)",
                  backgroundColor: "rgb(50,50,50)",
                  fontWeight: "bold",
                }}
              >
                {compact
                  ? formats.us_abbr(rowData.deposit)
                  : formats.currency(rowData.deposit)}{" "}
                Withdraw
              </Tooltip>
            }
          >
            <span
              style={{
                color: "rgba(255, 80, 0, .9)",
                position: "absolute",
                left: -15,
              }}
            >
              W
            </span>
          </Whisper>
        ) : null}
        {compact
          ? formats.us_abbr(rowData[dataKey])
          : formats.currency(rowData[dataKey])}
      </span>
    ),
    width: compact ? 65 : 100,
    sortable: true,
  },
  {
    label: "Daily PNL",
    abbr_label: "D$",
    field: "daily_return",
    formatFn: cell_format(compact ? formats.us_abbr : formats.currency),
    width: compact ? 55 : 85,
    sortable: true,
  },
  {
    label: "Cumulative PNL",
    abbr_label: "C$",
    field: "cumulative_return",
    formatFn: cell_format(compact ? formats.us_abbr : formats.currency),
    width: compact ? 55 : 90,
    sortable: true,
  },
  {
    label: "Daily Return",
    abbr_label: "DR",
    field: "daily_return_pct",
    formatFn: cell_format(formats.pct),
    width: 50,
    sortable: true,
  },
  {
    label: "Cumulative Return",
    abbr_label: `CR`,
    field: "cumulative_return_pct",
    formatFn: cell_format(formats.pct),
    width: 50,
    sortable: true,
  },
  {
    label: "Return Cash",
    abbr_label: "RC",
    field: "return_cash",
    formatFn: cell_format(formats.pct),
    width: 50,
    sortable: true,
  },
  {
    label: "Cumulative Return Cash",
    abbr_label: "CRC",
    field: "cumulative_return_cash",
    formatFn: cell_format(formats.pct),
    sortable: true,
    width: 50,
  },
  {
    label: "Max Drawdown",
    abbr_label: "MDD",
    field: "pct_drawdown",
    formatFn: cell_format((d) => formats.pct(d * 100), true, false, true),
    width: 50,
    sortable: true,
  },
  {
    label: "Time To Recovery",
    abbr_label: "TTR",
    field: "drawdown_days",
    formatFn: cell_format(formats.number, true, false, false, true),
    width: 40,
    sortable: true,
  },
  {
    label:
      leverageToDisplay === "REALIZED_LEVERAGE"
        ? "Realized Leverage"
        : leverageToDisplay === "LEVERAGE_CASH"
        ? "Leverage"
        : "Leverage",
    abbr_label:
      leverageToDisplay === "REALIZED_LEVERAGE"
        ? "RL"
        : leverageToDisplay === "LEVERAGE_CASH"
        ? "L"
        : "L",
    field:
      leverageToDisplay === "REALIZED_LEVERAGE"
        ? "realized_leverage"
        : leverageToDisplay === "LEVERAGE_CASH"
        ? "leverage_cash"
        : "leverage",
    format: "leverage",
    formatFn: style_by_value(formats.float_2, (value) =>
      leverageRowStyle(value, leverage, 3)
    ),
    width: 35,
  },
  {
    label: "",
    abbr_label: "",
    field: "official",
    formatFn: (r, f) =>
      r[f] ? (
        ""
      ) : (
        <Whisper
          speaker={
            <Tooltip
              placement="top"
              trigger="hover"
              style={{
                fontWeight: "bold",
              }}
            >
              Unofficial
            </Tooltip>
          }
        >
          <span>
            <Icon as={RiAlertFill} />
            {` `}
            {compact ? "" : "unofficial"}
          </span>
        </Whisper>
      ),
    width: compact ? 30 : 70,
  },
]

export default pnl_data_config
