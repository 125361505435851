import React from "react"
import {
  Col,
  Heading,
  IconButton,
  Modal,
  Row,
  Stack,
  Text,
  Toggle,
} from "rsuite"
import PagePreviousIcon from "@rsuite/icons/PagePrevious"
import ManagersList from "./ManagerList"
import ManagerForm from "./ManagerForm"
import useAuth from "../../../components/AmplifyAuth/useAuth"

import styles from "../components/Managers.module.css"

const ManagerEditModal = ({
  isManagerSettingsOpen,
  setIsManagerSettingsOpen,
  setIsModalSortingEnabled,
  isModalSortingEnabled,
  filteredManagers,
  setModalSelectedManager,
  modalSelectedManager,
}) => {
  const { userMeta } = useAuth()

  return (
    <Modal
      keyboard={false}
      open={isManagerSettingsOpen}
      onClose={() => setIsManagerSettingsOpen(false)}
      size="lg"
    >
      <Modal.Header />

      <Modal.Body>
        <Row gutter={20}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={10}
            xl={10}
            style={{ marginBottom: "10px" }}
          >
            <Row style={{ marginBottom: "10px" }}>
              <Col xs={24}>
                <Stack alignItems="center" justifyContent="space-between">
                  <div>
                    <Heading level={6} style={{ color: "orange" }}>
                      Managers
                    </Heading>
                  </div>
                  <Stack alignItems="center" spacing={10}>
                    <Text size="sm" style={{ marginTop: "3px" }}>
                      Enable Sorting
                    </Text>
                    <Toggle
                      defaultChecked
                      size="sm"
                      onChange={setIsModalSortingEnabled}
                    />
                  </Stack>
                </Stack>
              </Col>
            </Row>
            <div className={styles.managerPortfolioSettingsMobile}>
              {!modalSelectedManager && (
                <ManagersList
                  isSortingEnabled={isModalSortingEnabled}
                  key={filteredManagers?.length}
                  managers={filteredManagers}
                  isFromModal
                  setModalSelectedManager={setModalSelectedManager}
                  modalSelectedManager={modalSelectedManager}
                />
              )}
            </div>
            <div className={styles.managerPortfolioSettingsDesktop}>
              <ManagersList
                isSortingEnabled={isModalSortingEnabled}
                key={filteredManagers?.length}
                managers={filteredManagers}
                isFromModal
                setModalSelectedManager={setModalSelectedManager}
                modalSelectedManager={modalSelectedManager}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            {modalSelectedManager && (
              <IconButton
                className={styles.managerPortfolioSettingsMobile}
                onClick={() => setModalSelectedManager(null)}
                circle
                icon={<PagePreviousIcon />}
                appearance="default"
              />
            )}
            {modalSelectedManager && (
              <ManagerForm
                key={modalSelectedManager.id}
                manager={modalSelectedManager}
                isAdmin={userMeta?.isAdmin}
              />
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default ManagerEditModal
