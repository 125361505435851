export const value_format =
  (
    formatFn,
    positiveZero = false,
    reverse = false,
    neutralNegative = false,
    neutralPositive = false
  ) =>
  (value) => {
    if (typeof value === "string") return value
    if (value === undefined || isNaN(value)) return "--"
    let className = ""
    if (value < 0) {
      className = neutralNegative
        ? "neutral"
        : reverse
        ? "positive"
        : "negative"
    } else if (value > 0) {
      className = neutralPositive
        ? "neutral"
        : reverse
        ? "negative"
        : "positive"
    } else if (value === 0 || value === "0.00%" || value === null) {
      className = positiveZero ? "positive" : "neutral"
      // console.log(className)
    }
    return <span className={className}>{formatFn(value)}</span>
  }

export const cell_raw_format = (formatFn) => (rowData, field) => {
  const value = field ? rowData[field] : rowData
  if (
    (isNaN(value) && typeof value !== "string") ||
    value === null ||
    value === undefined
  )
    return "--"
  let className = "data-value"
  if (value === 0 || value === "0.00%") {
    className = "neutral"
  }
  return <div className={className}>{formatFn(value)}</div>
}

export const cell_format =
  (
    formatFn,
    positiveZero = false,
    reverse = false,
    neutralNegative = false,
    neutralPositive = false
  ) =>
  (rowData, field) => {
    const value = field ? rowData[field] : rowData
    return value_format(
      formatFn,
      positiveZero,
      reverse,
      neutralNegative,
      neutralPositive
    )(value)
  }

export const bool_bkgrn_color_change =
  (changed_field, color, changeFn) => (formatFn) => (rowData, field) => {
    // Extract the value based on whether a field is provided.
    const value = field ? rowData[field] : rowData

    // Determine whether the value has changed based on the provided change function.
    const isChanged = changeFn
      ? changeFn(rowData[changed_field])
      : rowData[changed_field]

    // Set className based on the value.
    const className = value === 0 ? "neutral" : ""

    // Determine the background color based on whether the value has changed and is not 0.
    const backgroundColor = isChanged && Math.abs(value) > 0 ? color : undefined

    // Return the React element.
    return (
      <div className={className} style={{ backgroundColor }}>
        {formatFn(value)}
      </div>
    )
  }

const getClassName = (value, diff) => {
  switch (true) {
    case value === 0 || diff === 0:
      return "neutral"
    case diff > 0:
      return "positive"
    default:
      return "negative"
  }
}

export const color_by_diff_field =
  (diff_field) => (formatFn) => (rowData, field) => {
    const value = field ? rowData[field] : rowData
    const diff = rowData[diff_field] || 0
    const className = getClassName(value, diff)

    return <div className={className}>{formatFn(value, diff)}</div>
  }
export const style_by_value = (formatFn, valueStyleFn) => (rowData, field) => {
  const value = rowData[field]
  return <div style={valueStyleFn(value)}>{formatFn(value)}</div>
}

export const style_by_diff_field =
  (diff_field, defaultValue = 0) =>
  (formatFn, valueStyleFn) =>
  (rowData, field) => {
    const value = field ? rowData[field] : rowData
    const diff = rowData[diff_field] || defaultValue

    return <div style={valueStyleFn(diff)}>{formatFn(value)}</div>
  }

export const official_pnl_color = (cell_comp) => (rowData, field) => {
  const official = rowData["official"]
  return (
    <div style={{ backgroundColor: official ? "" : "#000080" }}>
      {cell_comp(rowData, field)}
    </div>
  )
}

/**
 * Function to create a React component styled by a value given a formatting function, a style function, and an optional 'diff' field
 * @param {string} diffField - Optional 'diff' field to style by
 * @param {any} defaultValue - The default value if diffField is not in rowData
 * @returns {Function} - Function that returns a React component
 */
export const styleByValueOrDiffField =
  (diffField = null, defaultValue = null) =>
  (formatFn, valueStyleFn) =>
  (rowData, field) => {
    // Ensure rowData is an object and field is a string
    if (typeof rowData !== "object" || typeof field !== "string") {
      throw new Error("Invalid input types")
    }

    const value = field ? rowData[field] : rowData
    const diffValue =
      diffField && rowData.hasOwnProperty(diffField)
        ? rowData[diffField]
        : defaultValue

    // Return a React component
    return () => <div style={valueStyleFn(diffValue)}>{formatFn(value)}</div>
  }
