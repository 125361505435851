import { useMutation } from "@tanstack/react-query"
import React from "react"
import {
  Button,
  ButtonToolbar,
  Form,
  InputNumber,
  Schema,
  SelectPicker,
} from "rsuite"

const leverageModel = Schema.Model({
  leverageToDisplay: Schema.Types.StringType().isRequired(
    "Select a Leverage Type"
  ),
  leverage: Schema.Types.NumberType().min(1, "Should be at least 1"),
  minDollarPositionValueToShow: Schema.Types.NumberType().min(
    1,
    "Should be at least 1"
  ),
})

const LEVERAGE_CHOICES = ["LEVERAGE", "REALIZED_LEVERAGE", "LEVERAGE_CASH"]

const LeverageSettings = ({ port, handleClose = () => {} }) => {
  const formRef = React.useRef()
  const [formError, setFormError] = React.useState({})
  const [formValue, setFormValue] = React.useState({
    id: port.id,
    leverageToDisplay: port.leverageToDisplay || LEVERAGE_CHOICES[0],
    leverage: port.leverage || 1,
    minDollarPositionValueToShow: port.minDollarPositionValueToShow || 25,
  })

  const mutation = useMutation({
    mutationKey: ["updatePortfolio"],
  })

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      return
    }
    mutation.mutate(
      {
        ...formValue,
        leverage: +formValue.leverage,
        minDollarPositionValueToShow: +formValue.minDollarPositionValueToShow,
      },
      {
        onSuccess: handleClose,
      }
    )
  }

  return (
    <Form
      ref={formRef}
      model={leverageModel}
      formValue={formValue}
      onCheck={setFormError}
      onChange={setFormValue}
    >
      <Form.Group controlId="leverageToDisplay">
        <Form.Control
          name="leverageToDisplay"
          label="Leverage To Display"
          accepter={SelectPicker}
          data={LEVERAGE_CHOICES.map((opt) => ({
            label: opt,
            value: opt,
          }))}
          errorMessage={formError.leverageToDisplay}
        />
      </Form.Group>
      <Form.Group controlId="leverage">
        <Form.ControlLabel>Leverage</Form.ControlLabel>
        <Form.Control
          name="leverage"
          accepter={InputNumber}
          errorMessage={formError.leverage}
        />
      </Form.Group>
      <Form.Group controlId="minDollarPositionValueToShow">
        <Form.ControlLabel>Min Dollar Position Value To Show</Form.ControlLabel>
        <Form.Control
          name="minDollarPositionValueToShow"
          accepter={InputNumber}
          errorMessage={formError.minDollarPositionValueToShow}
        />
      </Form.Group>
      <ButtonToolbar>
        <Button
          appearance="primary"
          onClick={handleSubmit}
          loading={mutation.isLoading}
        >
          Save
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </ButtonToolbar>
    </Form>
  )
}

export default LeverageSettings
