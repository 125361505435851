import { createTheme, defaultDarkModeOverride } from "@aws-amplify/ui-react"

const RED_COLOR = "rgba(255, 0, 0, 0.7)"
const RED_NUMBERS = "rgba(255, 0, 0, .7)"
// const RED_NUMBERS = "red"

const GREEN_NUMBERS = "rgba(2, 173, 2, .8)"
// const GREEN_COLOR = "rgba(2, 173, 2, 0.5)"
const GREEN_COLOR = GREEN_NUMBERS
const DARK_GREEN_COLOR = "rgba(4, 255, 4, 1)"

const valueColor = (num) => {
  const just_the_num = /(-?[0-9.]*)/g.exec(num)[0]
  const v = just_the_num === "" ? num : Number(just_the_num)
  return typeof v == "number"
    ? v !== 0
      ? v >= 0
        ? GREEN_NUMBERS
        : RED_NUMBERS
      : DARK_GREEN_COLOR
    : "rgba(255,255,255,0.4)"
}

const zeroColor = (num, color) => {
  const v = parseFloat(num)
  return isNaN(v) || v === 0 ? "rgba(255,255,255,0.4)" : color
}

function hexToRgbA(hex, alpha = 1) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      alpha +
      ")"
    )
  }
  throw new Error("Bad Hex")
}

function getHexColor(value) {
  // Ensure the value is between 1 and 10
  if (value < 0) value = 0
  if (value > 10) value = 10
  // if (value < 0 || value > 10) {
  //   throw new Error("Value must be between 0 and 10")
  // }

  // Map the value to a range between 0 and 255 (00 to FF in hex)
  // If value is 10, it should be mapped to 255, if 1 then to 0.
  const blueValue = Math.round((value - 1) * (255 / 9))
    .toString(16)
    .padStart(2, "0")

  // Return the color in hex format, with blueValue converted to hexadecimal
  return `#0000${blueValue}`
}

function getRedHexColor(value) {
  // Ensure the value is between 1 and 10
  // if (value < 0 || value > 10) {
  //   throw new Error("Value must be between 0 and 10")
  // }
  if (value < 0) value = 0
  if (value > 10) value = 10

  // Map the value to a range between 0 and 255 (00 to FF in hex)
  // If value is 10, it should be mapped to 255, if 1 then to 0.
  const redValue = Math.round((value - 1) * (255 / 9))
    .toString(16)
    .padStart(2, "0")

  // Return the color in hex format, with redValue converted to hexadecimal
  return `#${redValue}0000`
}

export const metagoraTheme = createTheme({
  name: "metagora-theme",
  overrides: [defaultDarkModeOverride],
  tokens: {
    fonts: {
      default: {
        variable: { value: "Lato, sans-serif" },
        static: { value: "Lato, sans-serif" },
      },
    },
    components: {
      // selectfield: {
      //   color: { value: "{colors.font.secondary}" },
      //   fontSize: { value: "{fontSizes.large}" },
      //   borderColor: { value: "{colors.neutral.60}" },
      // },
      loader: {
        strokeEmpty: { value: "{colors.neutral.20}" },
        strokeFilled: { value: "{colors.blue.80}" },

        // sizes
        large: {
          width: { value: "{fontSizes.xxxl}" },
          height: { value: "{fontSizes.xxxl}" },
        },

        // linear loader
        linear: {
          width: { value: "50%" },
          strokeWidth: { value: "{fontSizes.xxs}" },
          strokeFilled: { value: "{colors.brand.secondary.80}" },
          strokeEmpty: { value: "{colors.neutral.20}" },
          animationDuration: { value: "2s" },
        },
      },
      link: {
        color: { value: "{colors.font.secondary}" },
        focus: {
          color: { value: "{colors.font.secondary}" },
        },
        hover: {
          color: { value: "{colors.neutral.60}" },
        },
        visited: {
          backgroundColor: { value: "{colors.neutral.80}" },
          color: { value: "{colors.font.secondary}" },
          textDecoration: { value: "underline" },
        },
      },
      button: {
        link: {
          margin: {
            value: "{space.large}",
          },
          backgroundColor: { value: "{colors.background.primary}" },
          color: { value: "{colors.font.secondary.value}" },
          _hover: {
            backgroundColor: { value: "{colors.background.primary}" },
            color: { value: "{colors.neutral.80}" },
          },
          _focus: {
            backgroundColor: { value: "{colors.neutral.80}" },
          },
          _visited: {
            backgroundColor: { value: "{colors.blue.90}" },
            textDecoration: { value: "underline" },
          },
        },
      },
    },
    colors: {
      border: {
        // this will affect the default button's border color
        link: { value: "black" },
      },
      font: {
        primary: {
          value: "rgba(245, 246, 250,1.0)",
        },
        secondary: {
          value: "rgba(220, 221, 225,1.0)",
        },
        muted: {
          value: "rgba(127, 143, 166,1.0)",
        },
      },
      icons: {
        PRODUCTION: {
          value: "rgb(255 215 0)",
          // value: "rgba(255, 195, 18,1.0)",
        },
        BENCHMARK: {
          value: "{colors.purple[60]}",
        },
        EXPERIMENTAL: {
          value: "rgba(6, 82, 221,1.0)",
        },
        MODEL: {
          value: "rgba(18, 203, 196,1.0)",
        },
        DEVELOPMENT: {
          value: "rgba(18, 203, 196,1.0)",
        },
        PAPER: {
          value: "rgba(131, 52, 113,1.0)",
        },
        LEGACY: {
          value: "rgba(131, 52, 113,1.0)",
        },
        DRAFT: {
          value: "{colors.neutral[80]}",
        },

        LIVE: {
          value: GREEN_COLOR,
        },
        PAUSED: {
          value: "{colors.neutral[80]}",
        },

        historical: {
          value: "rgba(68, 189, 50,1.0)",
        },
        dashboard: {
          value: "rgba(0, 168, 255,1.0)",
        },
        slippage: {
          value: "rgba(225, 177, 44,1.0)",
        },
        tradeserver: {
          value: "rgba(0, 98, 102,1.0)",
        },
        auto_reconciliation: {
          value: "rgba(0, 98, 102,1.0)",
        },
        postrack: {
          value: "{colors.orange[80]}",
        },
      },
      milestone: ["blue", "green", "red", "orange", "yellow", "violet"],
      metagora: {
        value_color: {
          value: valueColor,
        },
        zero_color: {
          value: zeroColor,
        },
        hex_to_rgba: {
          value: hexToRgbA,
        },
        getRedHexColor: {
          value: getRedHexColor,
        },
        getHexColor: {
          value: getHexColor,
        },
        chart_axis_stroke: {
          value: "{colors.neutral[40]}",
        },
        white: {
          value: "rgba(245, 246, 250,1.0)",
        },
        blue: {
          value: "rgba(39, 60, 117,1.0)",
        },
        green: {
          value: GREEN_COLOR,
        },
        green_chart: {
          value: "rgba(2, 173, 2, .8)",
        },
        benchmark_chart: {
          value: "rgba(99, 4, 125, .6) ",
        },
        benchmark_chart_stroke: {
          value: "rgba(255, 0, 255, .6) ",
        },
        red: {
          value: RED_COLOR,
        },
        unofficial_blue: {
          value: "#000080",
          // value: "{colors.blue[80]}",
        },
        unofficial_blue_chart: {
          value: "#000080",
          // value: "{colors.blue[60]}",
          // value: "rgba(220, 95, 30, 1)",
        },
        unofficial_blue_health: {
          // value: "#000080",
          value: "{colors.blue[60]}",
          // value: "rgba(220, 95, 30, 1)",
        },
        leverage: {
          value: "rgba(225, 177, 44, 0.6)",
        },
        target_leverage: {
          value: "{colors.blue[40]}",
        },
        cumulative: {
          value: "rgb(0, 136, 254)",
        },
        ann_slippage: {
          value: "{colors.teal[60]}",
        },
        ann_commission: {
          value: "{colors.blue[60]}",
        },
        time_to_recovery: {
          value: "rgba(238, 90, 3, .6)",
        },
        drawdown_pct: {
          value: "{colors.teal[80]}",
        },
        bg_info: {
          value:
            "linear-gradient(200deg, rgb(17, 24, 51) 0%, rgba(18, 135, 0, 0.1) 100%)",
        },
        bg_danger: {
          value:
            "linear-gradient(200deg, rgb(51, 17, 30) 0%, rgba(18, 135, 0, 0.1) 100%)",
        },
        bg_success: {
          value:
            "linear-gradient(200deg, rgb(17, 24, 51) 0%, rgba(18, 135, 0, 0.1) 100%)",
        },
        bg_warning: {
          value:
            "linear-gradient(200deg, rgb(17, 24, 51) 0%, rgba(18, 135, 0, 0.1) 100%)",
        },
      },
      background: {
        disabled: {
          value: "{colors.background.primary}",
        },
        primary: {
          value: "rgba(26, 25, 25, 0.9)",
        },
        secondary: {
          value: "rgba(26, 25, 25, 0.4)",
        },
        historical: {
          value: "rgba(68, 189, 50,1.0)",
        },
        dashboard: {
          value: "rgba(0, 168, 255,1.0)",
        },
        slippage: {
          value: "rgba(225, 177, 44,1.0)",
        },
        auto_reconciliation: {
          value: "rgba(0, 98, 102,1.0)",
        },
        postrack: {
          value: "{colors.orange[80]}",
        },
        settings: {
          value: "{colors.neutral[80]}",
        },
        tasks: {
          value: "#3b3b3b",
        },
      },
      charts: {
        key_colors: {
          value: [
            "aqua",
            "rgba(0, 168, 255,1.0)",
            "orange",
            "yellow",
            "violet",
          ],
        },
      },
    },
  },
})
