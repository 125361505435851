import _ from "lodash"
import moment from "moment"

const get_health_count = (health) => {
  const count = _.countBy(Object.values(health), Boolean)
  return count?.true
}

const parse_broker = (broker) => {
  switch (broker) {
    case "interactivebrokers":
      return "IB"
    default:
      return broker
  }
}

const managers_reducer = (managers, filter = (x) => !!x) => {
  const ordered_managers = _.orderBy(managers, ["sortOrder"])
  let total_health = 0
  let total_name_length = 0

  const now = moment()
  const all_managers = ordered_managers.reduce((tot, manager) => {
    const man_ports = manager.portfolios.filter(filter).reduce((all, port) => {
      const pnlAgeInDays = Math.abs(
        moment(port.metaData?.lastPNLDate).diff(now, "days")
      )
      const health_count = !!port.health ? get_health_count(port.health) : 0
      total_health = health_count > total_health ? health_count : total_health
      total_name_length =
        port.displayName.length > total_name_length
          ? port.displayName.length
          : total_name_length
      return [
        ...all,
        {
          ...port,
          account: port.account
            ?.map((acct) => `${parse_broker(acct.broker)}, ${acct.accountNum}`)
            .join(" : "),
          ...port.metaData,
          manager_displayName: manager.displayName,
          manager_name: manager.name,
          manager_slug: manager.slug,
          manager_sortOrder: manager.sortOrder,
          pnlAgeInDays,
        },
      ]
    }, [])
    return [...tot, ...man_ports]
  }, [])

  return { managers: all_managers, total_health, total_name_length }
}

export default managers_reducer
