import { useTheme } from "@aws-amplify/ui-react"
import { useMutation } from "@tanstack/react-query"
import React from "react"
import { RiBlazeFill, RiPercentLine } from "react-icons/ri"
import {
  Button,
  ButtonToolbar,
  Form,
  InputGroup,
  InputNumber,
  Schema,
} from "rsuite"

const MAX_MARGIN_BY_POSITION_THRESHOLD = 10
const MAX_MARGIN_BY_PORTFOLIO_THRESHOLD = 50
const DAILY_RETURN_DEFAULT = 20

const healthModel = Schema.Model({
  dailyReturnThreshold: Schema.Types.NumberType(),
  maxMarginPerPosition: Schema.Types.NumberType(),
  maxMarginPerPortfolio: Schema.Types.NumberType(),
})

const PortfolioHealthControls = ({ port, handleClose }) => {
  const formRef = React.useRef()
  const mutation = useMutation({
    mutationKey: ["updatePortfolio"],
  })
  const [formValue, setFormValue] = React.useState({
    id: port.id,
    dailyReturnThreshold: port.dailyReturnThreshold || DAILY_RETURN_DEFAULT,
    maxMarginPerPosition:
      port.maxMarginPerPosition || MAX_MARGIN_BY_POSITION_THRESHOLD,
    maxMarginPerPortfolio:
      port.maxMarginPerPortfolio || MAX_MARGIN_BY_PORTFOLIO_THRESHOLD,
  })

  const { tokens } = useTheme()

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      return
    }
    mutation.mutate(
      {
        ...formValue,
        dailyReturnThreshold: +formValue.dailyReturnThreshold,
        maxMarginPerPosition: +formValue.maxMarginPerPosition,
        maxMarginPerPortfolio: +formValue.maxMarginPerPortfolio,
      },
      {
        onSuccess: handleClose,
      }
    )
  }
  return (
    <Form
      fluid
      ref={formRef}
      model={healthModel}
      formValue={formValue}
      onChange={setFormValue}
    >
      <Form.Group controlId="dailyReturnThreshold">
        <Form.ControlLabel>{`Daily Return Threshold (${DAILY_RETURN_DEFAULT})`}</Form.ControlLabel>
        <InputGroup>
          <InputGroup.Addon>
            <RiPercentLine color={tokens.colors.metagora.red} />
          </InputGroup.Addon>
          <Form.Control name="dailyReturnThreshold" accepter={InputNumber} />
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="maxMarginPerPosition">
        <Form.ControlLabel>{`Max Margin Per Position (${MAX_MARGIN_BY_POSITION_THRESHOLD})`}</Form.ControlLabel>
        <InputGroup>
          <InputGroup.Addon>
            <RiBlazeFill color={tokens.colors.metagora.red} />
          </InputGroup.Addon>
          <Form.Control name="maxMarginPerPosition" accepter={InputNumber} />
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="maxMarginPerPortfolio">
        <Form.ControlLabel>{`Max Margin Per Portfolio (${MAX_MARGIN_BY_PORTFOLIO_THRESHOLD})`}</Form.ControlLabel>
        <InputGroup>
          <InputGroup.Addon>
            <RiBlazeFill color={tokens.colors.metagora.red} />
          </InputGroup.Addon>
          <Form.Control name="maxMarginPerPortfolio" accepter={InputNumber} />
        </InputGroup>
      </Form.Group>
      <ButtonToolbar>
        <Button
          appearance="primary"
          onClick={handleSubmit}
          loading={mutation.isLoading}
        >
          Save
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </ButtonToolbar>
    </Form>
  )
}

export default PortfolioHealthControls
