import React, { useState } from "react"
import { Stack, Text } from "rsuite"
import { Icon } from "@rsuite/icons"
import { FaDollarSign } from "react-icons/fa"

const currencyFormatter = new Intl.NumberFormat("en-US", {})

const OverallTotal = ({ aumValue, exposureValue, title }) => {
  const [isShowValue, setIsShowValue] = useState(false)

  const toggleVisibility = () => {
    setIsShowValue((prevState) => !prevState)
  }

  return (
    <>
      <Stack spacing={50}>
        {["aum", "exp"].map((item, index) => (
          <Stack.Item key={index} flex={1}>
            <Text size="sm" style={{ color: "#ffa71e" }}>
              {item.toUpperCase()}
            </Text>
            <Stack
              alignItems="center"
              spacing={5}
              onClick={toggleVisibility}
              style={{ cursor: "pointer" }}
            >
              <Icon
                as={FaDollarSign}
                style={{
                  color: "#828387",
                  marginBottom: "0.3rem",
                }}
              />
              {isShowValue ? (
                <Text size="sm">
                  {item === "aum"
                    ? currencyFormatter.format(aumValue)
                    : currencyFormatter.format(exposureValue)}
                </Text>
              ) : (
                "XXXXXX"
              )}
            </Stack>
          </Stack.Item>
        ))}
      </Stack>
    </>
  )
}

export default OverallTotal
