import React, { useState } from "react"
import {
  Checkbox,
  Heading,
  IconButton,
  Input,
  InputGroup,
  Stack,
  Table,
  Text,
  Tooltip,
  Whisper,
} from "rsuite"

import SearchIcon from "@rsuite/icons/Search"
import { ImAttachment } from "react-icons/im"
import { Icon } from "@rsuite/icons"

import { FaCog } from "react-icons/fa"
import { BsSend } from "react-icons/bs"

import { columnAbbr } from "../lib/report_table.constants"
import { useFilterPortfolios } from "../../../hooks/useFilterPortfolios"

const structureNote = `Alert: \n\nComments: \n\nResolution:`

const formetValue = (number) =>
  new Intl.NumberFormat("en", { style: "currency", currency: "USD" }).format(
    number
  )

const { Column, HeaderCell, Cell } = Table

const ReportManagers = ({
  portfolios,
  setIsSettingsDrawerOpen,
  setNoteModal,
  generateSlackReport,
  handleCheck,
  handleCheckAll,
  customTableColumn,
}) => {
  const [search, setSearch] = useState("")
  const { filteredPortfolios } = useFilterPortfolios(portfolios, search)

  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: "15px", paddingRight: "10px" }}
      >
        <Stack spacing={50} style={{ margin: "10px 0" }}>
          <div>
            <Heading level={6} style={{ color: "orange" }}>
              TEM Reports List
            </Heading>
            <Text size="sm" style={{ color: "#828387" }}>
              List of PNLs and notes
            </Text>
          </div>
        </Stack>
        <Stack spacing={10}>
          <InputGroup inside>
            <Input onChange={setSearch} />
            <InputGroup.Addon>
              <SearchIcon />
            </InputGroup.Addon>
          </InputGroup>
          <Stack spacing={5}>
            <IconButton
              circle
              size="sm"
              icon={<Icon as={BsSend} color="orange" />}
              onClick={generateSlackReport}
            />
            <IconButton
              circle
              size="sm"
              icon={<Icon as={FaCog} color="orange" />}
              onClick={() => setIsSettingsDrawerOpen(true)}
            />
          </Stack>
        </Stack>
      </Stack>

      <Table
        height={400}
        data={filteredPortfolios}
        bordered
        style={{ borderRadius: "10px" }}
      >
        <Column width={50} fixed="left">
          <HeaderCell style={{ padding: 0 }}>
            <div style={{ lineHeight: "40px", padding: "3px 7px " }}>
              <Checkbox
                color="orange"
                onCheckboxClick={(e) => handleCheckAll(e.target.checked)}
                style={{ marginBottom: "20px" }}
              />
            </div>
          </HeaderCell>
          <Cell align="center">
            {(rowData, index) => (
              <div style={{ lineHeight: "46px", position: "absolute", top: 0 }}>
                <Checkbox
                  color="orange"
                  checked={rowData.checked}
                  onCheckboxClick={(e) => handleCheck(e.target.checked, index)}
                />
              </div>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1} width={300} fixed="left" resizable>
          <HeaderCell fixed>
            <Text color="orange">NAME</Text>
          </HeaderCell>
          <Cell dataKey="displayName" />
        </Column>

        <Column width={100}>
          <HeaderCell align="right">
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={
                <Tooltip>
                  <Text> DAILY PNL</Text>
                </Tooltip>
              }
            >
              <Text size="sm" color="orange">
                DPNL
              </Text>
            </Whisper>
          </HeaderCell>
          <Cell dataKey="metaData.todaysPNL" align="right">
            {(rowData) => (
              <Text color={rowData?.metaData?.todaysPNL > 0 ? "green" : "red"}>
                {rowData?.metaData?.todaysPNL?.toFixed(2)}
              </Text>
            )}
          </Cell>
        </Column>
        <Column width={120}>
          <HeaderCell style={{}} align="right">
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={
                <Tooltip>
                  <Text>Return Percent</Text>
                </Tooltip>
              }
            >
              <Text size="sm" color="orange">
                RP
              </Text>
            </Whisper>
          </HeaderCell>

          <Cell align="right">
            {(rowData) => (
              <Text
                color={
                  rowData?.metaData?.avg_daily_return_pct > 0 ? "green" : "red"
                }
                size="sm"
              >
                {rowData?.metaData?.avg_daily_return_pct?.toFixed(2)}
              </Text>
            )}
          </Cell>
        </Column>
        {customTableColumn.map((cTC) => (
          <Column width={120}>
            <HeaderCell align="right">
              <Whisper
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={
                  <Tooltip>
                    <Text> {columnAbbr[cTC].title}</Text>
                  </Tooltip>
                }
              >
                <Text size="sm" color="orange">
                  {" "}
                  {columnAbbr[cTC].abbrv}
                </Text>
              </Whisper>
            </HeaderCell>
            <Cell align="right">
              {(rowData) => (
                <Text size="sm">
                  {columnAbbr[cTC].isCurrency
                    ? formetValue(rowData?.metaData?.[cTC])
                    : rowData?.metaData?.[cTC]}
                </Text>
              )}
            </Cell>
          </Column>
        ))}
        <Column width={80} fixed="right">
          <HeaderCell></HeaderCell>

          <Cell align="center">
            {(rowData, index) => (
              <Whisper
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={
                  <Tooltip>
                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      spacing={4}
                    >
                      <Text>Alert</Text>
                      <Text size="sm">{rowData.notes}</Text>
                    </Stack>
                  </Tooltip>
                }
              >
                <div>
                  <ImAttachment
                    onClick={() =>
                      setNoteModal((prevState) => ({
                        ...prevState,
                        title: `Note for ${rowData.displayName}`,
                        open: true,
                        index,
                        type: "official",
                        value: rowData.notes || structureNote,
                      }))
                    }
                    color={rowData.notes ? "green" : ""}
                  />
                </div>
              </Whisper>
            )}
          </Cell>
        </Column>
      </Table>
    </>
  )
}

export default ReportManagers
