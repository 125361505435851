import { useParams } from "react-router-dom"
import {
  getManagersAndPortfolios,
  getPortfolioManager,
  listManagers,
} from "../../../routes/secure_loaders"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import managers_reducer from "./managers_reducer"
import useAuth from "../../../components/AmplifyAuth/useAuth"

const REFETCH_INTERVAL = 1000 * 60 * 3

export const getManagerQuery = (managerSlug) => ({
  queryKey: ["managers", managerSlug],
  queryFn: () => getPortfolioManager(managerSlug),
  refetchInterval: REFETCH_INTERVAL,
})

export const listAllManagersQuery = (auth) => ({
  queryKey: ["managers", "all"],
  queryFn: () => listManagers(auth),
  refetchInterval: REFETCH_INTERVAL,
})

// used by benchmarks
export const allManagersQuery = (auth) => ({
  queryKey: ["managers"],
  queryFn: () => getManagersAndPortfolios(auth),
  refetchInterval: REFETCH_INTERVAL,
})

export const useManagers = (managerSlug) => {
  const auth = useAuth()
  return useQuery({
    ...getManagerQuery(managerSlug),
    enabled: !!auth.userMeta,
  })
}

export const useAllManagers = () => {
  const auth = useAuth()
  return useQuery({
    ...allManagersQuery(auth),
    enabled: !!auth.userMeta,
  })
}
//used by ManagersAdmin
export const useAllManagersAdmin = () => {
  const auth = useAuth()
  return useQuery({
    ...allManagersQuery(auth),
    select: (d) => ({
      managers: d,
      ports: managers_reducer(d),
    }),
    enabled: !!auth.userMeta,
  })
}

export const useListAllManagers = () => {
  const { managerSlug } = useParams()
  const auth = useAuth()
  return useQuery({
    ...listAllManagersQuery(auth),
    select: (d) => ({
      managers: d,
      activeManager: d.filter((m) => m.slug === managerSlug)[0],
    }),
    enabled: !!auth.userMeta,
  })
}

export const useBenchmarks = (selectedID) => {
  const { managerSlug } = useParams()
  const auth = useAuth()
  const query = useQuery({
    ...allManagersQuery(auth),
    select: (d) => {
      const overlays = _.chain(d)
        .filter((m) => m.slug === managerSlug || m.slug === "Benchmarks")
        .map((m) => m.portfolios)
        .flatten()
        .map((p) => ({
          ...p,
          label: p.displayName,
          value: `${p.portfolioManager}/${p.slug}`,
        }))
        .orderBy(["type", "displayName"])
        .filter((port) => port.id !== selectedID)
        .value()
      return overlays
    },
    enabled: !!auth.userMeta,
  })

  return query
}
