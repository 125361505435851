import React, { useEffect, useState } from "react"
import { Icon } from "@rsuite/icons"
import { Button, Stack, Text, Tooltip, Whisper } from "rsuite"
import {
  FaFlask,
  FaDollarSign,
  FaChartLine,
  FaFile,
  FaBook,
  FaPlay,
  FaPause,
  FaRegNewspaper,
  FaRegLightbulb,
  FaCompass,
  FaBalanceScale,
} from "react-icons/fa"

import { BiMapAlt } from "react-icons/bi"
import { MdSpeed, MdPaid } from "react-icons/md"
import { useTheme } from "@aws-amplify/ui-react"

const AppIcons = {
  PRODUCTION: {
    icon: FaDollarSign,
    type: "type",
  },
  PAPER: {
    icon: FaRegNewspaper,
    type: "type",
    divider: true,
  },
  MODEL: {
    icon: FaChartLine,
    type: "type",
  },
  BENCHMARK: {
    icon: MdSpeed,
    type: "type",
  },
  DEVELOPMENT: {
    icon: FaRegLightbulb,
    type: "type",
  },
  EXPERIMENTAL: {
    icon: FaFlask,
    type: "type",
  },
  LEGACY: {
    icon: FaBook,
    type: "type",
  },

  DRAFT: {
    icon: FaFile,
    type: "status",
  },
  LIVE: {
    icon: FaPause,
    type: "status",
  },
  PAUSED: {
    icon: FaPlay,
    type: "status",
    divider: true,
  },

  historical: {
    icon: MdPaid,
    type: "endpoint",
  },
  dashboard: {
    icon: BiMapAlt,
    type: "endpoint",
  },
  slippage: {
    icon: FaCompass,
    type: "endpoint",
  },

  auto_reconciliation: {
    icon: FaBalanceScale,
    type: "endpoint",
  },
}

const IconFilter = ({
  onChange,
  only,
  showClear,
  showLabel,
  vertical,
  value,
}) => {
  const { tokens } = useTheme()
  const [filterByIconArray, setFilterByIconArray] = useState(value || [])
  const onFilterByIcon = (status) => {
    setFilterByIconArray((prevState) => {
      const exists = prevState.find((pS) => pS.name === status.name)
      if (exists) {
        const newState = prevState.filter((pS) => pS.name !== status.name)

        return [...newState]
      }
      return [...prevState, status]
    })
  }

  const onClear = () => {
    setFilterByIconArray([])
  }

  useEffect(() => {
    onChange?.(filterByIconArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByIconArray.length])

  return (
    <Stack
      spacing={10}
      justifyContent="space-between"
      direction={vertical ? "column" : "row"}
      alignItems="flex-start"
    >
      {Object.keys(AppIcons).map((iconKey, index) => {
        if (only && !only.includes(AppIcons[iconKey].type)) return null

        return (
          <Stack
            spacing={10}
            key={index}
            onClick={() =>
              onFilterByIcon({ ...AppIcons[iconKey], name: iconKey })
            }
          >
            <Whisper
              controlId={`control-id-${index}-status`}
              trigger="hover"
              speaker={<Tooltip>{iconKey.toUpperCase()}</Tooltip>}
              placement="top"
            >
              <div>
                <Icon
                  key={index}
                  style={{ cursor: "pointer", marginBottom: "4px" }}
                  as={AppIcons[iconKey].icon}
                  fill={
                    filterByIconArray.find((fBI) => fBI.name === iconKey)
                      ? tokens.colors.icons[iconKey]?.value
                      : "grey"
                  }
                />
              </div>
            </Whisper>
            {showLabel && (
              <Text
                style={{
                  color: filterByIconArray.find((fBI) => fBI.name === iconKey)
                    ? "white"
                    : "grey",
                  cursor: "pointer",
                }}
              >
                {iconKey.toUpperCase()}
              </Text>
            )}
          </Stack>
        )
      })}
      {showClear && (
        <Button appearance="subtle" onClick={onClear}>
          Clear
        </Button>
      )}
    </Stack>
  )
}

export default IconFilter
