import React, { useEffect } from "react"
import {
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Cell,
  Tooltip,
  ReferenceLine,
  Legend,
  Line,
  Area,
  Scatter,
} from "recharts"
import _ from "lodash"
import { useTheme } from "@aws-amplify/ui-react"
import { renderLegend } from "../../../../../lib/chart_config"
import { abbreviate_number } from "../../../../../lib/utils"
import { useControls } from "../../../../../lib/controls/ControlContext"
import useTraderDashboard from "../lib/hooks/useTraderDashboard"
import CustomInstrumentsTooltip from "./CustomInstrumentsTooltip"

const InstrumentsChart = () => {
  const { chart_data, chart_field, tradeServerMode } = useTraderDashboard()
  const [chartData, setChartData] = React.useState()
  const [legCount, setLegCount] = React.useState(1)
  const { tokens } = useTheme()
  const { showLabels, showTooltip } = useControls()

  const [sortCol, setSortColDir] = React.useState(chart_field)
  const [sortDir, setSortDir] = React.useState("desc")

  useEffect(() => {
    const data = _.orderBy(chart_data, [sortCol], [sortDir])
    const max_legs = _.maxBy(data, "leg_count")
    setLegCount(max_legs?.leg_count)
    setChartData(data)
  }, [tradeServerMode, chart_data, sortCol, sortDir])

  return (
    <ResponsiveContainer height={400}>
      <ComposedChart
        width={500}
        height={300}
        data={chartData}
        stackOffset="sign"
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <ReferenceLine y={0} stroke="#FFF" yAxisId="left" />
        <XAxis
          dataKey="name"
          tick={{ stroke: "#888888", strokeWidth: 1 }}
          hide={showLabels.isDefault}
        />
        <YAxis
          yAxisId="left"
          orientation="left"
          tick={{ stroke: "#888888", strokeWidth: 1 }}
          tickFormatter={(tick) =>
            ["AutoRecon", "Positions"].indexOf(tradeServerMode) >= 0
              ? abbreviate_number(tick)
              : tick
          }
          hide={showLabels.isDefault}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          tick={{ stroke: "#888888", strokeWidth: 1 }}
          domain={([dataMin, dataMax]) => {
            const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax))
            return [-absMax, absMax]
          }}
          hide={showLabels.isDefault}
        />
        {!!showTooltip.isDefault && (
          <Tooltip
            content={<CustomInstrumentsTooltip chart_field={chart_field} />}
          />
        )}
        <Legend
          content={renderLegend((val) => {
            setSortDir((dir) => (dir === "asc" ? "desc" : "asc"))
            setSortColDir("field_size")
          })}
        />
        {[...Array(legCount).keys()]
          .map((l) => `L_${l}`)
          .map((leg) => (
            <Bar
              key={leg}
              dataKey={leg}
              stroke="white"
              stackId="stack"
              yAxisId="left"
              legendType="none"
            >
              {chartData?.map((d) => (
                <Cell
                  key={d.name}
                  fill={tokens.colors.metagora.value_color.value(d[leg])}
                />
              ))}
            </Bar>
          ))}
        {["AutoRecon", "Positions"].indexOf(tradeServerMode) >= 0 && (
          <>
            <Line
              dataKey="position_value"
              dot={{ stroke: "#fff", strokeWidth: 1 }}
              yAxisId="left"
              stroke="#8884d8"
              strokeDasharray="5 5"
            />
            <Area
              dataKey="qty_sum"
              yAxisId="right"
              dot={{ stroke: "#82ca9d", strokeWidth: 1 }}
              stroke="#82ca9d"
              strokeDasharray="3 4 5 2"
              //   legendType="none"
            />
          </>
        )}

        {tradeServerMode === "AutoRecon" && (
          <Scatter
            dataKey="diff"
            yAxisId="right"
            fill={tokens.colors.blue[60]}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default InstrumentsChart
