import { QueryClient } from "@tanstack/react-query"
import {
  createPortfolio,
  createPortfolioManager,
  deletePortfolio,
  deletePortfolioManager,
  syncPortfolio,
  updatePortfolio,
  updatePortfolioManager,
} from "./loaders"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 3,
    },
  },
})

const resetQuery = (managerSlug) => {
  queryClient.invalidateQueries(["dashboard"])
  queryClient.invalidateQueries(["managers"])
  queryClient.invalidateQueries(["positions"])
  queryClient.invalidateQueries([managerSlug])
}

const setQueriesDataForManagerAndPortfolio = (manager, data) => {
  queryClient.setQueriesData([manager, data?.slug], (old) => ({
    ...old,
    ...data,
  }))
}

const updatePortfolioSuccess = (data) => resetQuery(data.portfolioManager)

const mutationDefaults = {
  syncPortfolio: {
    mutationFn: syncPortfolio,
    onSuccess: (data) => {
      resetQuery(data?.portfolioManager)
      setQueriesDataForManagerAndPortfolio(data?.portfolioManager, data)
    },
  },
  updatePortfolio: {
    mutationFn: updatePortfolio,
    onSuccess: updatePortfolioSuccess,
  },
  createPortfolio: {
    mutationFn: createPortfolio,
    onSuccess: (data) => {
      resetQuery(data.portfolioManager)
      setQueriesDataForManagerAndPortfolio(data.portfolioManager, data)
    },
  },
  deletePortfolio: {
    mutationFn: deletePortfolio,
    onSuccess: (data) => {
      resetQuery(data.portfolioManager)
      queryClient.removeQueries([data.portfolioManager, data.slug])
    },
  },
  createManager: {
    mutationFn: createPortfolioManager,
    onMutate: (variables) => {
      return variables
    },
    onSuccess: (data) => {
      resetQuery(data.slug)
      queryClient.invalidateQueries(["admin", "list", "all"])
      queryClient.invalidateQueries(["admin", data.slug])
      queryClient.setQueriesData([data.slug], (old) =>
        !!old
          ? {
              ...old,
              ...data,
            }
          : old
      )
    },
  },
  deleteManager: {
    mutationFn: deletePortfolioManager,
    onSuccess: (data) => {
      resetQuery(data.slug)
      queryClient.invalidateQueries(["admin", "list", "all"])
      queryClient.invalidateQueries(["admin", data.slug])
    },
  },
  updateManager: {
    mutationFn: updatePortfolioManager,
    onSuccess: (data) => {
      resetQuery(data.slug)
      queryClient.invalidateQueries(["admin", "list", "all"])
      queryClient.invalidateQueries(["admin", data.slug])
      queryClient.setQueriesData([data.slug], (old) => ({
        ...old,
        ...data,
      }))
    },
  },
}

Object.entries(mutationDefaults).forEach(([key, value]) => {
  queryClient.setMutationDefaults([key], value)
})

export default queryClient
