import React, { useContext, useState } from "react"
import { PNLContext } from "../lib/PNLContext"
import { useParams, useSearchParams } from "react-router-dom"
import { SelectPicker, Stack } from "rsuite"
import useAuth from "../../../../../components/AmplifyAuth/useAuth"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { allManagersQuery } from "../../../../Managers/lib/useManager"

const useBenchmarks = (selectedID) => {
  const { managerSlug } = useParams()
  const auth = useAuth()
  const query = useQuery({
    ...allManagersQuery(auth),
    select: (d) => {
      const overlays = _.chain(d)
        .filter((m) => m.slug === managerSlug || m.slug === "Benchmarks")
        .map((m) => m.portfolios)
        .flatten()
        .map((p) => ({
          ...p,
          label: p.displayName,
          value: `${p.portfolioManager}/${p.slug}`,
        }))
        .orderBy(["type", "displayName"])
        .filter((port) => port.id !== selectedID)
        .value()
      return overlays
    },
    enabled: !!auth.userMeta,
  })

  return query
}

export const useBenchmarkParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  let benchmark_slug = searchParams.get("benchmark")
  let benchmark_manager_slug = searchParams.get("benchmark_manager")
  const [b_slug, setBSlug] = useState(benchmark_slug)
  const [bm_slug, setBMSlug] = useState(benchmark_manager_slug)

  const handleBenchmarkChange = (benchmarkSlug, benchmarkManagerSlug) => {
    setBSlug(benchmarkSlug)
    setBMSlug(benchmarkManagerSlug)
    searchParams.set("benchmark", benchmarkSlug)
    searchParams.set("benchmark_manager", benchmarkManagerSlug)
    setSearchParams(searchParams)
  }

  return {
    handleBenchmarkChange,
    b_slug,
    bm_slug,
  }
}

const BenchmarkOverlayMenu = ({ selectedID }) => {
  const {
    ui: {
      benchmark: { handleBenchmarkChange, b_slug, bm_slug },
    },
  } = useContext(PNLContext)

  const { data: manager_ports, isLoading } = useBenchmarks(selectedID)
  return (
    <SelectPicker
      placeholder="Overlay"
      data={manager_ports || []}
      loading={isLoading}
      groupBy="portfolioManager"
      value={bm_slug ? `${bm_slug}/${b_slug}` : ""}
      renderMenuItem={(label, item) => (
        <Stack spacing={10} alignItems="center">
          {item.displayName}
        </Stack>
      )}
      onChange={(d) => {
        if (d) {
          const [portfolioManager, slug] = d.split("/")
          handleBenchmarkChange(slug, portfolioManager)
        } else {
          handleBenchmarkChange(null, null)
        }
      }}
    />
  )
}

export default BenchmarkOverlayMenu
