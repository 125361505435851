import React from "react"
import { NavLink } from "react-router-dom"
import { Nav, Stack } from "rsuite"
import PortStatus from "./PortStatus"

const NavItemButton = ({ to, icon, text, ...rest }) => {
  return (
    <Nav.Item as={"div"}>
      <NavLink
        to={to}
        {...rest}
        style={({ isActive }) => ({
          textDecoration: "none",
          color: "white",
          borderBottom: isActive ? "thin solid white" : "none",
        })}
      >
        <Stack spacing={5}>
          <PortStatus status={icon} />
          <span className="no_mobile">{text}</span>
        </Stack>
      </NavLink>
    </Nav.Item>
  )
}

export default NavItemButton
